<template>
  <validation-observer
    ref="observer"
    v-slot="{ handleSubmit }"
  >
    <b-modal
      id="modal-add-champion-to-program"
      v-model="isModalVisible"
      title="Edit Champion"
      centered
      ok-title="Submit"
      cancel-variant="outline-secondary"
      :ok-disabled="isLoading"
      @hidden="onHidden"
      @ok.prevent="handleSubmit(onSubmit)"
    >
      <b-card>
        <b-form>
          <b-row>
            <b-col md="6">
              <validation-provider
                v-slot="validationContext"
                ref="name"
                :rules="{ required: true }"
                vid="name"
                name="First name"
              >
                <b-form-group
                  label="First name"
                  label-for="first-name"
                >
                  <b-form-input
                    v-model="championData.first_name"
                    class="mb-1"
                    placeholder="First name"
                    name="first-name"
                    :state="getValidationState(validationContext)"
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                v-slot="validationContext"
                ref="name"
                :rules="{ required: true }"
                vid="name"
                name="Last name"
              >
                <b-form-group
                  label="Last name"
                  label-for="last-name"
                >
                  <b-form-input
                    v-model="championData.last_name"
                    class="mb-1"
                    placeholder="Last name"
                    name="last-name"
                    :state="getValidationState(validationContext)"
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="12">
              <validation-provider
                v-slot="validationContext"
                ref="name"
                :rules="{ required: true, email: true }"
                vid="name"
                name="Email"
              >
                <b-form-group
                  label="Email"
                  label-for="email"
                >
                  <b-form-input
                    v-model="championData.email"
                    class="mb-1"
                    placeholder="Email"
                    name="email"
                    :state="getValidationState(validationContext)"
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="12">
              <validation-provider
                v-slot="validationContext"
                ref="name"
                rules="required|aom_phone"
                vid="name"
                name="Phone"
              >
                <b-form-group
                  label="Phone"
                  label-for="phone"
                >
                  <aom-tel-input
                    id="phone"
                    v-model="championData.phone"
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </b-form>
      </b-card>
    </b-modal>
  </validation-observer>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BModal,
  BFormInvalidFeedback,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import championsService from "@/services/championsService";
import { getValidationState } from "@/libs/utils";
import { makeErrorToast } from "@/libs/utils";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BForm,
    BModal,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    champion: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isModalVisible: this.showModal,
      championData: {
        first_name: null,
        last_name: null,
        email: null,
        phone: null,
      },
      isLoading: false,
    };
  },
  watch: {
    champion() {
      this.championData = this.champion;
    },
  },
  created() {
    if (this.champion) {
      this.championData = this.champion;
    }
  },
  methods: {
    show() {
      this.isModalVisible = true;
    },
    async onSubmit() {
      this.isLoading = true;
      try {
        const payload = {
          first_name: this.championData.first_name,
          last_name: this.championData.last_name,
          email: this.championData.email,
          phone: this.championData.phone,
        };

        await championsService.update(this.championData.id, payload);
        this.$emit("championEdited");
        this.$emit("hiddenModal", false);
      } catch (e) {
        const { status, data } = e.response;
        if (status === 409) {
          this.$toast(makeErrorToast(data.message));
        } else {
          this.$log(e);
          this.$toast(makeErrorToast(e));
        }
      } finally {
        this.isLoading = false;
      }
    },
    onHidden() {
      this.$emit("hiddenModal", false);
      this.clearForm();
    },
    clearForm() {
      this.championData = {
        first_name: null,
        last_name: null,
        email: null,
        phone: null,
      };
      if (!this.isObserverReady) {
        this.isObserverReady = false;
        this.$nextTick(() => {
          this.isObserverReady = true;
        });
      } else {
        if (this.$refs.observer) {
          this.$refs.observer.reset();
        }
      }
    },
  },
  setup() {
    return {
      getValidationState,
    };
  },
};
</script>

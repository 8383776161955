<template>
  <b-modal
    id="modal-add-resource"
    v-model="showModal"
    title="Add Resource"
    centered
    ok-title="Submit"
    cancel-variant="outline-secondary"
    :ok-disabled="isSubmitting"
    @hidden="onHidden"
    @ok.prevent="onSubmit"
  >
    <b-card>
      <b-form>
        <v-select
          v-model="selected"
          multiple
          class="mt-1"
          :dir="dir"
          placeholder="Select Resource"
          label="name"
          :options="options"
          :loading="isLoading"
          transition="fade"
          @search="debounceInput"
        />
      </b-form>
    </b-card>
  </b-modal>
</template>

<script>
import { BCard, BForm, BModal } from "bootstrap-vue";
import resourcesService from "@/services/resourcesService";
import { makeErrorToast, makeSuccessToast } from "@/libs/utils";
import _debounce from "lodash/debounce";
import vSelect from "vue-select";

export default {
  components: {
    BCard,
    BForm,
    BModal,
    vSelect,
  },
  props: {
    showModal: {
      type: Boolean
    }
  },
  data() {
    return {
      keyword: null,
      selected: [],
      options: [],
      dir: null,
      isLoading: false,
      isSubmitting: false,
    };
  },
  mounted() {
    this.loadItems();
  },
  methods: {
    async onSubmit() {
      this.isSubmitting = true;
      const resources = this.selected.map(select => ({id: select.id}));
      try {
        await resourcesService.addResourceToProgram(
          this.$route.params.id,
          {resources}
        );
        this.$toast(makeSuccessToast("Resource was successfully added"));
        this.$emit("resourcesAdded");
        this.$emit("hiddenModal", false);
        this.clearForm();
      } catch (e) {
        this.$toast(makeErrorToast(e));
        this.$log.error(e);
      } finally {
        this.isSubmitting = false;
      }
    },
    onHidden() {
      this.$emit("hiddenModal", false);
      this.clearForm();
    },
    clearForm() {
      this.selected = [];
    },
    debounceInput: _debounce(function (value) {
      this.keyword = value;
      this.loadItems();
    }, 500),
    async loadItems() {
      this.isLoading = true;
      try {
        const response = await resourcesService.getLibraryResourcesList(
          {
            columnFilters: this.keyword ? [
              {
                field: "translations.title",
                value: this.keyword,
              }
            ] : []
          }
        );
        this.options = response.data.items.map(resource => ({
          id: resource.id,
          name: resource.translations[0].title,
        }));
      } catch (e) {
        this.$toast(makeErrorToast("Resource list not loaded."));
        this.$log.error(e);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<template>
  <b-card>
    <b-row>
      <b-col md="4">
        <div class="d-flex align-items-center">
          <b-form-group class="mt-1">
            <b-form-checkbox
              id="showDeletedCheck"
              v-model="showDeleted"
              name="show-deleted"
              @change="refreshList()"
            >
              Show Deleted
            </b-form-checkbox>
          </b-form-group>
        </div>
      </b-col>
      <b-col
        cols="8"
        md="8"
      >
        <div class="d-flex align-items-center justify-content-end">
          <b-dropdown
            variant="link"
            no-caret
            toggle-class="p-0 mr-1"
            right
          >
            <template #button-content>
              <b-button
                variant="primary"
                class="btn-icon"
              >
                <feather-icon
                  icon="DownloadIcon"
                  size="16"
                  class="mr-50"
                />
                Export
              </b-button>
            </template>

            <b-dropdown-item @click="onDownloadProgramsList('csv')">
              <span class="align-middle ml-50">Export CSV</span>
            </b-dropdown-item>

            <b-dropdown-item @click="onDownloadProgramsList('xlsx')">
              <span class="align-middle ml-50">Export XLSX</span>
            </b-dropdown-item>
          </b-dropdown>
          <b-button
            variant="primary"
            class="ml-50"
            @click="showAddProgram()"
          >
            <feather-icon
              icon="PlusIcon"
              size="16"
            />
            <span class="text-nowrap ml-1">Add Program</span>
          </b-button>
          <b-button
            variant="primary"
            class="ml-50"
            @click="showAddProgramFromTemplate()"
          >
            <feather-icon
              icon="PlusIcon"
              size="16"
            />
            <span class="text-nowrap ml-1">Add Program From Template</span>
          </b-button>
          <b-button
            variant="primary"
            class="ml-50"
            @click="onAddDemoProgramClick()"
          >
            <feather-icon
              icon="PlusIcon"
              size="16"
            />
            <span class="text-nowrap ml-1">Add Demo Program</span>
          </b-button>
        </div>
      </b-col>
    </b-row>

    <program-list
      ref="programList"
      :show-deleted="showDeleted" 
    />

    <add-program
      ref="addProgram"
      @programAdded="onProgramAdded"
    />

    <add-program-from-template
      ref="addProgramFromTemplate"
      @programAdded="onProgramAdded"
    />

    <add-demo-program
      ref="addDemoProgram"
      @programAdded="onDemoProgramAdded"
    />

    <b-modal
      id="modal-edit-program"
      v-model="modalShowEdit"
      v-b-modal.modal-default
      title="Edit Program"
      centered
      size="lg"
      ok-title="Submit"
      cancel-variant="outline-secondary"
    >
      <edit-program />
    </b-modal>
  </b-card>
</template>

<script>
import {
  BButton,
  BRow,
  BCol,
  BCard,
  BModal,
  BFormGroup,
  BFormCheckbox,
  BDropdown,
  BDropdownItem,
  VBModal
} from "bootstrap-vue";
import AddProgram from "./AddProgram.vue";
import addProgramFromTemplate from "./AddProgramFromTemplate.vue";
import AddDemoProgram from "./AddDemoProgram.vue";
import EditProgram from "./EditProgram.vue";
import ProgramList from "./ProgramsList.vue";
import { BE_API_URL } from "@/constants/app";

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BModal,
    AddProgram,
    EditProgram,
    ProgramList,
    BFormGroup,
    BFormCheckbox,
    BDropdown,
    BDropdownItem,
    AddDemoProgram,
    addProgramFromTemplate
  },
  directives: {
    "b-modal": VBModal
  },
  data() {
    return {
      modalShow: false,
      modalShowEdit: false,
      dir: false,
      showDeleted: false
    };
  },
  methods: {
    onAddDemoProgramClick() {
      this.$refs.addDemoProgram.show();
    },
    onDemoProgramAdded(id) {
      this.$router.push({path: `/admin/programs/${id}`});
    },
    showAddProgram() {
      this.$refs.addProgram.show();
    },
    showAddProgramFromTemplate() {
      this.$refs.addProgramFromTemplate.show();
    },
    onProgramAdded() {
      this.$refs.programList.loadItems();
    },
    onDownloadProgramsList(type) {
      const dowloadLink = `${BE_API_URL}/programs/download/${type}`;
      window.document.location = dowloadLink;
    },
  },
};
</script>

<style lang="scss" scoped>
tbody > tr > td {
  vertical-align: middle !important;
}

tr > td {
  vertical-align: middle !important;
}

.badge {
  font-weight: 400 !important;
}
</style>


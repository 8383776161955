<template>
  <b-dropdown-item
    link-class="d-flex align-items-center"
    @click.prevent="resendAccess"
  >
    <feather-icon 
      size="16" 
      icon="MailIcon" 
      class="mr-50" 
    />
    <span>Resend Access</span>
  </b-dropdown-item>
</template>
<script>
import { BDropdownItem } from "bootstrap-vue";

import { makeSuccessToast, makeErrorToast } from "@/libs/utils";
import usersService from "@/services/usersService";

export default {
  name: "ResendAccessChampion",
  components: {
    BDropdownItem,
  },
  props: {
    user: {
      type: Object,
      default: () => {},
      required: true,
    },
    userId: {
      type: Number,
      required: true,
    },
  },

  methods: {
    async resendAccess() {
      const result = await this.$bvModal.msgBoxConfirm(
        "Do you want to resend access to this champion? This will send them an email with a link to allow them to reset their password.",
        {
          title: "Resend Access?",
          size: "sm",
          okVariant: "primary",
          okTitle: "Resend",
          cancelTitle: "Cancel",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        }
      );

      if (!result) {
        return;
      }

      try {
        const programId = this.$route.params.id;
        const result = await usersService.resendChampionProgramAccess(programId, this.user.id);
        if (result) {
          this.$toast(makeSuccessToast("Resent access successfully."));
          this.$emit("updated");
        }
      } catch (e) {
        const { data } = e.response;
        this.$toast(makeErrorToast(data.message));
        this.$log.error(e);
      }
    },
  },
};
</script>
     
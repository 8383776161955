<template>
  <b-dropdown-item
    link-class="d-flex align-items-center"
    @click.prevent="enableTfa"
  >
    <feather-icon 
      size="16" 
      icon="KeyIcon" 
      class="mr-50"
    />
    <span>{{ buttonText }}</span>
  </b-dropdown-item>
</template>
<script>
import { BDropdownItem } from "bootstrap-vue";
import { makeSuccessToast, makeErrorToast } from "@/libs/utils";
import usersService from "@/services/usersService";

export default {
  name: "EnableTfaChampion",
  components: {
    BDropdownItem,
  },
  props: {
    user: {
      type: Object,
      default: () => {},
      required: true,
    },
    userId: {
      type: Number,
      required: true,
    },
  },
  computed: {
    buttonText() {
      return !this.user.is_tfa_enabled ? "Enable 2FA" : "Disable 2FA";
    },
    modalTitle() {
      return !this.user.is_tfa_enabled ? "Enable 2FA" : "Disable 2FA";
    },
    modalMessage() {
      return !this.user.is_tfa_enabled
        ? "Are you sure you want to enable 2FA for this user?"
        : "Are you sure you want to disable 2FA for this user?";
    },
  },
  methods: {
    successMsg(newIsTfaEnabled) {
      return !newIsTfaEnabled
        ? "2FA Disabled."
        : "2FA Enabled.";
    },
    async enableTfa() {
      const result = await this.$bvModal.msgBoxConfirm(this.modalMessage, {
        title: this.modalTitle,
        size: "sm",
        okVariant: "primary",
        okTitle: this.buttonText,
        cancelTitle: "Cancel",
        cancelVariant: "outline-secondary",
        hideHeaderClose: false,
        centered: true,
      });

      if (!result) {
        return;
      }

      try {
        const newIsTfaEnabled = !this.user.is_tfa_enabled;
        const result = await usersService.updateChampionOrAdmin(this.user.id, {is_tfa_enabled: newIsTfaEnabled});
        this.$emit("updated");
        if (result) {
          this.$toast(makeSuccessToast(this.successMsg(newIsTfaEnabled)));
        }
      } catch (e) {
        const { data } = e.response;
        this.$toast(makeErrorToast(data.message));
        this.$log.error(e);
      }
    },
  },
};
</script>
   
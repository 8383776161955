var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var pristine = ref.pristine;
var invalid = ref.invalid;
var handleSubmit = ref.handleSubmit;
return [_c('b-modal',{attrs:{"id":"modal-add-program","title":"Add New Program","centered":"","ok-title":"Submit","cancel-variant":"outline-secondary","ok-disabled":pristine || invalid},on:{"hidden":_vm.onHidden,"ok":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
var ok = ref.ok;
var cancel = ref.cancel;
return [_c('b-button',{attrs:{"variant":"outline-secondary"},on:{"click":function($event){return cancel()}}},[_vm._v(" Cancel ")]),_c('b-button',{attrs:{"type":"submit","variant":"primary"},on:{"click":function($event){return ok()}}},[(_vm.isUpdating)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),(_vm.isUpdating)?_c('span',[_vm._v(" Updating...")]):_c('span',[_vm._v("Submit")])],1)]}}],null,true),model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{ref:"Organisation",attrs:{"rules":"required","name":"Organisation"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":"Organisation","label-for":"Organisation"}},[_c('v-select',{attrs:{"dir":_vm.dir,"placeholder":"Please select a Organisation","options":_vm.clientsList,"reduce":function (option) { return option.value; },"label":"text"},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_vm._v(" "+_vm._s(option.text)+" - "+_vm._s(option.domain)+" ")]}}],null,true),model:{value:(_vm.program.client_id),callback:function ($$v) {_vm.$set(_vm.program, "client_id", $$v)},expression:"program.client_id"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getValidationState(validationContext)}},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)})],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{ref:"name",attrs:{"rules":{ required: true },"vid":"name","name":"Program Name"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":"Program Name","label-for":"program-name"}},[_c('b-form-input',{attrs:{"id":"program-name-input","placeholder":"Program Name","maxlength":"255","state":_vm.getValidationState(
                    validationContext
                  )},on:{"change":_vm.onNameChange},model:{value:(_vm.program.name),callback:function ($$v) {_vm.$set(_vm.program, "name", $$v)},expression:"program.name"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)})],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{ref:"programType",attrs:{"rules":"required","name":"Program Type"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":"Program Type","label-for":"program-type"}},[_c('v-select',{attrs:{"id":"program-type","label":"text","reduce":function (programTypeDisplay) { return (programTypeDisplay.id); },"placeholder":"Select the Program Type","options":_vm.programTypeDisplay,"state":_vm.getValidationState(validationContext)},model:{value:(_vm.program.type_id),callback:function ($$v) {_vm.$set(_vm.program, "type_id", $$v)},expression:"program.type_id"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getValidationState(validationContext)}},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)})],1)],1),_c('b-row',[_c('b-col',{attrs:{"sm":"12"}},[_c('validation-provider',{ref:"subdomain",attrs:{"rules":{ required: true },"vid":"path","name":"Program Path"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":"Program Path","label-for":"program-path-input"}},[_c('b-input-group',{attrs:{"prepend":"organisation-url/","append":"/"}},[_c('b-form-input',{attrs:{"id":"program-path-input","placeholder":"Path","maxlength":"63","state":_vm.getValidationState(
                      validationContext
                    )},model:{value:(_vm.program.path),callback:function ($$v) {_vm.$set(_vm.program, "path", $$v)},expression:"program.path"}})],1),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getValidationState(
                    validationContext
                  )}},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)})],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Index URL","label-for":"indexUrl"}},[_c('b-form-checkbox',{attrs:{"id":"indexedSwitch","checked":"false","name":"index-url","switch":"","inline":""},model:{value:(_vm.program.indexed),callback:function ($$v) {_vm.$set(_vm.program, "indexed", $$v)},expression:"program.indexed"}})],1)],1)],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }
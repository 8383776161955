<template>
  <vue-good-table
    class="position-static"
    mode="remote"
    :columns="columns"
    :rows="rows"
    :rtl="directionIsRTL"
    :search-options="{
      enabled: true,
      externalQuery: searchTerm,
    }"
    :pagination-options="{
      enabled: true,
      perPage: perPage,
    }"
    style-class="vgt-table striped"
    :is-loading="isLoading"
    @on-page-change="onPageChange"
    @on-sort-change="onSortChange"
    @on-column-filter="onColumnFilter"
    @on-per-page-change="onPerPageChange"
  >
    <template slot="emptystate">
      <div
        v-if="isFirstLoading"
        class="text-center"
      >
        Fetching data
      </div>
      <div
        v-else
        class="text-center"
      >
        No data for table
      </div>
    </template>
    <template
      slot="table-row"
      slot-scope="props"
    >
      <span v-if="props.column.field === 'title'">
        <span v-if="props.row.translations[0]">{{ props.row.translations[0].title }}</span> 
      </span>
      <span v-else-if="props.column.field === 'description'">
        <span v-if="props.row.translations[0]">{{ props.row.translations[0].description }}</span> 
      </span>
      <span v-else-if="props.column.field === 'survey_type'">
        <span v-if="props.row.survey_type">{{ surveyTypesDisplay[props.row.survey_type] }}</span>
      </span>
      <span
        v-else-if="props.column.field === 'role'"
        class="text-capitalize"
      >
        <span>{{ props.row.role }}</span>
      </span>
      <span v-else>
        {{ props.formattedRow[props.column.field] }}
      </span>
      <!-- Column: Help Link -->
      <!-- Column: Actions -->
      <div
        v-if="props.column.field === 'actions'"
        cols="12"
        md="2"
        class="vgt-center-align d-flex"
      >
        <b-button
          variant="outline-none"
          class="nav pr-0"
        >
          <b-nav-item-dropdown
            class="btn-icon"
          >
            <template 
              #button-content
            >
              <feather-icon
                icon="MoreVerticalIcon"
                size="20"
              />                
            </template>
            <b-dropdown-item
              link-class="d-flex align-items-center"
              @click="editSurvey(props.row.id)"
            >
              <feather-icon
                size="16"
                icon="EditIcon"
                class="mr-50"
              />
              <span>Edit</span>
            </b-dropdown-item>
            <b-dropdown-item
              link-class="d-flex align-items-center"
              @click="viewSurvey(props.row.id)"
            >
              <feather-icon
                size="16"
                icon="EyeIcon"
                class="mr-50"
              />
              <span>View</span>
            </b-dropdown-item>
            <b-dropdown-item
              link-class="d-flex align-items-center"
              @click="duplicateSurvey(props.row.id)"
            >
              <feather-icon
                size="16"
                icon="CopyIcon"
                class="mr-50"
              />
              <span>Duplicate</span>
            </b-dropdown-item>
          </b-nav-item-dropdown>
        </b-button>
        <b-button
          variant="outline-none"
          class="pr-0 pl-0"
          @click="deleteSurvey(props.row.id)"
        >
          <feather-icon
            v-if="!props.row.hasBeenUsed"
            icon="TrashIcon"
            size="20"
          />
        </b-button>
      </div>
    </template>
    
    <!-- pagination -->
    <template
      slot="pagination-bottom"
      slot-scope="props"
    >
      <table-pagination
        :per-page="perPage"
        :total="total"
        @perPageChanged="
          (value) => props.perPageChanged({ currentPerPage: value })
        "
        @pageChanged="(value) => props.pageChanged({ currentPage: value })"
      />
    </template>
  </vue-good-table>
</template>
    
<script>
import {
  BButton,
  VBToggle,
  BNavItemDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import store from "@/store/index";
    
import {
  GOODTABLE_ISO_DATETIME_INPUT_FORMAT,
  GOODTABLE_LOCALISED_DATE_FORMAT,
  GOODTABLE_SEARCH_FIELD_DATE_PICKER_FORMAT,
  PRIMARY_DATE_FORMAT,
  localDateStringOrDateRangeStringToUtcRange
} from "@/libs/utils";
import TablePagination from "@/views/components/pagination/TablePagination.vue";
    
import flatPickr from "flatpickr";
import "flatpickr/dist/flatpickr.css";
import "flatpickr/dist/themes/material_blue.css";
import formsService from '@/services/formsService';
import { makeSuccessToast, makeErrorToast } from "@/libs/utils";
import router from '@/router';
import { useGetAvailability } from "@/views/apps/useGetAvailability";
import { surveyTypesDisplay, surveyTypesSelect, participantRolesDisplay } from "@/models";
import useAomTableConfig from "@aom-core/useAomTableConfig.js";

export default {
  name: 'SurveysListAdmin',
  components: {
    VueGoodTable,
    BButton,
    TablePagination,
    BNavItemDropdown,
    BDropdownItem,
  },
  directives: {
    "b-toggle": VBToggle,
  },
  data() {
    return {
      isFirstLoading: false,
      isLoading: false,
      element: {},
      dir: false,
      columns: [
        {
          label: "Title",
          field: "title",
          filterOptions: {
            enabled: true,
            placeholder: "Search Title",
          },
          width: "10em",
        },
        {
          label: "Description",
          field: "description",
          filterOptions: {
            enabled: true,
            placeholder: "Search Description",
          },
          width: "15em",
        },
        {
          label: "Role",
          field: "role",
          filterOptions: {
            enabled: true,
            placeholder: "All",
            filterDropdownItems: participantRolesDisplay.map(item => item.text),
          },
          width: "8em",
        },
        {
          label: "Survey Type",
          field: "survey_type",
          filterOptions: {
            enabled: true,
            placeholder: "All",
            filterDropdownItems: surveyTypesSelect.map(type => (
              {
                value: type.id,
                text: type.name
              }
            )),
          },
          width: "10em",
        },
        {
          label: "No. Questions",
          field: "questions_count",
          filterOptions: {
            enabled: true,
            placeholder: "Search Number of Questions",
          },
          width: "10em",
          tdClass: "text-center"
        },
        {
          label: "Availability",
          field: "availability",
          filterOptions: {
            enabled: false,
          },
          width: "12em",
        },
        {
          label: "Usage",
          field: "copies_count",
          filterOptions: {
            enabled: true,
            placeholder: "Search Usage of Questions",
          },
          width: "6em",
          tdClass: "text-center"
        },
        {
          label: "Last Modified",
          field: "updated_at",
          filterOptions: { 
            enabled: true,
            placeholder: "Filter Date",
            filterFn: this.dateFilter,
          },
          type: "date",
          dateInputFormat: GOODTABLE_ISO_DATETIME_INPUT_FORMAT,
          dateOutputFormat: GOODTABLE_LOCALISED_DATE_FORMAT,
        },
        {
          field: "actions",
          width: "6em",
          sortable: false
        },
      ],
      columnFilters: [],
      sort: [],
      rows: [],
      searchTerm: "",
      surveyTypesDisplay
    };
  },
  computed: {
    directionIsRTL() {
      return store.state.appConfig.isRTL;
    },
  },
  async created() {
    this.isFirstLoading = true;
    await this.loadItems();
    this.isFirstLoading = false;
  },
  mounted() {
    const elements = document.getElementsByName("vgt-updated_at");
    elements.forEach(function (input) {
      flatPickr(input, {
        enableTime: false,
        dateFormat: GOODTABLE_SEARCH_FIELD_DATE_PICKER_FORMAT,
        allowInput: true,
        altInput: true,
        altFormat: PRIMARY_DATE_FORMAT,
        mode: "range",
      });
    });
  },
  methods: {
    dateFilter(data, filterString) {
      return (data = Date.parse(data) >= Date.parse(filterString));
    },
    
    onPageChange(params) {
      this.page = params.currentPage;
      this.loadItems();
    },
    
    onPerPageChange(params) {
      this.perPage = params.currentPerPage;
      this.loadItems();
    },
    
    onSortChange(params) {
      let field = params[0].field;
      let type = params[0].type;
      if (type !== 'none') {
        this.sort = [{ field: field, type: type }];
      } else {
        this.sort = [];
      }
      this.loadItems();
    },
    
    onColumnFilter(params) {
      const columnFilters = [];
      for (let col of ["title", "description", "updated_at", "copies_count", "questions_count", "survey_type"]) {
        if (params.columnFilters[col]) {
          if (col === "updated_at") {
            const dateRange = localDateStringOrDateRangeStringToUtcRange(params.columnFilters[col]);
            if (dateRange) {
              columnFilters.push({
                field: col,
                value: dateRange,
              });
            }
          } else {
            columnFilters.push({
              field: col === "survey_type" ? "survey_type_id" : col,
              value: params.columnFilters[col],
            });
          }
        }
      }
      this.columnFilters = columnFilters;
      this.loadItems();
    },
    async loadItems() {
      try {
        this.isLoading = true;
        const response = await formsService.getSurveysList({
          page: this.page,
          perPage: this.perPage,
          columnFilters: this.columnFilters,
          sort: this.sort,
        });
        this.total = response.data.total;
        this.rows = response.data.items.map(item => (
          {
            ...item,
            survey_type: item.survey_type_id,
            role: item.roles.map(r => r.name).join(", "),
            availability: item.scheduled_actions && item.scheduled_actions.length > 0 ? useGetAvailability(item.scheduled_actions[0]) : '',
          }
        ));
      } catch (e) {
        const { status, data } = e.response;
        //server may respond with vaidation errors
        if (status === 422 && data.errors) {
          this.$refs.observer.setErrors(data.errors);
        } else {
          this.$log.error(e);
        }
        return;
      } finally {
        this.isLoading = false;
      }
    },
    editSurvey(id) {
      this.$nextTick(()=> {
        this.$emit('edit', id);
      });
    },
    viewSurvey(id) {
      router.push({ name: 'admin-survey', params: { survey: id } });
    },
    async deleteSurvey(id) {
      this.$bvModal
        .msgBoxConfirm('Are you sure you want to delete this Survey ?', {
          title: 'Delete Survey',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Delete',
          cancelTitle: 'Cancel',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true
        })
        .then(async value => {
          if(value) {
            try {
              this.isLoading = true;
              await formsService.deleteSurvey(id);
              this.loadItems();
              this.$toast(makeSuccessToast('Survey deleted successfully.'));
            } catch (e) {
              this.$toast(makeErrorToast('Something went wrong. Try again'));
              this.$log.error(e);
            } finally {
              this.isLoading = false;
            }
          }
        });
    },
    async duplicateSurvey(id) {
      try {
        this.isLoading = true;
        await formsService.duplicateSurvey(id);
        this.loadItems();
        this.$toast(makeSuccessToast('Survey duplicated successfully.'));
      } catch (e) {
        this.$toast(makeErrorToast('Something went wrong. Try again'));
        this.$log.error(e);
      } finally {
        this.isLoading = false;
      }
    }
  },
  setup() {
    const { total, perPage, page } = useAomTableConfig();

    return {
      total, 
      perPage, 
      page
    };
  },
};
</script>
    
<style lang="scss" scoped>
  .float-left{
    float: left;
  }

  .badge {
    font-weight: 400 !important;
  }
  table.vgt-table td {
    vertical-align: middle;
  }
</style>

<style lang="scss">
  @import "/src/assets/scss/vue-good-table.scss";
  .nav .dropdown-toggle:not(.active)::after {
    background-image: none !important;
  }
</style>
    
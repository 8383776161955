export const wcagCompliance = Object.freeze({
    NON_COMPLIANT: 1,
    OPT_IN_COMPLIANCE: 2,
    FULL_COMPLIANCE: 3
  });
export const wcagComplianceDisplay = [
    { value: wcagCompliance.NON_COMPLIANT, text: 'Non-compliant'},
    { value: wcagCompliance.OPT_IN_COMPLIANCE, text: 'Opt-in compliance'},
    { value: wcagCompliance.FULL_COMPLIANCE, text:  'Full compliance'}
];


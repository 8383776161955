<template>
  <section>
    <vue-good-table
      class="mt-3"
      mode="remote"
      :is-loading="isLoading"
      :columns="columns"
      :rows="rows"
      :rtl="directionIsRTL"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm,
      }"
      :pagination-options="{
        enabled: true,
        perPage: perPage,
      }"
      style-class="vgt-table striped"
      @on-page-change="onPageChange"
      @on-sort-change="onSortChange"
      @on-column-filter="onColumnFilter"
      @on-per-page-change="onPerPageChange"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >
        <!-- Column: Status -->
        <span v-if="props.column.field === 'status_id'"> 
          <b-badge :variant="STATUS_COLOR[props.row.status.name]">
            {{ props.row.status.name }}
          </b-badge>
        </span>
        <!-- Column: Name -->
        <span v-else-if="props.column.field === 'name'"> 
          <del 
            v-if="props.row.deleted_at" 
            style="color:red;"
          >
            <router-link
              :to="{ name: 'admin-program', params: { id: props.row.id } }"
            >
              {{ props.row.name }} 
            </router-link>
          </del>
          <p v-else>
            <router-link
              :to="{ name: 'admin-program', params: { id: props.row.id } }"
              class="table-primary-link"
            >
              {{ props.row.name }}
            </router-link>
          </p>
        </span>
        <!-- Column: Action -->
        <div
          v-else-if="props.column.field === 'action'"
          cols="12"
          md="2"
          class="justify-content-between flex-wrap vgt-center-align"
        >
          <div class="d-flex align-items-center mb-0 mt-0">
            <b-button
              variant="outline-none"
              class="nav pr-0"
            >
              <b-nav-item-dropdown
                class="btn-icon"
              >
                <template 
                  #button-content
                >
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="20"
                  />
                </template>

                <b-dropdown-item
                  link-class="d-flex align-items-center"
                  :to="{ name: 'admin-program', params: { id: props.row.id } }"
                >
                  <feather-icon
                    size="16"
                    icon="EditIcon"
                    class="mr-50"
                  />
                  <span>Edit</span>
                </b-dropdown-item>

                <b-dropdown-item
                  link-class="d-flex align-items-center"
                  @click.prevent="duplicateProgram(props.row)"
                >
                  <feather-icon
                    size="16"
                    icon="CopyIcon"
                    class="mr-50"
                  />
                  <span>Duplicate</span>
                </b-dropdown-item>

                <b-dropdown-divider />

                <b-dropdown-item
                  link-class="d-flex align-items-center"
                  @click.prevent="suspendProgram(props.row.id)"
                >
                  <feather-icon
                    size="16"
                    icon="FrownIcon"
                    class="mr-50"
                  />
                  <span>Suspend</span>
                </b-dropdown-item>

                <b-dropdown-item
                  link-class="d-flex align-items-center"
                  @click.prevent="setProgramActive(props.row.id)"
                >
                  <feather-icon
                    size="16"
                    icon="SmileIcon"
                    class="mr-50"
                  />
                  <span>Active</span>
                </b-dropdown-item>

                <b-dropdown-item
                  link-class="d-flex align-items-center"
                  @click.prevent="archiveProgram(props.row.id)"
                >
                  <feather-icon
                    size="16"
                    icon="ArchiveIcon"
                    class="mr-50"
                  />
                  <span>Archive</span>
                </b-dropdown-item>
              </b-nav-item-dropdown>
            </b-button>
            <b-button
              variant="outline-none"
              class="btn-icon pr-0  pl-0"
              @click.prevent="deleteProgram(props.row.id)"
            >
              <feather-icon
                size="16"
                icon="TrashIcon"
                class="mr-50"
              />
            </b-button>
          </div>
        </div>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <table-pagination
          :per-page="perPage"
          :total="total"
          @perPageChanged="
            (value) => props.perPageChanged({ currentPerPage: value })
          "
          @pageChanged="
            (value) => props.pageChanged({ currentPage: value })
          "
        />
      </template>
    </vue-good-table>
    <duplicate-program
      :show="showDuplicateModal"
      :program="duplicateProgramModel"
      @duplicated="loadItems"
      @close="closeDuplicateModal"
    />
  </section>
</template>

<script>
import {
  BBadge,
  BButton,
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import store from "@/store/index";
import {
  STATUS_COLOR,
  GOODTABLE_ISO_DATETIME_INPUT_FORMAT,
  GOODTABLE_LOCALISED_DATE_FORMAT,
  GOODTABLE_SEARCH_FIELD_DATE_PICKER_FORMAT,
} from "@/libs/utils";
import TablePagination from "@/views/components/pagination/TablePagination.vue";
import flatPickr from "flatpickr";
import "flatpickr/dist/flatpickr.css";
import "flatpickr/dist/themes/material_blue.css";
import programsService from "@/services/programsService";
import {programTypeDisplay, programStatus, programStatusSelect} from '@models/enums/ePrograms';
import { makeSuccessToast, makeErrorToast } from "@/libs/utils";
import DuplicateProgram from './DuplicateProgram.vue';
import useAomTableConfig from "@aom-core/useAomTableConfig.js";

export default {
  components: {
    VueGoodTable,
    BBadge,
    BButton,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    TablePagination, 
    DuplicateProgram
  },
  props: {
    showDeleted: {
      type: Boolean
    },
    clientId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      showArchived: true,
      isLoading: false,
      element : { },
      dir: false,
      columns: this.gridColumns(),
      columnFilters: [],
      sort: [],
      rows: [],
      searchTerm: '',
      currentProgram : null,
      programTypeDisplay,
      showDuplicateModal: false,
      duplicateProgramModel: {}
    };
  },
  computed: {
    directionIsRTL() {
      return store.state.appConfig.isRTL;
    },
  },
  mounted() {
    const inputs = document.getElementsByClassName('search-date-selector');
    inputs.forEach(function (input) {
      flatPickr(input, {
        enableTime: false,
        dateFormat: GOODTABLE_SEARCH_FIELD_DATE_PICKER_FORMAT,
      });
    });
  },
  created() {
    this.loadItems();
  },
  methods: {
    gridColumns() {
      const cols = [];
      cols.push({
        label: "Program Name",
        field: "name",
        filterOptions: {
          enabled: true,
          placeholder: "Search Name",
        }
      });
        
      if (!this.clientId) {
        cols.push({
          label: "Organisation Name",
          field: "client.name",
          filterOptions: {
            enabled: true,
            placeholder: "Search Organisation",
          }
        });
      }
      cols.push(
        {
          label: "Type",
          field: "type.name",
          filterable: true,
          filterOptions: {
            enabled: true,
            placeholder: "All",
            filterDropdownItems: programTypeDisplay.map(t => ({value:t.id, text: t.text})),
          },
        },
        {
          label: "Licence Start Date",
          field: "licence_start_date",
          filterOptions: {
            enabled: true,
            placeholder: "Search Date",
            filterFn: this.dateFilter
          },
          tdClass: "search-date-selector",
          width: "8em",
          type: 'date',
          dateInputFormat: GOODTABLE_ISO_DATETIME_INPUT_FORMAT,
          dateOutputFormat: GOODTABLE_LOCALISED_DATE_FORMAT
        },
        {
          label: "License End Date",
          field: "licence_end_date",
          filterOptions: {
            enabled: true,
            placeholder: "Search Date",
            filterFn: this.dateFilter
          },
          tdClass: "search-date-selector",
          width: "8em",
          type: 'date',
          dateInputFormat: GOODTABLE_ISO_DATETIME_INPUT_FORMAT,
          dateOutputFormat: GOODTABLE_LOCALISED_DATE_FORMAT
        },
        {
          label: "Total Matches",
          field: "matches_count",
          filterOptions: {
            enabled: true,
            placeholder: "Search",
          },
          width: "10em",
          tdClass: "text-center",
        },
        {
          label: "Matches Allowed",
          field: "match_limit",
          filterOptions: {
            enabled: true,
            placeholder: "Search",
          },
          width: "9em",
          tdClass: "text-center",
        },
        {
          label: "Active Participants",
          field: "active_users_count",
          filterOptions: {
            enabled: true,
            placeholder: "Search",
          },
          width: "9em",
          tdClass: "text-center",
        },
        {
          label: "Status",
          field: "status_id",
          filterable: true,
          filterOptions: {
            enabled: true,
            placeholder: "All",
            filterDropdownItems: programStatusSelect
          },
          width: "8em",
        },
        {
          field: "action",
          width: "8em",
          sortable: false
        }
      );
      return cols;
    },
    async suspendProgram(programId) {
      try {
        const response =
          await programsService.getProgramByProgramId(
            programId
          );

        this.currentProgram = response.data;

        if (this.currentProgram.header_code===null){this.currentProgram.header_code= '--';}
        if (!this.currentProgram.footer_code){this.currentProgram.footer_code= '--';}
        if (!this.currentProgram.email_signature){this.currentProgram.email_signature= '--';}

        this.currentProgram.status_id= programStatus.SUSPENDED;
        await programsService.updateProgram(
          programId,
          this.currentProgram
        );
        this.loadItems();
      } catch (e) {
        this.$log.error(e);
        this.$toast(makeErrorToast());
      }
    },
    async setProgramActive(programId) {
      try {
        const response =
          await programsService.getProgramByProgramId(
            programId
          );

        this.currentProgram = response.data ;

        if(this.currentProgram.header_code===null){this.currentProgram.header_code= '--';}
        if(!this.currentProgram.footer_code){this.currentProgram.footer_code= '--';}
        if(!this.currentProgram.email_signature){this.currentProgram.email_signature= '--';}

        this.currentProgram.status_id= programStatus.ACTIVE;
        await programsService.updateProgram(
          programId,
          this.currentProgram
        );
        this.loadItems();
      } catch (e) {
        this.$log.error(e);
        this.$toast(makeErrorToast());
      }
    },
    async archiveProgram(programId) {
      try {
        const response =
          await programsService.getProgramByProgramId(
            programId
          );

        this.currentProgram = response.data ;

        if(this.currentProgram.header_code===null){this.currentProgram.header_code= '--';}
        if(!this.currentProgram.footer_code){this.currentProgram.footer_code= '--';}
        if(!this.currentProgram.email_signature){this.currentProgram.email_signature= '--';}

        this.currentProgram.status_id= programStatus.ARCHIVED;
        await programsService.updateProgram(
          programId,
          this.currentProgram
        );
        this.loadItems();
      } catch (e) {
        this.$log.error(e);
        this.$toast(makeErrorToast());
      }
    },
    async deleteProgram(programId) {
      this.$bvModal
        .msgBoxConfirm('Are you sure you want to delete this Program ?', {
          title: 'Delete Program',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Delete',
          cancelTitle: 'Cancel',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(async value => {
          if(value) {
            try {
              await programsService.deleteProgram(programId);
              this.$toast(makeSuccessToast('Program deleted successfully.'));
              this.loadItems();
            } catch (e) {
              this.$log.error(e);
              this.$toast(makeErrorToast('Something went wrong. Program not deleted.'));
            }
          }
        });
    },
    onPageChange(params) {
      this.page = params.currentPage;
      this.loadItems();
    },

    onPerPageChange(params) {
      this.perPage = params.currentPerPage;
      this.loadItems();
    },

    onSortChange(params) {
      let field = params[0].field;
      let type = params[0].type;
      if(field === 'type.name') {
        field = "type_id";
      }
      if(field === 'client.name') {
        field = "clients_name";
      }
      if (type !== 'none') {
        this.sort = [{ field: field, type: type }];
      } else {
        this.sort = [];
      }
      this.loadItems();
    },

    onColumnFilter(params) {
      const columnFilters = [];
      for (let col of [
        "name", 
        "client.name",
        "type.name",
        "start_date",
        "end_date",
        "matches_count",
        "match_limit",
        "active_users_count",
        "status_id"
      ]) {
        if (params.columnFilters[col]) {
          if(col === 'type.name'){
            columnFilters.push({
              field: 'type_id',
              value: params.columnFilters[col]
            });
          } else if(col === 'client.name'){
            columnFilters.push({
              field: 'clients_name',
              value: params.columnFilters[col]
            });
          } else {
            columnFilters.push({
              field: col,
              value: params.columnFilters[col],
            });
          }
        }
      }
      this.columnFilters = columnFilters;
      this.loadItems();
    },
    refreshList(){
      if(!this.isLoading){
        this.loadItems();
      }
    },
    async loadItems() {
      try {
        this.isLoading = true;
        const response = await this.callProgramService({
          page: this.page,
          perPage: this.perPage,
          columnFilters: this.columnFilters,
          sort: this.sort,
        });
        const {total, items} = response.data;
        this.total = total;
        this.rows = items;
      } catch (e) {
        this.$log.error(e);
      } finally {
        this.isLoading = false;
      }
    },
    callProgramService(search) {
      if (this.clientId > 0) {
        return programsService.getClientPrograms(this.clientId, search);
      } else {
        return programsService.getList(search);
      }
    },
    duplicateProgram(program) {
      this.showDuplicateModal = true;
      this.duplicateProgramModel = program;
    },
    closeDuplicateModal() {
      this.showDuplicateModal = false;
      this.duplicateProgramModel = {};
    }
  },
  setup() {
    const { total, perPage, page } = useAomTableConfig();

    return {
      STATUS_COLOR,
      total,
      perPage,
      page
    };
  },
};
</script>

<style>
  .search-date-selector{
    text-align: center !important;
  }
</style>

<style lang="scss" scoped>
tbody > tr > td {
  vertical-align: middle !important;
}

tr > td {
  vertical-align: middle !important;
}

.badge {
  font-weight: 400 !important;
}
</style>

<style lang="scss">
@import "/src/assets/scss/vue-good-table.scss";
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('div',{staticClass:"pt-1"},[_c('b-media',{staticClass:"flex-column flex-md-row",attrs:{"id":"media2","no-body":"","vertical-align":"center"}},[_c('b-media-aside',[_c('feather-icon',{staticClass:"d-flex align-items-center move-icon mt-3",attrs:{"icon":"MoveIcon","size":"20"}}),_c('b-img',{staticClass:"rounded mr-2 mb-1 mb-md-0 ml-1",attrs:{"src":_vm.sponsorLogo.url,"width":"115"}})],1),_c('b-media-body',{staticClass:"mb-2"},[_c('div',{staticClass:"mb-2"},[_c('action-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(113, 102, 240, 0.15)'),expression:"'rgba(113, 102, 240, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"primary","size":"sm"},on:{"click":function($event){return _vm.openImageUpload(_vm.index)}}},[(_vm.sponsorLogo.isUpdating)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),(!_vm.sponsorLogo.isUpdating)?_c('span',{staticClass:"text-nowrap"},[_vm._v(" Upload")]):_vm._e()],1),_c('action-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(113, 102, 240, 0.15)'),expression:"'rgba(113, 102, 240, 0.15)'",modifiers:{"400":true}}],staticClass:"ml-1",attrs:{"variant":"outline-secondary","size":"sm"},on:{"click":function($event){return _vm.deleteImage(_vm.sponsorLogo.id)}}},[(_vm.sponsorLogo.isDeleting)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),(!_vm.sponsorLogo.isDeleting)?_c('span',{staticClass:"text-nowrap"},[_vm._v("Delete")]):_vm._e()],1)],1),_c('validation-provider',{ref:("sponsor-file-" + _vm.index),attrs:{"rules":{ size: _vm.MAX_LOGO_SIZE_KB },"vid":("sponsor-file-" + _vm.index),"name":"Sponsor File"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-file',{ref:"refInputImg",staticClass:"hidden",attrs:{"accept":".jpg, .png, .gif","placeholder":"Choose a file","state":_vm.getValidationState(
                validationContext
              )},on:{"input":_vm.inputImageRenderer},model:{value:(_vm.uploadLogo),callback:function ($$v) {_vm.uploadLogo=$$v},expression:"uploadLogo"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getValidationState(
                validationContext
              )}},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])]}}])}),_c('validation-provider',{ref:("sponsor-link-" + _vm.index),attrs:{"rules":{ url_with_protocol: true },"vid":("sponsor-link-" + _vm.index),"name":"Sponsor link"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":"Link","label-for":"sponsor-link"}},[_c('b-form-input',{attrs:{"id":"sponsor-link","maxlength":"255","name":"sponsor-link","placeholder":"Add URL here...","state":_vm.getValidationState(
                  validationContext
                )},model:{value:(_vm.link),callback:function ($$v) {_vm.link=$$v},expression:"link"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getValidationState(
                  validationContext
                )}},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}])})],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <vue-good-table
    class="position-static"
    mode="remote"
    :is-loading="isLoading"
    :columns="columns"
    :rows="rows"
    :rtl="directionIsRTL"
    :search-options="{
      enabled: true,
      externalQuery: searchTerm,
    }"
    :pagination-options="{
      enabled: true,
      perPage: perPage
    }"
    style-class="vgt-table striped"
    @on-page-change="onPageChange"
    @on-sort-change="onSortChange"
    @on-per-page-change="onPerPageChange"
  >
    <template
      slot="table-row"
      slot-scope="props"
    >
      <span v-if="props.column.field === 'survey_type_id'">
        <span v-if="props.row.survey_type_id">{{ surveyTypesDisplay[props.row.survey_type_id] }}</span>
      </span>
      <span
        v-else-if="props.column.field === 'role'"
        class="text-capitalize"
      >
        <span>{{ props.row.role }}</span>
      </span>
      <div
        v-else-if="props.column.field === 'title'"
        cols="12"
        md="2"
        class="justify-content-between flex-wrap"
      >
        <b-link @click="viewSurvey(props.row.id)">
          {{ props.row.title }}
        </b-link>
      </div>
      <span v-else-if="props.column.field === 'responses'">
        <b-link
          :to="{
            name: 'champion-program-survey-result',
            params: {
              id: $route.params.id,
              survey: props.row.id,
            }
          }"
          class="text-primary"
        >
          {{ props.row.responses }}
        </b-link>
      </span>
      <span v-else-if="props.column.field === 'group'">
        <div v-if="!props.row.group" class="text-danger text-center">
          <feather-icon v-b-tooltip.hover.top="'Please add a group to send the survey too'" icon="AlertCircleIcon" size="32" />
        </div>
        {{ props.row.group }}
      </span>
      <div v-else-if="props.column.field === 'is_enabled'">
        <action-checkbox
          :checked="props.row.is_enabled"
          switch
          inline
          @clickNativePrevent="toggleSurveyStatus(props.row.id, !props.row.is_enabled)"
        />
      </div>
      <span v-else>
        {{ props.formattedRow[props.column.field] }}
      </span>

      <!-- Column: Actions -->
      <div
        v-if="props.column.field === 'actions'"
        cols="12"
        md="2"
        class="vgt-center-align d-flex align-items-center"
      >
        <b-button
          variant="outline-none"
          class="nav pr-0"
          style=""
        >
          <b-nav-item-dropdown
            class="btn-icon"
            dropleft
          >
            <template
              #button-content
            >
              <feather-icon
                icon="MoreVerticalIcon"
                size="20"
              />
            </template>
            <action-dropdown-item
              link-class="d-flex align-items-center"
              @click.prevent="editSurvey(props.row.id)"
            >
              <feather-icon
                size="16"
                icon="EditIcon"
                class="mr-50"
              />
              <span>Edit</span>
            </action-dropdown-item>
            <b-dropdown-item
              v-if="!isProgramTemplate"
              link-class="d-flex align-items-center"
              @click.prevent="viewSurveyReport(props.row.id)"
            >
              <feather-icon
                size="16"
                icon="BarChart2Icon"
                class="mr-50"
              />
              <span>Report</span>
            </b-dropdown-item>
            <b-dropdown-item
              link-class="d-flex align-items-center"
              @click.prevent="copySurveyLinkForBulkEmail(props.row.id)"
            >
              <feather-icon
                size="16"
                icon="ClipboardIcon"
                class="mr-50"
              />
              <span>Copy Link For Bulk Email</span>
            </b-dropdown-item>

            <action-dropdown-item
              v-if="!isProgramTemplate"
              link-class="d-flex align-items-center"
              @click="duplicateSurvey(props.row.id)"
            >
              <feather-icon
                size="16"
                icon="CopyIcon"
                class="mr-50"
              />
              <span>Duplicate</span>
            </action-dropdown-item>

            <b-dropdown-item
              v-if="!isProgramTemplate"
              link-class="d-flex align-items-center"
              @click="downloadSurveyExport(props.row.id, 'csv')"
            >
              <span>Export CSV</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="!isProgramTemplate"
              link-class="d-flex align-items-center"
              @click="downloadSurveyExport(props.row.id, 'xlsx')"
            >
              <span>Export XLSX</span>
            </b-dropdown-item>

            <action-dropdown-item
              v-b-tooltip.hover.html.righttop="sentNowTooltip(props.row)"
              link-class="d-flex align-items-center"
              :disabled="!canSendNow(props.row)"
              @click="sendNow(props.row)"
            >
              <feather-icon
                size="16"
                icon="SendIcon"
                class="mr-50"
              />
              <span>Send Now</span>
            </action-dropdown-item>

            <action-dropdown-item
              link-class="d-flex align-items-center"
              @click="deleteSurvey(props.row.id)"
            >
              <feather-icon
                size="16"
                icon="TrashIcon"
                class="mr-50"
              />
              <span>Delete</span>
            </action-dropdown-item>
          </b-nav-item-dropdown>
        </b-button>
      </div>
    </template>

    <!-- pagination -->
    <template
      slot="pagination-bottom"
      slot-scope="props"
    >
      <table-pagination
        :per-page="perPage"
        :total="total"
        @perPageChanged="
          (value) => props.perPageChanged({ currentPerPage: value })
        "
        @pageChanged="(value) => props.pageChanged({ currentPage: value })"
      />
    </template>
  </vue-good-table>
</template>

<script>
import ActionCheckbox from "@/views/components/common/ActionCheckbox.vue";
import ActionButton from "@/views/components/common/ActionButton.vue";

import { mapGetters } from "vuex";
import { BButton, VBToggle, BNavItemDropdown, BDropdownItem, BLink, BFormCheckbox, VBTooltip } from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import store from "@/store/index";
import formsService from "@/services/formsService";
import { makeSuccessToast, makeErrorToast } from "@/libs/utils";
import programsService from "@/services/programsService";
import TablePagination from "@/views/components/pagination/TablePagination.vue";
import { surveyTypesDisplay } from '@models/surveyTypes';
import { BE_API_URL } from "@/constants/app";
import { scheduledActionStatus } from '@/models/scheduledActionStatus';
import { useGetAvailability } from "@/views/apps/useGetAvailability";
import { AOM_PORTAL_SUB_DOMAIN } from '@/constants/app';
import useAomTableConfig from "@aom-core/useAomTableConfig.js";
import { surveyService } from "@/services";
import { actions } from "@/models";
import moment from "moment";
import { roleAlias } from "@/@aom-core/utils/utils";
import ActionDropdownItem from "@/views/components/common/ActionDropdownItem.vue";

export default {
  components: {
    VueGoodTable,
    BButton,
    ActionButton,
    BNavItemDropdown,
    BDropdownItem,
    BLink,
    TablePagination,
    BFormCheckbox,
    ActionCheckbox,
    ActionDropdownItem
  },
  directives: {
    "b-toggle": VBToggle,
    "b-tooltip": VBTooltip,
  },
  props: {
    program: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      isLoading: false,
      columns: this.gridColumns(),
      rows: [],
      searchTerm: "",
      surveyTypesDisplay,
      client: undefined,
    };
  },
  computed: {
    ...mapGetters('app', ['isAdminPortal', 'isChampionPortal']),
    ...mapGetters('programs', ['programUserGroups', 'programById', 'defaultProgram']),

    directionIsRTL() {
      return store.state.appConfig.isRTL;
    },
    isProgramTemplate() {
      return this.program?.is_template === true;
    }
  },
  async created() {
    const program = this.programById(this.$route.params.id);
    if (program && program.length) {
      this.client = program?.[0].client;
    }
    // Need to get user groups first
    await this.$store.dispatch("programs/FETCH_PROGRAM_USER_GROUPS", this.$route.params.id);

    this.loadItems();
  },
  methods: {
    gridColumns() {
      if (this.program?.is_template) {
        return [
          {
            label: "Title",
            field: "title",
            width: "10em",
          },
          {
            label: "Description",
            field: "description",
            width: "13em",
          },
          {
            label: "Survey Type",
            field: "survey_type_id",
            width: "10em",
          },
          {
            label: "Role",
            field: "role",
            width: "8em",
          },
          {
            label: "Group",
            field: "group",
            width: "8em",
          },
          {
            label: "Availability",
            field: "availability",
            width: "10em",
          },
          {
            label: "No. Questions",
            field: "questions_count",
            width: "10em",
            tdClass: "text-center"
          },
          {
            label: "Is enabled?",
            field: "is_enabled",
            width: "8em",
          },
          {
            field: "actions",
            width: "10em",
            sortable: false
          },
        ];
      }
      return [
        {
          label: "Title",
          field: "title",
          width: "15em",
        },
        {
          label: "Survey Type",
          field: "survey_type_id",
          width: "10em",
        },
        {
          label: "Role",
          field: "role",
          width: "8em",
        },
        {
          label: "Group",
          field: "group",
          width: "8em",
        },
        {
          label: "Availability",
          field: "availability",
          width: "10em",
        },
        {
          label: "Responses",
          field: "responses",
          width: "8em",
        },
        {
          label: "No. Questions",
          field: "questions_count",
          width: "10em",
        },
        {
          label: "Enabled",
          field: "is_enabled",
          width: "8em",
        },
        {
          field: "actions",
          sortable: false,
        },
      ];
    },
    async loadItems() {
      try {
        this.isLoading = true;
        const response = await programsService.getSurveysByProgramId(
          this.$route.params.id,
          {
            page: this.page,
            perPage: this.perPage,
            sort: this.sort
          }
        );
        this.total = response.data.total;
        this.rows = response.data.items.map(item => {
          const statusId = item.scheduled_actions && item.scheduled_actions.length > 0 ? item.scheduled_actions[0].status_id : scheduledActionStatus.DISABLED;
          const completedResponses = item.user_forms_completed || 0;
          const numResponses = item.user_forms_count || 0;
          return {
            ...item,
            title: item.translations ? item.translations[0].title : '',
            description: item.translations ? item.translations[0].description : '',
            is_enabled: statusId === scheduledActionStatus.ENABLED,
            responses: `${completedResponses}/${numResponses}`,
            role: item.roles.map(r => roleAlias(r.id, this.defaultProgram)).join(", "),
            group: item.group?.name || "",
            availability: item.scheduled_actions && item.scheduled_actions.length > 0 ? useGetAvailability(item.scheduled_actions[0]) : '',
          };
        });
      } catch (e) {
        const { status, data } = e.response;
        //server may respond with vaidation errors
        if (status === 422 && data.errors) {
          this.$refs.observer.setErrors(data.errors);
        } else {
          this.$log.error(e);
        }
        this.$toast(makeErrorToast("Surveys list not loaded."));
        return;
      } finally {
        this.isLoading = false;
      }
    },

    copySurveyLinkForBulkEmail(id) {
      const survey = this.rows.find(item => item.id === id);
      if (survey && window.navigator) {
        const protocol = window.location.protocol;
        const link = `${protocol}//${AOM_PORTAL_SUB_DOMAIN}.{{BASE_SITE_DOMAIN}}/${this.client.path}/${this.program.path}/surveys/${survey.id}/{{PARTICIPANT_TOKEN}}`;
        window.navigator.clipboard.writeText(link);
        this.$toast(makeSuccessToast("Copied to clipboard."));
      }
    },
    viewSurvey(id) {
      if (this.isAdminPortal) {
        this.$router.push({
          name: "admin-program-survey",
          params: {
            id: this.$route.params.id,
            survey: id
          }
        });
      } else if (this.isChampionPortal) {
        this.$router.push({
          name: "champion-program-survey",
          params: {
            id: this.$route.params.id,
            survey: id
          }
        });
      }
    },

    async deleteSurvey(id) {
      this.$bvModal
        .msgBoxConfirm("Are you sure you want to delete this Survey ?", {
          title: "Delete Survey",
          size: "sm",
          okVariant: "primary",
          okTitle: "Delete",
          cancelTitle: "Cancel",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then(async value => {
          if (value) {
            try {
              this.isLoading = true;
              await formsService.deleteProgramSurvey(this.$route.params.id, id);
              this.$toast(makeSuccessToast("Survey deleted successfully."));
              this.loadItems();
            } catch (e) {
              this.$toast(makeErrorToast("Something went wrong. Try again"));
              this.$log.error(e);
            } finally {
              this.isLoading = false;
            }
          }
        });
    },

    editSurvey(id) {
      this.$nextTick(()=> {
        this.$emit('edit', id);
      });
    },

    async downloadSurveyExport(surveyId, type) {
      const dowloadLink = `${BE_API_URL}/programs/${this.$route.params.id}/surveys/${surveyId}/results/download/${type}`;
      window.document.location = dowloadLink;
    },

    onPageChange(params) {
      this.page = params.currentPage;
      this.loadItems();
    },

    onPerPageChange(params) {
      this.perPage = params.currentPerPage;
      this.loadItems();
    },

    onSortChange(params) {
      let field = params[0].field;
      let type = params[0].type;
      if (type !== 'none') {
        this.sort = [{ field: field, type: type }];
      } else {
        this.sort = [];
      }
      this.loadItems();
    },

    viewSurveyReport(id) {
      if (this.isChampionPortal) {
        this.$router.push({
          name: "champion-program-survey-metric",
          params: {
            id: this.$route.params.id,
            survey: id
          }
        });
      }
    },

    async duplicateSurvey(id) {
      try {
        this.isLoading = true;
        await formsService.duplicateProgramSurvey(this.$route.params.id, id);
        this.$toast(makeSuccessToast("Survey duplicated successfully."));
        this.loadItems();
      } catch (e) {
        this.$toast(makeErrorToast("Something went wrong. Try again"));
        this.$log.error(e);
      } finally {
        this.isLoading = false;
      }
    },

    async toggleSurveyStatus(id, newStatus) {
      const action = newStatus ? 'Enable' : 'Disable';
      const title = `${action} this survey?`;
      const surveyName = this.rows.find(s => s.id === id)?.title || '';
      const description = `Are you sure you want to ${action.toLowerCase()} this survey?\n${surveyName}`;
      const isConfirmed = await this.$bvModal
        .msgBoxConfirm(description, {
          title: title,
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'Cancel',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
          contentClass: 'modal-whitespace-pre-line'
        });

      if (!isConfirmed) {
        return;
      }

      try {
        this.isLoading = true;
        const data = {
          status_id: newStatus ? scheduledActionStatus.ENABLED : scheduledActionStatus.DISABLED
        };
        await formsService.updateProgramSurveyStatus(this.$route.params.id, id, data);
        this.$toast(makeSuccessToast("Survey status updated successfully."));
        this.loadItems();
      } catch (e) {
        this.$toast(makeErrorToast("Something went wrong. Try again"));
        this.$log.error(e);
      } finally {
        this.isLoading = false;
      }
    },

    canSendNow(survey) {
      if (survey && survey.scheduled_actions) {
        const scheduledAction = survey.scheduled_actions[0];
        const mYesterday = moment().subtract(1, "days");
        const mTomorrow = moment().add(1, "days");
        const mEventDate = moment(scheduledAction.absolute_date);
        return (
          scheduledAction.status_id === scheduledActionStatus.ENABLED &&
          scheduledAction.action_id === actions.SHOW &&
          (scheduledAction.actionable_event_id !== null ||
            (scheduledAction.absolute_date &&
              mYesterday <= mEventDate && mEventDate <= mTomorrow))
        );
      }
      return false;
    },

    sentNowTooltip(survey) {
      if (!this.canSendNow(survey)) {
        return `<div class='text-left'>Send now option activates when:<br/>- Survey status is enabled<br/>- Survey is scheduled for a key date, a participant action, or a custom date (absolute date)</div>`;
      }
      return '';
    },

    async sendNow(survey) {
      const h = this.$createElement;
      const messageVNode = h("div", {}, [
        h("p", "Are you sure you want to send this survey now?"),
        h("p", `[${survey.title}]`)
      ]);
      this.$bvModal
        .msgBoxConfirm([messageVNode], {
          title: "Send Now",
          size: "sm",
          okVariant: "primary",
          okTitle: "Yes",
          cancelTitle: "Cancel",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then(async value => {
          if (value) {
            try {
              const result = await surveyService.runSurveyNow(
                survey.program_id,
                survey.id
              );
              if (result) {
                this.$toast(makeSuccessToast("Survey was sent successfully."));
              }
            } catch (e) {
              const { data } = e.response;
              this.$log.error(e);
              this.$toast(makeErrorToast(data.message));
            }
          }
        });
    },
  },
  setup() {
    const { total, perPage, page } = useAomTableConfig();

    return {
      total, 
      perPage, 
      page,
      roleAlias
    };
  }
};
</script>

<style lang="scss">
@import "/src/assets/scss/vue-good-table.scss";

.modal-whitespace-pre-line {
    white-space: pre-line;
}
.vgt-responsive {
  min-height:30vh;
}
</style>
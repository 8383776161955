<template>
  <b-modal
    id="modal-add-resource"
    v-model="showModal"
    :title="`Add ${communications.length} Communication?`"
    centered
    ok-title="Submit"
    cancel-variant="outline-secondary"
    :ok-disabled="isSubmitting"
    size="lg"
    @hidden="onHidden"
    @ok.prevent="onSubmit"
  >
    <b-card>
      <p class="my-1">
        Are you sure you want to add this Communication to this program? Please note, the default rules will apply
      </p>
      <b-table
        v-if="items && items.length > 0"
        :items="items"
        :fields="fields"
      >
        <template #cell(include_default_recipient)="props">
          <v-select
            v-model="props.item.include_default_recipient"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="name"
            class="flex-fill flex-1 email-to-selector"
            :close-on-select="false"
            :options="getGroupsForItem(props.item)"
            input-id="email-to-group"
            :loading="isLoadingGroups"
            append-to-body
            :calculate-position="withPopper"
            placeholder="Select a Group"
          >
            <template #option="{ name, type }">
              <b-row>
                <b-col sm="12">
                  <span class="mr-1"> {{ name }}</span>
                  <b-badge
                    variant="secondary"
                  >
                    {{ type }}
                  </b-badge>
                </b-col>
              </b-row>
            </template>
  
            <template #selected-option="{ avatar, name }">
              <b-avatar
                size="sm"
                class="border border-white"
                :src="avatar"
              />
              <span class="ml-50"> {{ name }}</span>
            </template>
          </v-select>
        </template>
      </b-table>
    </b-card>
  </b-modal>
</template>

<script>
import { BCard, BModal, BTable,  BRow, BCol, BBadge, BAvatar } from "bootstrap-vue";
import { groupsService, commsService }  from "@/services";
import vSelect from 'vue-select';
import { makeErrorToast, makeSuccessToast } from "@/libs/utils";
import { topicChannel, topicTypes, recipientTypes } from '@models';
import { formatDateTimeFromIsoDateTime } from "@/libs/utils";
import { createPopper } from '@popperjs/core';
import { isChampionFilterGroup } from "@/@aom-core/utils/utils";

export default {
  components: {
    BCard,
    BModal,
    BTable,
    vSelect,
    BRow,
    BCol,
    BBadge,
    BAvatar
  },
  props: {
    showModal: {
      type: Boolean
    },
    communications: {
      type: Array,
      required: true,
      default: () => []
    },
    isProgramTemplate: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      items: [],
      fields: [
        {
          key: 'communication_name',
          label: 'Communication Name',
        },
        {
          key: 'include_default_recipient',
          label: 'Include Default Recipient',
        },
      ],
      emailToGroupOptions: [],
      isLoadingGroups: false,
      group: [],
      isSubmitting: false
    };
  },
  watch: {
    communications: {
      handler (n) {
        if(n) {
          this.items = this.communications.map(c => ({
            ...c,
            id: c.id,
            communication_name: c.name,
            include_default_recipient: null
          }));
        }
      },
      deep: true,
      immediate: true
    }
  },
  created() {
    this.getGroups();
  },
  methods: {
    getGroupsForItem(communication) {
      if (communication.scheduled_action?.actionable_event && !communication.scheduled_action?.actionable_event?.key_dates?.length) {
        return this.emailToGroupOptions.filter(group => !isChampionFilterGroup([group]));
      }
      return this.emailToGroupOptions;
    },
    onHidden() {
      this.$emit("hiddenModal", false);
      this.clearForm();
    },
    async resolveService() {
      const programId = this.$route.params.id;
      if (this.isProgramTemplate) {
        return groupsService.getListTemplates(programId);
      }
      return groupsService.getList(programId);
    },
    async getGroups() {
      try {
        this.isLoadingGroups = true;
        const response = await this.resolveService();
        const {items} = response.data;
        this.emailToGroupOptions = items.map(i => ({id: i.id, name: i.name, type: i.type.name, search_expr: i.search_expr}));
      } catch (e) {
        this.$toast(makeErrorToast("Groups list not loaded."));
        console.log(e);
      } finally {
        this.isLoadingGroups = false;
      }
    },
    withPopper(dropdownList, component) {
      dropdownList.style.width = 'auto';
      dropdownList.style.maxWidth = "100vw";
      dropdownList.style.zIndex = "1060";
      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: 'bottom',
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, -1],
            },
          },
          {
            name: 'toggleClass',
            enabled: true,
            phase: 'write',
            fn({ state }) {
              component.$el.classList.toggle(
                'drop-up',
                state.placement === 'top'
              );
            },
          },
        ],
      });
      return () => popper.destroy();
    },
    async onSubmit() {
      this.isSubmitting = true;
      try {
        const programId = this.$route.params.id;

        for (const item of this.items) {
          const payload = {
            name: item.name,
            subject: item.messages[0].subject,
            body: item.messages[0].body,
            is_enabled: false,
            scheduled_actions: [{
              action_id: item.scheduled_action.action_id ?? null,
              actionable_event_id: item.scheduled_action.actionable_event_id ?? null,
              absolute_date: item.scheduled_action.absolute_date ? this.formatDate(item.scheduled_action.absolute_date) : null,
              relative_value: item.scheduled_action.relative_value ?? null,
              relative_unit_id: item.scheduled_action.relative_unit_id ?? null
            }],
            channel_id: topicChannel.EMAIL,
            recipients: [{
              recipient_id: item.include_default_recipient.id,
              recipient_type: recipientTypes.GROUPS,
            }],
            type_id: topicTypes.MESSAGE,
          };

          await commsService.postCreateTopic(programId, payload);
        }

        this.$emit('commsAdded');
        this.$toast(makeSuccessToast("Schedule has been set."));

        // this.showModal = false;
        this.$emit("hiddenModal", false);
      } catch (e) {
        this.$toast(makeErrorToast(e));
        this.$log.error(e);
      } finally {
        this.isSubmitting = false;
      }
    },
    formatDate(datetime) {
      const date = new Date(formatDateTimeFromIsoDateTime(datetime, true, 'Y-M-D'));
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return [year, month, day].join('-');
    },
    clearForm() {
    }
  }
};
</script>


/* eslint-disable camelcase */
import { FormClass } from './formsClass';

export class ApplicationSetClass {
    constructor ({
        id,
        client_id,
        program_id,
        template_id,
        created_at,
        updated_at,
        translations,
        applications
    } = {}) {
        this.id = id;
        this.client_id = client_id;
        this.program_id = program_id;
        this.template_id = template_id;
        this.created_at = created_at;
        this.updated_at = updated_at;
        this.translations = translations.map(t => new ApplicationSetTranslationClass(t));
        this.applications = applications.map(a => new FormClass(a));
    }

}

export class ApplicationSetTranslationClass {
    constructor ({
        id,
        application_set_id,
        locale_id,
        title,
        description,
        client_id,
        created_at,
        updated_at
    } = {}) {
        this.id = id;
        this.client_id = client_id;
        this.application_set_id = application_set_id;
        this.locale_id = locale_id;
        this.title = title;
        this.description = description;
        this.created_at = created_at;
        this.updated_at = updated_at;
    }

}


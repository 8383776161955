<template>
  <b-dropdown-item
    link-class="d-flex align-items-center"
    @click.prevent="removeUser"
  >
    <feather-icon 
      size="16" 
      icon="TrashIcon" 
      class="mr-50"
    />
    <span>Remove</span>
  </b-dropdown-item>
</template>
<script>
import { BDropdownItem } from "bootstrap-vue";

import { makeSuccessToast, makeErrorToast } from "@/libs/utils";
import usersService from "@/services/usersService";

export default {
  name: "RemoveChampion",
  components: {
    BDropdownItem,
  },
  props: {
    user: {
      type: Object,
      default: () => {},
      required: true,
    },
    userId: {
      type: Number,
      required: true,
    },
    isClientUser: {
      type: Boolean,
    },
  },

  methods: {
    modalMessage() {
      return !this.isClientUser
        ? "Are you sure you want to remove this champion? This will cause them to lose access to this program."
        : "Are you sure you want to remove this champion? This will cause them to lose access to all programs associated with this organisation.";
    },
    async removeUser() {
      const result = await this.$bvModal.msgBoxConfirm(this.modalMessage(), {
        title: "Remove Champion?",
        size: "sm",
        okVariant: "primary",
        okTitle: "Remove",
        cancelTitle: "Cancel",
        cancelVariant: "outline-secondary",
        hideHeaderClose: false,
        centered: true,
      });

      if (!result) {
        return;
      }
      try {
        const result = await this.resolveService(this.user.id);
        if (result) {
          this.$toast(makeSuccessToast("Champion removed successfully."));
          this.$emit("updated");
        }
      } catch (e) {
        const { data } = e.response;
        this.$toast(makeErrorToast(data.message));
        this.$log.error(e);
      }
    },
    resolveService(userId) {
      if (this.isClientUser) {
        const clientId = this.$route.params.id || this.$route.params.clientId;
        return usersService.deleteClientChampion(clientId, userId);
      } else {
        const programId = this.$route.params.id;
        return usersService.deleteProgramChampion(programId, userId);
      }
    },
  },
};
</script>
     
<template>
  <b-modal
    id="modal-add-application"
    v-model="showModal"
    title="Add Application"
    centered
    ok-title="Submit"
    cancel-variant="outline-secondary"
    :ok-disabled="isSubmitting"
    @hidden="onHidden"
    @ok.prevent="onSubmit"
  >
    <b-form>
      <!-- Add Application -->
      <v-select
        v-model="selected"
        class="mt-1"
        :dir="dir"
        placeholder="Select Application"
        label="name"
        :options="options"
        :loading="isLoading"
        transition="fade"
        @search="debounceInput"
      />
    </b-form>
    <template #modal-footer="{ ok, cancel}">
      <b-button
        size="md"
        variant="outline-secondary"
        @click="cancel()"
      >
        Cancel
      </b-button>
      <b-button
        size="md"
        variant="primary"
        :disabled="selected.length === 0"
        @click="ok(onSubmit)"
      >
        <b-spinner
          v-if="isSubmitting" 
          small
        />
        <span
          v-if="isSubmitting"
        > Updating...</span>
        <span v-else>Add Application</span>
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import {
  BForm,
  BModal,
  BSpinner,
  BButton
} from "bootstrap-vue";
import formsService from "@/services/formsService";
import programsService from "@/services/programsService";
import _debounce from "lodash/debounce";
import { makeSuccessToast, makeErrorToast } from "@/libs/utils";
import vSelect from "vue-select";

export default {
  components: {
    BForm,
    BModal,
    vSelect,
    BSpinner,
    BButton
  },
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    champion: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      keyword: null,
      selected: [],
      dir: null,
      options: [],
      isLoading: false,
      isSubmitting: false,
    };
  },
  created() {
    this.loadItems();
  },
  methods: {
    async onSubmit() {
      this.isSubmitting = true;
      try {
        const applicationSet = this.selected;
        await programsService.addApplicationSetsToProgram(
          this.$route.params.id,
          { application_set : applicationSet }
        );
        this.$emit("applicationSetsAdded");
        this.$emit("hiddenModal", false);
        this.clearForm();
        this.$toast(makeSuccessToast('Application was successfully added'));
      } catch (e) {
        const { status, data } = e.response;

        if (status === 400) {
          this.$toast.error(makeErrorToast(data.message));
        } else {
          this.$toast.error(makeErrorToast("Add application to program failed."));
        }
        this.$log.error(e);
      } finally {
        this.isSubmitting = false;
      }
    },
    onHidden() {
      this.$emit("hiddenModal", false);
      this.clearForm();
    },
    clearForm() {
      this.selected = [];
    },
    debounceInput: _debounce(function (value) {
      this.keyword = value;
      this.loadItems();
    }, 500),
    async loadItems() {
      try {
        this.isLoading = true;
        const response = await formsService.getApplicationList({
          page: 1,
          perPage: 100,
          columnFilters: this.keyword
            ? [
              {
                field: "title",
                value: this.keyword,
              },
            ]
            : [],
        });
        this.options = response.data.items.map(application => ({
          id: application.id,
          name: application.translations?.[0]?.title,
        }));
      } catch (e) {
        console.log(e);
        this.$toast(makeErrorToast("Application sets list not loaded."));
        this.$log.error(e);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<template>
  <b-card class="program-update-tab-card">
    <b-row>
      <client-program :program="program" />
      <b-col
        cols="6"
        md="6"
      >
        <div class="d-flex align-items-center justify-content-end">
          <!-- Program communication -->
          <div v-if="isProgramCommunication">
            <b-button
              variant="primary"
              style="margin-left: 10px"
              @click="handleShowAddModal"
            >
              <feather-icon
                icon="PlusIcon"
                class="mr-50"
                size="16"
              />
              <span class="text-nowrap">Add Communication</span>
            </b-button>
            <div>
              <add-communication-to-program
                :show-modal="showModal"
                @hiddenModal="hiddenModal"
                @confirmAddComms="onConfirmAddComms"
              />

              <confirm-add-communication-to-program
                :show-modal="showModalConfirm"
                :communications="communications"
                :is-program-template="isProgramTemplate"
                @hiddenModal="hiddenModalConfirm"
                @commsAdded="onRefreshItems"
              />
            </div>
          </div>

          <div v-else>
            <b-button
              variant="primary"
              style="margin-left: 10px"
              @click="shallShowEmailComposeModal=true"
            >
              <feather-icon
                icon="PlusIcon"
                class="mr-50"
                size="16"
              />
              <span class="text-nowrap">Add Communication</span>
            </b-button>
          </div>
          <email-compose
            v-model="shallShowEmailComposeModal"
            :communication="communication"
            :type="emailComposeTypes.SCHEDULED"
            :is-library-comms="isLibraryComms"
            :is-program-template-comms="isProgramTemplate"
            :is-program-communications="isProgramCommunication"
            @emailAdded="emailAdded"
            @emailSent="emailSent"
          />
        </div>
      </b-col>
    </b-row>

    <!-- table program resource-->
    <program-communications-list
      v-if="isProgramCommunication"
      ref="communicationsList"
      @edit="editCommunication"
    />

    <!-- table resource library -->
    <communications-list
      v-else
      ref="communicationsList"
      @edit="editLibCommunication"
    />
  </b-card>
</template>

<script>
import { BButton, BRow, BCol, BCard, VBToggle } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import CommunicationsList from "./CommunicationsList.vue";
import ProgramCommunicationsList from "./ProgramCommunicationsList.vue";
import AddCommunicationToProgram from "./AddCommunicationToProgram.vue";
import EmailCompose from "@/views/components/forms/communication/EmailCompose.vue";
import { ref } from '@vue/composition-api';
import ConfirmAddCommunicationToProgram from "./ConfirmAddCommunicationToProgram.vue";
import { emailComposeTypes } from '@/models/enums/emailComposeTypes';
import { makeSuccessToast } from "@/libs/utils";
import ClientProgram from '@/views/apps/admin/programs/general/ClientProgram.vue';

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    CommunicationsList,
    ProgramCommunicationsList,
    AddCommunicationToProgram,
    EmailCompose,
    ConfirmAddCommunicationToProgram,
    ClientProgram,
    
  },
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
  props: {
    isProgramCommunication: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default:''
    },
    program: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      communication: null,
      showModal: false,
      showModalConfirm: false,
      communications: [],
      emailComposeTypes,
      isLibraryComms: true
    };
  },
  computed: {
    isProgramTemplate() {
      return this.program?.is_template === true;
    }
  },
  methods: {
    onRefreshItems() {
      this.$refs.communicationsList.loadItems();
    },
    editCommunication(comms) {
      this.isLibraryComms = false;
      this.shallShowEmailComposeModal = true;
      this.communication = comms;
    },
    handleShowAddModal() {
      this.showModal = true;
    },
    hiddenModal(isShow) {
      this.showModal = isShow;
    },
    emailAdded() {
      this.$toast(makeSuccessToast("Schedule created successfully."));
      this.isLibraryComms = true;
      this.shallShowEmailComposeModal = false;
      this.onRefreshItems();
    },
    emailSent() {
      this.$toast(makeSuccessToast("Test email has been sent successfully to your email address."));
    },
    async onConfirmAddComms(comms) {
      try {
        this.showModalConfirm = true;
        this.communications = comms;
      } catch (e) {
        this.$log.error(e);
      }
    },
    hiddenModalConfirm(isShow) {
      this.showModalConfirm = isShow;
    },
    editLibCommunication(comms) {
      this.isLibraryComms = true;
      this.shallShowEmailComposeModal = true;
      this.communication = comms;
    }
  },
  setup() {
    // Compose
    const shallShowEmailComposeModal = ref(false);

    return {
      shallShowEmailComposeModal,
    };
  }
};
</script>

<style lang="scss" scoped>
.badge {
  font-weight: 400 !important;
}
table.vgt-table td {
  vertical-align: middle;
}
</style>

<style lang="scss">
@import "~@/assets/scss/base/pages/app-email.scss";
</style>

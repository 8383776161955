var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',{staticClass:"mt-2"},[_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{ref:("title-" + _vm.index),attrs:{"vid":("title-" + _vm.index),"rules":{ required: _vm.isRequired },"name":"Title"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":"Title","label-for":"title"}},[_c('b-form-input',{attrs:{"type":"text","placeholder":"Title","state":_vm.getValidationState(
                validationContext
              )},model:{value:(_vm.selectedTitle),callback:function ($$v) {_vm.selectedTitle=$$v},expression:"selectedTitle"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getValidationState(
                validationContext
              )}},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}])})],1)],1),_c('b-row',{staticClass:"mt-1"},[_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{ref:("description-" + _vm.index),attrs:{"vid":("description-" + _vm.index),"rules":{ required: _vm.isRequired, min: 10 },"name":"Description"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":"Description","label-for":"description"}},[_c('b-form-textarea',{attrs:{"placeholder":"Description","maxlength":"255","rows":"3","state":_vm.getValidationState(
                validationContext
              )},model:{value:(_vm.selectedDescription),callback:function ($$v) {_vm.selectedDescription=$$v},expression:"selectedDescription"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getValidationState(
                validationContext
              )}},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
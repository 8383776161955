<template>
  <div>
    <input
      class="icp__input"
      :style="styleObject"
      type="color"
      :value="value"
      @input="handleColorInput"
      @change="handleColorChange"
    >
    <span
      v-if="wcagComplianceNotice"
      class="wcag-notice success"
    >{{ wcagComplianceNotice }}</span>
    <span
      v-if="nonWcagComplianceNotice"
      class="wcag-notice danger"
    >{{ nonWcagComplianceNotice }}</span>
  </div>
</template>
<script>
export default {
  name: 'VInputColorpicker',
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      wcagComplianceNotice: '',
      nonWcagComplianceNotice: '',
      styleObject: {
        backgroundColor: this.value
      }
    };
  },
  methods: {
    handleColorInput (e) {
      this.wcagComplianceNotice = '';
      this.nonWcagComplianceNotice = '';
      const color = e.target.value;
      const ratio = this.checkContrast(color);
      this.isWCagCompliant(ratio);
      this.$emit('input', e.target.value);
    },
    handleColorChange (e) {
      this.wcagComplianceNotice = '';
      this.nonWcagComplianceNotice = '';
      const color = e.target.value;
      const ratio = this.checkContrast(color);
      this.isWCagCompliant(ratio);
      this.$emit('change', e.target.value);
    },
	  getRGB(c) {
    	try {
    		var c = parseInt(c, 16);
    	} catch (err) {
    		var c = false;
    	}
    	return c;
    },
	  getsRGB(c) {
    	c = this.getRGB(c) / 255;
    	c = (c <= 0.03928) ? c / 12.92 : Math.pow(((c + 0.055) / 1.055), 2.4);
    	return c;
    },
	  getL(c) {
    	return (0.2126 * this.getsRGB(c.substr(1, 2)) + 0.7152 * this.getsRGB(c.substr(3, 2)) + 0.0722 * this.getsRGB(c.substr(-2)));
    },
    Dec2(num) {
      num = String(num);
      if(num.indexOf('.') !== -1) {
        var numarr = num.split(".");
        if (numarr.length == 1) {
          return Number(num);
        }
        else {
          return Number(numarr[0]+"."+numarr[1].charAt(0)+numarr[1].charAt(1));
        }
      }
      else {
        return Number(num);
      }
    },
    checkContrast(colour) {
    	const L1 = this.getL(colour);
      const L2 = this.getL("#ffffff"); 
      const ratio = (Math.max(L1, L2) + 0.05) / (Math.min(L1, L2) + 0.05);
      return ratio;
    },
    isWCagCompliant(ratio) {
      const ratioNice = this.Dec2((ratio * 100) / 100);
      if(ratioNice >= 4.5) {
        this.wcagComplianceNotice = `WCAG Compliant (${ratioNice}:1)`;
      } else {
        this.nonWcagComplianceNotice = `NOT WCAG Compliant (${ratioNice}:1)`;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.icp__input {
  height: 38px;
  border-radius: 5px;
  width: 50px;
  border: none;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  padding: 0;
}

.icp__input::-webkit-color-swatch-wrapper {
  padding: 0;
}

.icp__input::-webkit-color-swatch {
  border: none;
  border-radius: 15px;
}

.icp__input::-moz-color-swatch {
  border: none;
  border-radius: 15px;
}
.wcag-notice {
  display: block;
  min-width:165px;
  width: 165px;
  font-size: 11px;
}
.success {
  color: green;
}
.danger {
  color: red
}
</style>


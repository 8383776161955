import { ScheduledActionsClass } from './scheduledActionsClass';

/* eslint-disable camelcase */
export class FormClass {
  constructor ({
    id,
    type_id,
    form_id = 0,
    client_id,
    program_id,
    template_id,
    application_set_id,
    roles = [],
    translations = [],
    scheduled_actions = [],
    created_at,
    updated_at,
    survey_type_id
  } = {}) {
    this.id = id;
    this.type_id = type_id;
    this.survey_type_id = survey_type_id;
    this.form_id = form_id;
    this.client_id = client_id;
    this.program_id = program_id;
    this.template_id = template_id;
    this.application_set_id = application_set_id;
    this.template_id = template_id;
    this.roles = roles.map(r => new FormRoles(r));
    this.translations = translations.map(t => new FormTranslation(t));
    this.scheduled_actions = scheduled_actions.map(s => new ScheduledActionsClass(s));
    this.created_at = created_at;
    this.updated_at = updated_at;
  }

}

export class FormType {
  constructor ({
    id = undefined,
    name = undefined,
  } = {}) {
    this.id = id;
    this.name = name;
  }
}

export class FormRoles {
  constructor ({
    form_id = undefined,
    id = undefined,
  } = {}) {
    this.form_id = form_id;
    this.id = id;
  }
}

export class FormTranslations {
  constructor ({
    form_id = undefined,
    id = undefined,
    locale_id = undefined, 
    title = undefined, 
    description = undefined
  } = {}) {
    this.form_id = form_id;
    this.id = id;
    this.locale_id = locale_id;
    this.title = title;
    this.description = description;
  }
}
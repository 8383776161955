<template>
  <section>
    <!-- Table Top -->
    <b-sidebar
      id="application-sidebar"
      v-model="openSideBar"
      :title="sideBarTitle"
      bg-variant="white"
      right
      shadow
      backdrop
      aria-controls="add-application-sidebar"
      :aria-expanded="openSideBar"
      width="40%"
    >
      <validation-observer
        ref="observer"
        v-slot="{ pristine, invalid, handleSubmit, errors }"
      >
        <b-form @submit.prevent="handleSubmit(onUpdate)">
          <aom-languages
            :dirty-tabs="isTabDataDirty(errors)"
          >        
            <template slot-scope="props">
              <div v-if="isFetching">
                <question-skeleton-loader />
              </div>
              <application-title-desc
                :title="getApplicationTitle(props.localeId)"
                :index="props.index"
                :locale-id="props.localeId"
                :description="getApplicationDescription(props.localeId)"
                @updateTitle="updateTitle"
                @updateDescription="updateDescription"
              /> 
            </template>
          </aom-languages>
          <b-card>
            <b-row class="mt-5">
              <b-col
                sm="6"
                md="4"
                class="mb-1"
              >
                <b-button
                  block
                  type="reset"
                  variant="outline-dark"
                  @click="clearForm"
                >
                  Cancel
                </b-button>
              </b-col>
              <b-col
                sm="6"
                md="4"
                class="mb-1"
              >
                <b-button
                  block
                  type="submit"
                  variant="primary"
                >
                  <b-spinner
                    v-if="isUpdating" 
                    small
                  />
                  <span
                    v-if="isUpdating"
                  > Updating...</span>
                  <span v-else>Submit</span>
                </b-button>
              </b-col>
            </b-row>
          </b-card>
        </b-form>
      </validation-observer>
    </b-sidebar>
  </section>
</template>
    
<script>
import {
  BRow,
  BCol,
  BCard,
  BSidebar,
  BButton,
  BSpinner,
  BForm
} from "bootstrap-vue";
    
import AomLanguages from "@aom-core/AomLanguages";
import { ValidationObserver } from "vee-validate";
import { makeSuccessToast, makeErrorToast } from "@/libs/utils";
import ApplicationTitleDesc from './ApplicationTitleDesc.vue';
import { ApplicationSetTranslationClass } from '@models/applicationSetClass';  
import formsService from '@/services/formsService';
import QuestionSkeletonLoader from "../../questions/QuestionSkeletonLoader.vue";

export default {
  name: 'EditApplicationSideBar',
  components: {
    BCard,
    BRow,
    BCol,
    BSidebar,
    AomLanguages,
    BButton,
    BSpinner,
    BForm,
    ValidationObserver,
    ApplicationTitleDesc,
    QuestionSkeletonLoader
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    open: {
      type: Boolean,
      default: false
    }, 
    applicationId: {
      type: Number,
      default: 0
    }
  },
  data(vm) {
    return {
      sideBarTitle: vm.title,
      isUpdating: false,
      openSideBar: vm.open,
      application: undefined,
      isFetching: false
    };
  },
  watch: {
    open() {
      this.openSideBar = this.open;
    },
    title() {
      this.sideBarTitle = this.title;
    },
    openSideBar(n) {
      this.$emit('toggleSideBar', n);
    },
    applicationId: {
      handler() {
        if(this.applicationId !== 0) {
          this.getApplicationById();
        }
      },
      immediate: true
    }
  },
  methods: {
    updateTitle (payload) {
      const {title, localeId} = payload;
      this.updateApplicationTranslation({title, locale_id: localeId});
    },
    updateDescription (payload) {
      const {description, localeId} = payload;
      this.updateApplicationTranslation({description, locale_id: localeId});
    },
    closeSideBar () {
      this.openSideBar = false;
      this.$emit('close');
    },
    async getApplicationById() {
      try {
        this.isFetching = true;
        const response = await formsService.getApplicationSet(this.applicationId);
        const { data } = response;
        this.application = data;
      } catch (e) {
						 // this.$log.error(e);
						 this.$toast(makeErrorToast('Error Fetching Application.'));
      } finally {
        this.isFetching = false;
      }
    },
    async onUpdate () {
      try {
        this.isUpdating = true;
        await formsService.updateApplicationSet(this.applicationId, this.application);
        this.$toast(makeSuccessToast('Application updated successfully.'));
        this.clearForm();
      } catch (e) {
        const { status, data } = e.response;
        //server may respond with vaidation errors
        if (status === 422 && data.errors) {
          this.$refs.observer.setErrors(data.errors);
        } 
        // this.$log.error(e);
        this.$toast(makeErrorToast(data.message));
      } finally {
        this.isUpdating = false;
      }
    },
    isTabDataDirty (errors) {
      let tabErrorIndex = [];
      for (const [key, error] of Object.entries(errors)) {
        const regex = /\d+/g;
        if(error.length > 0) {
          const tabIndex = key.match(regex);
          if(Array.isArray(tabIndex)) {
            tabErrorIndex.push(tabIndex[0]);
          }
        }
      }
      return tabErrorIndex;
    },
    clearForm() {
      this.application = undefined;
      this.closeSideBar();
    },
    getApplicationTitle(localeId) {
      if(this.application) {
        const trans = this.application.translations.filter(trans => (trans.locale_id === localeId));
        if(trans.length > 0) {
          return trans[0].title;
        }
        return '';
      }
    },
    getApplicationDescription(localeId) {
      if(this.application) {
        const trans = this.application.translations.filter(trans => (trans.locale_id === localeId));
        if(trans.length > 0) {
          return trans[0].description;
        }
        return '';
      }
    },
    updateApplicationTranslation(trans) {
      if(this.application && this.application.translations){
        const findIndex = this.application.translations.find(t => t.locale_id === trans.locale_id);
        if(findIndex) {
          const newArr = this.application.translations.map(obj => {
            if (obj.locale_id === trans.locale_id) {
              return {...obj, ...trans};
            }
            return obj;
          });
                  
          this.application = {
            ...this.application,
            translations: newArr
          };
              
        } else {
          this.application = {
            ...this.application,
            translations: [
              ...this.application.translations,
              new ApplicationSetTranslationClass(trans)
            ]
          };
        }
      }
    }
  }
};
</script>
    
    <style lang="scss">
      $sidebar-header-text-color: #808080;
      .b-sidebar > .b-sidebar-header{
        flex-direction: row-reverse;
        background: #f3f3f3;
        color: $sidebar-header-text-color;
    
        #application-sidebar___title__ {
          font-size: .8em;
          flex: 2;
        }
      }
    </style>
<template>
  <b-dropdown-item
    link-class="d-flex align-items-center"
    @click.prevent="suspendUser"
  >
    <feather-icon 
      size="16" 
      icon="AlertTriangleIcon" 
      class="mr-50"
    />
    <span>{{ buttonText }}</span>
  </b-dropdown-item>
</template>
<script>
import { BDropdownItem } from "bootstrap-vue";
import { makeSuccessToast, makeErrorToast } from "@/libs/utils";
import usersService from "@/services/usersService";
import { userStatus, userRoles } from "@models";

export default {
  name: "SuspendChampion",
  components: {
    BDropdownItem,
  },
  props: {
    user: {
      type: Object,
      default: () => {},
      required: true,
    },
    userId: {
      type: Number,
      required: true,
    },
    isClientUser: {
      type: Boolean,
    },
  },
  computed: {
    isUserSuspended() {
      if (this.isClientUser) {
        return (
          this.user.user_roles.find(
            ur =>
              ur.client_id == (this.$route.params.id || this.$route.params.clientId) &&
              ur.role_id === userRoles.CHAMPION_CANDIDATE
          )?.status_id === userStatus.SUSPENDED
        );
      } else {
        return (
          this.user.user_roles.find(
            ur =>
              ur.program_id == this.$route.params.id &&
              (ur.role_id === userRoles.CHAMPION || ur.role_id === userRoles.PROGRAM_ADMIN)
          )?.status_id === userStatus.SUSPENDED
        );
      }
    },
    buttonText() {
      return !this.isUserSuspended ? "Suspend" : "Unsuspend";
    },
    modalTitle() {
      return !this.isUserSuspended ? "Suspend Champion" : "Unsuspend Champion";
    },
    modalMessage() {
      return !this.isUserSuspended
        ? "Are you sure you want to suspend this user? They will not have access to their account whilst they are suspended."
        : "Are you sure you want to unsuspend this user? They will regain their access to their account.";
    },
    updatedStatus() {
      return this.isUserSuspended ? userStatus.ACTIVE : userStatus.SUSPENDED;
    },
  },
  methods: {
    successMessage(newStatus) {
      return newStatus === userStatus.SUSPENDED
        ? "Champion Suspended."
        : "Champion Unsuspended.";
    },
    async suspendUser() {
      const result = await this.$bvModal.msgBoxConfirm(`${this.modalMessage}`, {
        title: `${this.modalTitle}`,
        size: "sm",
        okVariant: "primary",
        okTitle: `${this.buttonText}`,
        cancelTitle: "Cancel",
        cancelVariant: "outline-secondary",
        hideHeaderClose: false,
        centered: true,
      });

      if (!result) {
        return;
      }

      try {
        const statusId = this.updatedStatus;
        const result = await this.resolveService(statusId);
        this.$emit("updated");
        if (result) {
          this.$toast(makeSuccessToast(this.successMessage(statusId)));
        }
      } catch (e) {
        const { data } = e.response;
        this.$toast(makeErrorToast(data.message));
        this.$log.error(e);
      }
    },
    resolveService(statusId) {
      if (this.isClientUser) {
        const clientId = this.$route.params.id || this.$route.params.clientId;
        return usersService.setClientChampionStatus(
          clientId,
          this.user.id,
          statusId
        );
      } else {
        const programId = this.$route.params.id;
        return usersService.setProgramChampionStatus(
          programId,
          this.user.id,
          statusId
        );
      }
    },
  },
};
</script>
   
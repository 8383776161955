<template>
  <!-- table -->
  <vue-good-table
    class="mt-3"
    mode="remote"
    :is-loading="isLoading"
    :columns="columns"
    :rows="rows"
    :rtl="directionIsRTL"
    :search-options="{
      enabled: true,
      externalQuery: searchTerm,
    }"
    :pagination-options="{
      enabled: true,
      perPage: perPage,
    }"
    style-class="vgt-table striped"
    @on-page-change="onPageChange"
    @on-sort-change="onSortChange"
    @on-column-filter="onColumnFilter"
    @on-per-page-change="onPerPageChange"
  >
    <template
      slot="table-row"
      slot-scope="props"
    >
      <!-- Column: Actions -->
      <div
        v-if="props.column.label === 'Actions'"
        cols="12"
        md="2"
        class="d-flex align-items-center vgt-center-align"
      >
        <b-button
          :variant="props.row.is_enabled ? 'danger' : 'primary'"
          size="sm"
          @click="toggleCommunicationStatus(props.row)"
        >
          {{ props.row.is_enabled ? 'Disable' : 'Enable' }}
        </b-button>

        <b-button
          variant="outline-none"
          @click="editCommunication(props.row)"
        >
          <feather-icon
            icon="EditIcon"
            size="20"
          />
        </b-button>

        <b-button
          variant="outline-none"
          @click="deleteProgramCommunication(props.row.id)"
        >
          <feather-icon
            icon="TrashIcon"
            size="20"
          />
        </b-button>
      </div>

      <!-- Column: Common -->
      <div v-else-if="props.column.field === 'is_enabled'">
        <aom-status-indicator
          :variant="props.row.is_enabled ? 'success' : 'warning'"
          class="mx-auto"
        />
      </div>
      <span v-else>
        {{ props.formattedRow[props.column.field] }}
      </span>
    </template>
    <!-- pagination -->
    <template
      slot="pagination-bottom"
      slot-scope="props"
    >
      <table-pagination
        :per-page="perPage"
        :total="total"
        @perPageChanged="
          (value) => props.perPageChanged({ currentPerPage: value })
        "
        @pageChanged="(value) => props.pageChanged({ currentPage: value })"
      />
    </template>
  </vue-good-table>
</template>

<script>
import { BButton, VBToggle } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { VueGoodTable } from "vue-good-table";
import store from "@/store/index";
import TablePagination from "@/views/components/pagination/TablePagination.vue";
import {
  makeSuccessToast,
  makeErrorToast,
  formatDateTimeFromIsoDateTime
} from "@/libs/utils";
import { commsService }  from "@/services";
import { topicChannel, topicTypes } from '@models';
import AomStatusIndicator from "@aom-core/AomStatusIndicator";
import { useGetAvailability } from "@/views/apps/useGetAvailability";

export default {
  components: {
    VueGoodTable,
    BButton,
    AomStatusIndicator,
    TablePagination,
  },
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
  data() {
    return {
      isLoading: false,
      perPage: 10,
      page: 1,
      element: {},
      dir: false,
      columns: [
        {
          label: "Email Subject",
          field: "email_subject",
          filterOptions: {
            enabled: true,
            placeholder: "Search Email Subject",
          },
        },
        {
          label: "Recipient",
          field: "recipient",
          filterOptions: {
            enabled: true,
            placeholder: "Search Recipient",
          },
        },
        {
          label: "Availability",
          field: "availability",
          sortable: false,
          filterOptions: {
            enabled: false,
          },
          width: "8em",
        },
        {
          label: "Is enabled?",
          field: "is_enabled",
          filterOptions: {
            enabled: false,
          },
        },
        {
          label: "Actions",
          field: "id",
          sortable: false,
          tdClass: "text-center",
          width: "26m",
        },
      ],
      columnFilters: [],
      sort: [],
      total: 0,
      rows: [],
      searchTerm: "",
    };
  },
  computed: {
    directionIsRTL() {
      return store.state.appConfig.isRTL;
    },
  },
  created() {
    this.loadItems();
  },
  methods: {
    dateFilter(date, filterString) {
      return (date = Date.parse(date) >= Date.parse(filterString));
    },

    onPageChange(params) {
      this.page = params.currentPage;
      this.loadItems();
    },

    onPerPageChange(params) {
      this.perPage = params.currentPerPage;
      this.loadItems();
    },

    onSortChange(params) {
      let field = params[0].field;
      let type = params[0].type;
      if (type !== 'none') {
        if (field == "recipient") {
          field ="topic_recipients.id";
        }else if (field == "email_subject") {
          field ="messages.subject";
        }
        this.sort = [{ field: field, type: type }];
      } else {
        this.sort = [];
      }
      this.loadItems();
    },

    onColumnFilter(params) {
      const columnFilters = [];
      for (let col of [
        "email_subject",
        "is_enabled",
        "recipient",
      ]) {
        if (params.columnFilters[col]) {
          columnFilters.push({
            field: col,
            value: params.columnFilters[col],
          });
        }
      }
      this.columnFilters = columnFilters;
      this.loadItems();
    },

    async loadItems() {
      try {
        this.isLoading = true;
        const response = await commsService.getProgramsCommsScheduled(
          this.$route.params.id,
          {
            page: this.page,
            perPage: this.perPage,
            columnFilters: this.columnFilters,
            sort: this.sort,
          }
        );
        this.total = response.data.total;
        this.rows = response.data.items.map(item => ({
          ...item,
          id: item.id,
          email_subject: item.messages[0].subject,
          recipient: item.topic_recipients[0]?.recipient ? item.topic_recipients[0].recipient.name : "",
          is_enabled: item.is_enabled,
          availability: useGetAvailability(item.scheduled_action),
        }));
      } catch (e) {
        this.$log.error(e);
        this.$toast(makeErrorToast("Scheduled communications list not loaded."));
      } finally {
        this.isLoading = false;
      }
    },

    editCommunication(comm) {
      this.$nextTick(()=> {
        this.$emit('edit', comm);
      });
    },

    async toggleCommunicationStatus(comm) {
      const newStatus = !comm.is_enabled;
      this.$bvModal
        .msgBoxConfirm(`Are you sure you want ${newStatus ? 'enable' : 'disable'} this scheduled communication?`, {
          title: comm.email_subject,
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'Cancel',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(async value => {
          if (value) {
            try {
              const payload = {
                subject: comm.email_subject,
                body: comm.messages[0].body,
                channel_id: topicChannel.EMAIL,
                scheduled_actions: comm.scheduled_action,
                type_id: topicTypes.MESSAGE,
                is_enabled: !comm.is_enabled
              };

              // Add scheduled actions
              payload.is_schedulable = true;

              if (comm.scheduled_action.actionable_event_id) {
                payload.scheduled_actions = [{
                  action_id: comm.scheduled_action.action_id,
                  actionable_event_id: comm.scheduled_action.actionable_event_id,
                  relative_unit_id: comm.scheduled_action.relative_unit_id,
                  relative_value: comm.scheduled_action.relative_value,
                }];
              } else {
                payload.scheduled_actions = [{
                  action_id: comm.scheduled_action.action_id,
                  actionable_event_id: comm.scheduled_action.actionable_event_id,
                  absolute_date: formatDateTimeFromIsoDateTime(comm.scheduled_action.absolute_date, true, 'YYYY-MM-DD'),
                }];
              }

              // Add recipients
              payload.recipients = comm.topic_recipients.map(recp => {
                return {
                  recipient_id: recp.recipient_id,
                  recipient_type: recp.recipient_type,
                };
              });

              const response = await commsService.updateCommsScheduled(comm.id, payload);
              if (response) {
                this.$toast(makeSuccessToast("Scheduled communication is updated"));
                this.loadItems();
              }
            } catch (e) {
              this.$toast(makeErrorToast("Scheduled communication not updated"));
              this.$log.error(e);
            }
          }
        });
    },

    async deleteProgramCommunication(id) {
      this.$bvModal
        .msgBoxConfirm(
          "Are you sure you want to delete this communication? This action cannot be undone",
          {
            title: "Delete Communication",
            size: "sm",
            okVariant: "primary",
            okTitle: "Delete",
            cancelTitle: "Cancel",
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then(async value => {
          if (value) {
            try {
              const response = await commsService.deleteProgramComm(
                this.$route.params.id,
                id
              );

              if (response) {
                this.$toast(makeSuccessToast("Communication deleted successfully."));
                this.loadItems();
              }
            } catch (e) {
              const { data } = e.response;
              this.$log.error(e);
              this.$toast(makeErrorToast(data.message));
            }
          }
        });
    },
  },
};
</script>

<style>
.search-date-selector {
  text-align: center !important;
}
</style>

<style lang="scss">
@import "/src/assets/scss/vue-good-table.scss";
</style>

import { durationUnitsDisplay } from "@/models";
import { localeDateStringFromIsoDateTimeWithoutTimezone } from "@/libs/utils";

export function useGetAvailability(scheduledAction) {
  if (!scheduledAction) { return ''; }

  let when = '';
  if (!scheduledAction.relative_value) {
    when = 'On';
  } else {
    if (scheduledAction.relative_value < 0) {
      when = 'Before';
    } else {
      when = 'After';
    }
  }

  let event = '';
  if (scheduledAction.absolute_date) {
    event = localeDateStringFromIsoDateTimeWithoutTimezone(scheduledAction.absolute_date);
  } else if (scheduledAction.actionable_event) {
    event = scheduledAction.actionable_event.key_dates && scheduledAction.actionable_event.key_dates.length > 0
      ?scheduledAction.actionable_event.key_dates[0].title : scheduledAction.actionable_event.name;

    const keyDate = scheduledAction.actionable_event.key_dates && scheduledAction.actionable_event.key_dates.length > 0
      ? localeDateStringFromIsoDateTimeWithoutTimezone(scheduledAction.actionable_event.key_dates[0].date)
      : "";
    if (keyDate) {
      event += ` ${keyDate}`;
    }
  }

  const period = scheduledAction.relative_value ? Math.abs(scheduledAction.relative_value) : 0;
  const durationArr = durationUnitsDisplay.filter(d => d.id === scheduledAction.relative_unit_id);
  const durationText = durationArr.length > 0 ? durationArr[0].name : '';

  // Only show date for On and custom date
  if (when === 'On' || scheduledAction.absolute_date) {
    return `${when} ${event}`;
  }
  return `${period} ${durationText} ${when} ${event}`;
}
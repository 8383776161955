<template>
  <div>
    <!-- table -->
    <vue-good-table
      ref="refChampionListTable"
      class="position-relative"
      mode="remote"
      :is-loading="isLoading"
      :columns="columns"
      responsive
      :rows="rows"
      :rtl="directionIsRTL"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm,
      }"
      :pagination-options="{
        enabled: true,
        perPage: perPage,
      }"
      style-class="vgt-table striped"
      @on-page-change="onPageChange"
      @on-sort-change="onSortChange"
      @on-column-filter="onColumnFilter"
      @on-per-page-change="onPerPageChange"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >
        <div
          v-if="props.column.field === 'full_name'"
          cols="12"
          md="2"
          style="display: flex"
        >
          <b-link
            @click="updateChampion(props.row.champion)"
          >
            {{ props.row.full_name }}
          </b-link>
        </div>
        <!-- Column: Status -->
        <span v-else-if="props.column.field === 'status'">
          <b-badge :variant="STATUS_COLOR[props.row.status]">
            {{ props.row.status }}
          </b-badge>
        </span>

        <!-- Column: Action -->
        <div
          v-else-if="props.column.field === 'actions'"
          cols="12"
          md="2"
          style="display: flex"
        >
          <b-button
            variant="outline-none"
            @click="updateChampion(props.row.champion)"
          >
            <feather-icon
              icon="EditIcon"
              size="20"
            />
          </b-button>

          <b-button
            variant="outline-none"
            class="nav action-menu"
          >
            <b-nav-item-dropdown
              class="btn-icon"
            >
              <template 
                #button-content
              >
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="20"
                />                
              </template>
              <suspend-champion
                :user="props.row"
                :user-id="props.row.id"
                is-client-user
                @updated="loadItems"
              />
              <remove-champion
                :user="props.row"
                :user-id="props.row.id"
                is-client-user
                @updated="loadItems"
              />
            </b-nav-item-dropdown>
          </b-button>
        </div>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <table-pagination
          :per-page="perPage"
          :total="total"
          @perPageChanged="
            (value) => props.perPageChanged({ currentPerPage: value })
          "
          @pageChanged="(value) => props.pageChanged({ currentPage: value })"
        />
      </template>
    </vue-good-table>
    <edit-champion
      v-if="showModal"
      ref="editChampion"
      :show-modal="showModal"
      :champion="champion"
      @hiddenModal="hiddenModal"
      @championEdited="onChampionEdited"
    />
  </div>
</template>

<script>
import {
  BBadge,
  BButton,
  VBToggle,
  BNavItemDropdown,
  BLink
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { VueGoodTable } from "vue-good-table";
import store from "@/store/index";

import {
  GOODTABLE_ISO_DATETIME_INPUT_FORMAT,
  GOODTABLE_LOCALISED_DATE_FORMAT,
  GOODTABLE_SEARCH_FIELD_DATE_PICKER_FORMAT,
  localDateStringOrDateRangeStringToUtcRange
} from "@/libs/utils";
import TablePagination from "@/views/components/pagination/TablePagination.vue";
import flatPickr from "flatpickr";
import "flatpickr/dist/flatpickr.css";
import "flatpickr/dist/themes/material_blue.css";
import { STATUS_COLOR } from "@/libs/utils";
import championsService from "@/services/championsService";
import { userStatusDisplay } from "@models/userStatus";
import EditChampion from "@/views/apps/admin/champion/EditChampion";
import { makeErrorToast } from "@/libs/utils";
import SuspendChampion  from '@/views/apps/admin/champion/actions/SuspendChampion.vue';
import RemoveChampion from '@/views/apps/admin/champion/actions/RemoveChampion.vue';
import useAomTableConfig from "@aom-core/useAomTableConfig.js";

export default {
  components: {
    VueGoodTable,
    BBadge,
    BButton,
    BNavItemDropdown,
    BLink,
    TablePagination,
    EditChampion,
    SuspendChampion,
    RemoveChampion,
  },
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
  data() {
    return {
      isLoading: false,
      element: {},
      dir: false,
      columns: [
        
        {
          label: "Full Name",
          field: "full_name",
          tdClass: "text-nowrap",
          filterOptions: {
            enabled: true,
            placeholder: "Search Name",
          },
        },
        {
          label: "Email",
          field: "email",
          filterOptions: {
            enabled: true,
            placeholder: "Search Email",
          },
          width: "12em",
        },
        {
          label: "Phone",
          field: "phone",
          filterOptions: {
            enabled: true,
            placeholder: "Search Phone",
          },
          width: "8em",
        },
        {
          label: "Date Created",
          field: "created_at",
          filterOptions: {
            enabled: true,
            placeholder: "Search Date",
            filterFn: this.dateFilter,
          },
          tdClass: "search-date-selector",
          width: "10em",
          type: "date",
          dateInputFormat: GOODTABLE_ISO_DATETIME_INPUT_FORMAT,
          dateOutputFormat: GOODTABLE_LOCALISED_DATE_FORMAT,
        },
        {
          label: "Status",
          field: "status",
          filterable: true,
          filterOptions: {
            enabled: true,
            placeholder: "All",
            filterDropdownItems: ["Active", "Rejected", "Suspended"],
          },
          width: "8em",
        },
        {
          field: "actions",
          width: "6em",
          sortable: false
        },
      ],
      columnFilters: [],
      sort: [],
      rows: [],
      searchTerm: "",
      showModal: false,
      champion: null,
    };
  },
  computed: {
    directionIsRTL() {
      return store.state.appConfig.isRTL;
    },
  },
  async created() {
    await this.loadItems();

    if (this.$route.query.id) {
      const item = this.rows.find(row => Number(row.id) === Number(this.$route.query.id));
      if (item) {
        this.updateChampion(item.champion);
      }
    }
  },
  mounted() {
    const inputs = document.getElementsByClassName("search-date-selector");
    inputs.forEach(function (input) {
      flatPickr(input, {
        enableTime: false,
        dateFormat: GOODTABLE_SEARCH_FIELD_DATE_PICKER_FORMAT,
        allowInput: true,
      });
    });
  },
  methods: {
    dateFilter(date, filterString) {
      return (date = Date.parse(date) >= Date.parse(filterString));
    },
    onPageChange(params) {
      this.page = params.currentPage;
      this.loadItems();
    },

    onPerPageChange(params) {
      this.perPage = params.currentPerPage;
      this.loadItems();
    },

    onSortChange(params) {
      let field = params[0].field;
      let type = params[0].type;
      if (type !== 'none') {
        this.sort = [{ field: field, type: type }];
      } else {
        this.sort = [];
      }
      this.loadItems();
    },

    onColumnFilter(params) {
      const columnFilters = [];
      for (let col of ["status", "full_name", "email", "phone", "created_at"]) {
        if (params.columnFilters[col]) {
          if (col === "created_at") {
            const dateRange = localDateStringOrDateRangeStringToUtcRange(params.columnFilters[col]);
            if (dateRange) {
              columnFilters.push({
                field: col,
                value: dateRange,
              });
            }
          } else {
            columnFilters.push({
              field: col,
              value: params.columnFilters[col],
            });
          }
        }
      }
      this.columnFilters = columnFilters;
      this.loadItems();
    },

    async loadItems() {
      try {
        this.isLoading = true;
        const response = await championsService.getChampionsByClient(
          this.$route.params.clientId,
          {
            page: this.page,
            perPage: this.perPage,
            columnFilters: this.columnFilters,
            sort: this.sort,
          }
        );
        this.total = response.data.total;
        this.rows = response.data.items.map(item => ({
          created_at: item.created_at,
          email: item.email,
          full_name: item.full_name,
          id: item.id,
          phone: item.phone,
          status: userStatusDisplay[item.user_roles[0].status_id],
          champion: item,
          user_roles: item.user_roles,
        }));
      } catch (e) {
        this.$log.error(e);
        this.$toast(makeErrorToast("Champions list not loaded."));
      } finally {
        this.isLoading = false;
      }
    },

    updateChampion(champion) {
      this.champion = champion;
      this.showModal = true;
    },

    hiddenModal(isShow) {
      this.showModal = isShow;
    },

    onChampionEdited() {
      this.loadItems();
    },
  },
  setup() {
    const { total, perPage, page } = useAomTableConfig();
    return {
      STATUS_COLOR,
      total, 
      perPage, 
      page
    };
  },
};
</script>

<style>
.search-date-selector {
  text-align: center !important;
}
</style>

<style lang="scss">
@import "/src/assets/scss/vue-good-table.scss";
.nav .dropdown-toggle:not(.active)::after {
  background-image: none !important;
}
</style>

<style scoped>
.b-nav-dropdown {
  display: flex;
}
</style>

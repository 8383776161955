<template>
  <b-card class="program-update-tab-card">
    <b-card-title>
      Automated Reminders
    </b-card-title>
    <b-card-sub-title>
      Send weekly reminder for participants to complete
    </b-card-sub-title>
    <b-form class="mt-2">
      <b-row>
        <b-col 
          v-for="reminder in reminders"
          :key="reminder.id"
          sm="4"
        >
          <div
            class="d-flex justify-content-start"
          >
            <b-form-group
              :label="reminder.name"
              :label-for="'reminder-switch-' + reminder.id"
              class="mb-0 mr-50"
            >
              <action-checkbox
                v-model="reminder.is_enabled"
                :name="'reminder' + reminder.id"
                switch
                inline
                @change="onSubmit(reminder)"
              />
            </b-form-group>
            <feather-icon
              v-b-tooltip.hover.righttop="reminderTooltips[reminder.subject_id]"
              icon="AlertCircleIcon"
              size="16"
            />
          </div>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BForm,
  BFormGroup,
  BRow,
  BCol,
  BCard,
  BCardTitle,
  BCardSubTitle,
  BFormCheckbox,
  VBTooltip
} from "bootstrap-vue";
import programsService from "@/services/programsService";
import { makeSuccessToast, makeErrorToast } from "@/libs/utils";
import { reminderType, reminderTooltips } from "@/models";
import ActionCheckbox from "@/views/components/common/ActionCheckbox.vue";


export default {
  components: {
    BForm,
    BFormGroup,
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BCardSubTitle,
    ActionCheckbox
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  props: {
    program: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      reminders: [],
      reminderType,
      reminderTooltips
    };
  },
  async created() {
    try {
      const result = await programsService.getProgramReminderSubjects();
      const reminderSubjects = result.data.items.map(subject => ({
        ...subject,
        subject_id: subject.id,
        is_enabled: true
      }));
      const items = [...reminderSubjects];
      for (let r of this.program.program_reminders) {
        const position = reminderSubjects.findIndex(subject => subject.subject_id === r.subject_id);
        if (position >= 0) {
          items[position] = Object.assign({}, reminderSubjects[position], r);
        }
      }
      this.reminders = items;
    } catch (e) {
      console.log(e);
      this.$toast(makeErrorToast());
    }
  },
  methods: {
    onSubmit(reminder) {
      try {
        const data = {
          program_reminders: [
            { subject_id: reminder.subject_id, is_enabled: reminder.is_enabled }
          ]
        };

        const response = programsService.updateProgramReminders(this.program.id, data);
        if (response && response.data) {
          this.$toast(makeSuccessToast("Reminders Updated Successfully."));
        }
      } catch (e) {
        this.$toast(makeErrorToast("Something went wrong. Reminders not saved"));
        this.$log.error(e);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  ::v-deep .custom-control-inline {
    margin-right: 0;
  }
</style>

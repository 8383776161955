<template>
  <validation-observer
    ref="observer"
    v-slot="{ pristine, invalid, handleSubmit }"
  >
    <b-modal
      id="modal-add-program"
      v-model="showModal"
      title="Add New Demo Program"
      centered
      no-close-on-backdrop
      ok-title="Submit"
      cancel-variant="outline-secondary"
      :ok-disabled="pristine || invalid"
      @hidden="onHidden"
      @ok.prevent="handleSubmit(onSubmit)"
    >
      <b-form @submit.prevent>
        <b-row>
          <b-col md="12">
            <validation-provider
              v-slot="validationContext"
              ref="Organisation"
              rules="required"
              name="Organisation"
            >
              <b-form-group 
                label="Organisation"
                label-for="Organisation"
              >
                <v-select
                  v-model="client_id"
                  :dir="dir"
                  placeholder="Please select a Organisation"
                  :options="clientsList"
                  :reduce="(option) => option.value"
                  label="text"
                  @input="loadChampions"
                >
                  <template v-slot:option="option">
                    {{ option.text }} - {{ option.domain }}
                  </template>
                </v-select>
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12">
            <validation-provider
              v-slot="validationContext"
              ref="application_set_id"
              :rules="{ required: true }"
              vid="application_set_id"
              name="Application"
            >
              <b-form-group
                label="Application" 
              >
                <v-select
                  v-model="application_set_id"
                  :dir="dir"
                  placeholder="Please select an Application"
                  :options="applications"
                  :reduce="(option) => option.value"
                  label="text"
                />
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{
                    validationContext.errors[0]
                  }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12">
            <validation-provider
              v-slot="validationContext"
              ref="survey_id"
              :rules="{ required: true }"
              vid="survey_id"
              name="Survey"
            >
              <b-form-group
                label="Survey" 
              >
                <v-select
                  v-model="survey_id"
                  :dir="dir"
                  placeholder="Please select a Survey"
                  :options="surveys"
                  :reduce="(option) => option.value"
                  label="text"
                />
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{
                    validationContext.errors[0]
                  }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12">
            <validation-provider
              v-slot="validationContext"
              ref="default_champion_id"
              :rules="{ required: true }"
              vid="default_champion_id"
              name="Champion"
            >
              <b-form-group
                label="Champion" 
              >
                <v-select
                  v-model="default_champion_id"
                  :dir="dir"
                  placeholder="Please select a default Champion"
                  :options="champions"
                  :reduce="(option) => option.value"
                  label="text"
                />
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{
                    validationContext.errors[0]
                  }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
      </b-form>
      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="outline-secondary"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          type="submit"
          variant="primary"
          @click="ok()"
        >
          <b-spinner
            v-if="isUpdating" 
            small
          />
          <span
            v-if="isUpdating"
          > Updating...</span>
          <span v-else>Submit</span>
        </b-button>
      </template>
    </b-modal>
  </validation-observer>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BForm,
  BModal,
  BFormInvalidFeedback,
  VBModal,
  BButton,
  BSpinner
} from "bootstrap-vue";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
//eslint-disable-next-line
import { required } from "@validations";
import {
  getValidationState,
  makeErrorToast,
  makeSuccessToast
} from "@/libs/utils";
import { clientsService, formsService, championsService, programsService } from "@services";
import { AOM_MAIN_DOMAIN } from '@/constants/app';
import {surveyTypes} from '@/models/surveyTypes';

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BForm,
    BModal,
    vSelect,
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,
    BButton,
    BSpinner
  },
  directives: {
    "b-modal": VBModal,
  },
  data() {
    return {
      client_id: null,
      application_set_id: null,
      survey_id: null,
      default_champion_id: null,
      dir: "ltr",
      clientsList: [],
      applications: [],
      surveys: [],
      champions: [],
      showModal: false,
      isUpdating: false
    };
  },  
  created() {
    this.loadClients();
    this.loadApplicationSets();
    this.loadSurveys();
  },
  methods: {
    async loadApplicationSets() {
      try {
        const response = await formsService.getApplicationList(null);
        this.applications = response.data.items.map(i => {
          return {value: i.id, text: i.translations[0].title};
        });
      } catch (e) {
        this.$toast(makeErrorToast("Applications list not loaded."));
        this.$log.error(e);
      }
    },
    async loadSurveys() {
      try {
        const response = await formsService.getSurveysList({columnFilters: [{field: 'survey_type_id', value: surveyTypes.PARTICIPANT}]});
        this.surveys = response.data.items.map(i => {
          return {value: i.id, text: i.translations[0].title};
        });
      } catch (e) {
        this.$toast(makeErrorToast("Surveys list not loaded."));
        this.$log.error(e);
      }
    },
    async loadChampions(clientId) {
      if (!clientId) {
        this.champions = [];
        this.default_champion_id = null;
        return;
      }
      try {
        const response = await championsService.getChampionsByClient(clientId, null);
        this.champions = response.data.items.map(i => {
          return {value:i.id, text:i.full_name};
        });
      } catch (e) {
        console.log(e);
        this.$toast(makeErrorToast("Champions list not loaded."));
        this.$log.error(e);
      }
    },
    async loadClients() {
      try {
        const response = await clientsService.getList();
        this.clientsList = response.data.items.map(client => {
          // TODO make domain dynamic
          return {value: client.id, text: client.name, domain: `${client.path}.${AOM_MAIN_DOMAIN}`};
        });
      } catch (e) {
        this.$toast(makeErrorToast("Organisations List not loaded."));
        this.$log.error(e);
      }
    },
    show() {
      this.showModal = true;
    },
    onHidden() {
      this.clearForm();
    },
    clearForm() {
      this.client_id = null;
      this.survey_id = null;
      this.application_set_id = null;
      this.default_champion_id = null;
      this.$nextTick(() => {
        this.$refs.observer.reset();
      });
    },
    async onSubmit() {
      try {
        this.isUpdating = true;
        const response = await programsService.createDemo(this.client_id, this.application_set_id, this.survey_id, this.default_champion_id);
        this.showModal = false;
        this.$emit("demoProgramAdded", response.data.id);
        this.$toast(makeSuccessToast("Demo Program saved."));
        await this.$store.dispatch('programs/GET_PROGRAMS');  
      } catch (e) {
        const { status, data } = e.response;
        //server may respond with vaidation errors
        if (status === 422 && data.errors) {
          this.$refs.observer.setErrors(data.errors);
        } else {
          this.$toast(makeErrorToast("Something went wrong. Program not saved."));
          this.$log.error(e);
        }
      } finally {
        this.isUpdating = false;
      }
    },
  },
  setup() {
    return {
      getValidationState
    };
  },
};
</script>
<template>
  <vue-good-table
    class="position-static"
    mode="remote"
    :is-loading="isLoading"
    :columns="columns"
    :rows="rows"
    :rtl="directionIsRTL"
    :search-options="{enabled: false}"
    :pagination-options="{
      enabled: true,
      perPage: perPage
    }"
    style-class="vgt-table striped"
    @on-page-change="onPageChange"
    @on-sort-change="onSortChange"
    @on-per-page-change="onPerPageChange"
  >
  <template 
      slot="table-column" 
      slot-scope="props"
    >
      <span v-if="props.column.field === 'is_locked'">
        {{ props.column.label }}
        <feather-icon
          v-b-tooltip.hover.top="'Locks the application form from being edited after participant submission commences.'"
          icon="AlertCircleIcon"
          class="mr-50 my-icon"
        />
      </span>
      <span v-else>
        {{ props.column.label }}
      </span>
    </template>
    <template
      slot="table-row"
      slot-scope="props"
    >
      <span v-if="props.column.field === 'title'">
        <span v-if="props.row.translations[0]">{{
          props.row.translations[0].title
        }}</span>
      </span>
      <span v-else-if="props.column.field === 'description'">
        <span v-if="props.row.translations[0]">{{
          props.row.translations[0].description
        }}</span>
      </span>
      <span v-else-if="props.column.field === 'all_questions_count'">
        {{ props.formattedRow[props.column.field] }}
      </span>
      <!-- Column: Actions -->
      <div
        v-else-if="props.column.field === 'actions'"
        cols="12"
        md="2"
        class="vgt-center-align d-flex"
      >
        <b-button
          variant="outline-none"
          @click="viewApplication(props.row.id)"
        >
          <feather-icon
            icon="EyeIcon"
            size="20"
          />
        </b-button>
        <b-button
          v-if="isChampionPortal"
          variant="outline-none"
          @click="viewApplicationResult(props.row.id)"
        >
          <feather-icon
            icon="BarChart2Icon"
            size="20"
          />
        </b-button>
        <b-button
          v-if="!props.row.has_answers"
          variant="outline-none"
          @click="removeApplication(props.row.id)"
        >

          <feather-icon
            icon="TrashIcon"
            size="20"
          />
        </b-button>
      </div>
      <div
        v-else-if="props.column.field === 'is_locked'"
      >
        <b-form-checkbox
          :checked="props.row.is_locked"
          class="mr-0 mt-0 application-locked-switch"
          inline
          switch
          size="lg"
          @input="v => onIsLockedInput(props.row.id, v)"
        />
      </div>
    </template>

    <!-- pagination -->
    <template
      slot="pagination-bottom"
      slot-scope="props"
    >
      <table-pagination
        :per-page="perPage"
        :total="total"
        @perPageChanged="
          (value) => props.perPageChanged({ currentPerPage: value })
        "
        @pageChanged="(value) => props.pageChanged({ currentPage: value })"
      />
    </template>
  </vue-good-table>
</template>

<script>
import { mapGetters } from "vuex";
import { BButton, VBToggle, BFormCheckbox, VBTooltip } from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import store from "@/store/index";
import { makeErrorToast } from "@/libs/utils";
import { programsService, applicationService } from "@/services";
import TablePagination from "@/views/components/pagination/TablePagination.vue";
import useAomTableConfig from "@aom-core/useAomTableConfig.js";

export default {
  components: {
    VueGoodTable,
    TablePagination,
    BButton,
    BFormCheckbox
  },
  directives: {
    "b-toggle": VBToggle,
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      isLoading: false,
      columns: [
        {
          label: "Title",
          field: "title",
          width: "10em",
        },
        {
          label: "Description",
          field: "description",
          width: "12em",
        },
        {
          label: "No. Questions",
          field: "all_questions_count",
          width: "5em",
          type: "number"
        },
        {
          field: "actions",
          width: "5em",
          sortable: false,
        },
        {
          label: 'Application Lock',
          field: "is_locked",
          width: "8em",
          sortable: false,
          type: "boolean"
        },
      ],
      rows: [],
    };
  },
  computed: {
    ...mapGetters('app', ['isAdminPortal', 'isChampionPortal']),

    directionIsRTL() {
      return store.state.appConfig.isRTL;
    },
    programId() {
      return Number(this.$route.params.id);
    }
  },
  created() {
    this.loadItems();
  },
  methods: {
    async onIsLockedInput(appSetId, isLocked) {
      try {
        await applicationService.patchProgramApplicationSet(this.programId, appSetId, {is_locked: isLocked});
        this.$toast(makeSuccessToast('Application has been ' + (isLocked ? 'locked' : 'unlocked')));
      } catch (e) {
        
        this.$toast(makeErrorToast(e?.data.message));
      }
    }, 
    async loadItems() {
      try {
        this.isLoading = true;
        const response = await programsService.getApplicationSetsByProgramId(
          this.$route.params.id,
          {
            page: this.page,
            perPage: this.perPage,
            sort: this.sort
          }
        );
        this.total = response.data.total;
        this.rows = response.data.items;
      } catch (e) {
        const { status, data } = e.response;
        //server may respond with vaidation errors
        if (status === 422 && data.errors) {
          this.$refs.observer.setErrors(data.errors);
        } else {
          this.$log.error(e);
        }
        this.$toast(makeErrorToast("Application sets list not loaded."));
        return;
      } finally {
        this.isLoading = false;
      }
    },
    viewApplication(id) {
      if (this.isAdminPortal) {
        this.$router.push({
          name: "admin-program-application",
          params: {
            id: this.$route.params.id,
            application: id
          }
        });
      } else if (this.isChampionPortal) {
        this.$router.push({
          name: "champion-program-application",
          params: {
            id: this.$route.params.id,
            application: id
          }
        });
      }
    },
    async removeApplication(id) {
      try {
        this.isLoading = true;
        const response = await programsService.removeApplicationSet(this.$route.params.id, id);
        this.total = response.data.total;
        this.rows = response.data.items;
      } catch (e) {
        const { status, data } = e.response;
        //server may respond with vaidation errors
        this.$toast(makeErrorToast("This application has already submissions."));
        return;
      } finally {
        this.isLoading = false;
      }
    },
    viewApplicationResult(id) {
      if (this.isChampionPortal) {
        this.$router.push({
          name: "champion-program-application-result",
          params: {
            id: this.$route.params.id,
            application: id
          }
        });
      }
    },

    onPageChange(params) {
      this.page = params.currentPage;
      this.loadItems();
    },

    onPerPageChange(params) {
      this.perPage = params.currentPerPage;
      this.loadItems();
    },

    onSortChange(params) {
      let field = params[0].field;
      let type = params[0].type;
      if (type !== 'none') {
        this.sort = [{ field: field, type: type }];
      } else {
        this.sort = [];
      }
      this.loadItems();
    },
  },

  setup() {
    const { total, perPage, page } = useAomTableConfig();

    return {
      total,
      perPage,
      page
    };
  }
};
</script>

<style lang="scss">
@import "/src/assets/scss/vue-good-table.scss";

.modal-whitespace-pre-line {
    white-space: pre-line;
}
.vgt-responsive {
  min-height:30vh;
}
</style>

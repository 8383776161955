<template>
  <validation-observer
    ref="observer"
    v-slot="{ pristine, invalid, handleSubmit }"
  >
    <b-modal
      id="modal-add-program"
      v-model="showModal"
      title="Add New Program"
      centered
      ok-title="Submit"
      cancel-variant="outline-secondary"
      :ok-disabled="pristine || invalid"
      @hidden="onHidden"
      @ok.prevent="handleSubmit(onSubmit)"
    >
      <b-form @submit.prevent>
        <b-row>
          <b-col md="12">
            <validation-provider
              v-slot="validationContext"
              ref="Organisation"
              rules="required"
              name="Organisation"
            >
              <b-form-group 
                label="Organisation"
                label-for="Organisation"
              >
                <v-select
                  v-model="program.client_id"
                  :dir="dir"
                  placeholder="Please select a Organisation"
                  :options="clientsList"
                  :reduce="(option) => option.value"
                  label="text"
                >
                  <template v-slot:option="option">
                    {{ option.text }} - {{ option.domain }}
                  </template>
                </v-select>
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12">
            <validation-provider
              v-slot="validationContext"
              ref="name"
              :rules="{ required: true }"
              vid="name"
              name="Program Name"
            >
              <b-form-group
                label="Program Name"
                label-for="program-name"
              >
                <b-form-input
                  id="program-name-input"
                  v-model="program.name"
                  placeholder="Program Name"
                  maxlength="255"
                  :state="
                    getValidationState(
                      validationContext
                    )
                  "
                  @change="onNameChange"
                />
                <b-form-invalid-feedback>
                  {{
                    validationContext.errors[0]
                  }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12">
            <validation-provider
              v-slot="validationContext"
              ref="programType"
              rules="required"
              name="Program Type"
            >
              <b-form-group
                label="Program Type"
                label-for="program-type"
              >
                <v-select
                  id="program-type"
                  v-model="program.type_id"
                  label="text"
                  :reduce="programTypeDisplay => (programTypeDisplay.id)"
                  placeholder="Select the Program Type"
                  :options="programTypeDisplay"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12">
            <validation-provider
              v-slot="validationContext"
              ref="subdomain"
              :rules="{ required: true }"
              vid="path"
              name="Program Path"
            >
              <b-form-group
                label="Program Path"
                label-for="program-path-input"
              >
                <b-input-group
                  prepend="organisation-url/"
                  append="/"
                >
                  <b-form-input
                    id="program-path-input"
                    v-model="program.path"
                    placeholder="Path"
                    maxlength="63"
                    :state="
                      getValidationState(
                        validationContext
                      )
                    "
                  />
                </b-input-group>
                <b-form-invalid-feedback
                  :state="
                    getValidationState(
                      validationContext
                    )
                  "
                >
                  {{
                    validationContext.errors[0]
                  }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12">
            <b-form-group
              label="Index URL"
              label-for="indexUrl"
            >
              <b-form-checkbox
                id="indexedSwitch"
                v-model="program.indexed"
                checked="false"
                name="index-url"
                switch
                inline
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="outline-secondary"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          type="submit"
          variant="primary"
          @click="ok()"
        >
          <b-spinner
            v-if="isUpdating" 
            small
          />
          <span
            v-if="isUpdating"
          > Updating...</span>
          <span v-else>Submit</span>
        </b-button>
      </template>
    </b-modal>
  </validation-observer>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BForm,
  BModal,
  BFormCheckbox,
  BFormInvalidFeedback,
  VBModal,
  BButton,
  BSpinner
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
//eslint-disable-next-line
import { required, max } from "@validations";
import {
  toFriendlyUrlPart,
  getValidationState,
  makeErrorToast,
  makeSuccessToast
} from "@/libs/utils";
import clientsService from "@/services/clientsService";
import programsService from "@/services/programsService";
import { ProgramClass } from '@models/classes/programClass';
import { AOM_MAIN_DOMAIN } from '@/constants/app';
import {
  programTypeDisplay
} from '@/models';

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BForm,
    BModal,
    vSelect,
    BFormCheckbox,
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,
    BButton,
    BSpinner
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      name: null,
      dir: "ltr",
      clientsList: [],
      showModal: false,
      program: new ProgramClass(),
      isUpdating: false
    };
  },  
  created() {
    this.loadClients();
  },
  methods: {
    onNameChange() {
      if (this.program?.name) {
        this.program.path = toFriendlyUrlPart(this.program.name);
      }
    },
    async loadClients() {
      try {
        const response = await clientsService.getList();
        this.clientsList = response.data.items.map(i => {
          // TODO make domain dynamic
          return {value:i.id, text:i.name, domain: `${i.path}.${AOM_MAIN_DOMAIN}`};
        });
      } catch (e) {
        this.$toast(makeErrorToast("Organisations List not loaded."));
        this.$log.error(e);
      } finally {
        this.isLoading = false;
      }
    },
    show() {
      this.showModal = true;
    },
    onHidden() {
      this.clearForm();
    },
    clearForm() {
      this.program = new ProgramClass();
      this.$nextTick(() => {
        this.$refs.observer.reset();
      });
    },
    async onSubmit() {
      try {
        this.isUpdating = true;
        await programsService.create(this.program.client_id, this.program);
        this.showModal = false;
        this.$emit("programAdded");
        this.$toast(makeSuccessToast("Program saved."));
        await this.$store.dispatch('programs/GET_PROGRAMS');  
      } catch (e) {
        const { status, data } = e.response;
        //server may respond with vaidation errors
        if (status === 422 && data.errors) {
          this.$refs.observer.setErrors(data.errors);
        } else {
          this.$toast(makeErrorToast("Something went wrong. Program not saved."));
          this.$log.error(e);
        }
      } finally {
        this.isUpdating = false;
      }
    },
  },
  setup() {
    return {
      getValidationState,
      programTypeDisplay
    };
  },
};
</script>
a
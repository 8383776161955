<template>
  <b-card class="program-update-tab-card">
    <b-card-title>
      <span v-b-tooltip.hover.top="'Implement single sign'">
        Single sign on
      </span>
    </b-card-title>
    <b-card-sub-title>
      Single sign on settings
    </b-card-sub-title>

    <validation-observer
      ref="observer"
      v-slot="{ pristine, invalid, handleSubmit }"
    >
      <b-form
        class="mt-2"
        @submit.prevent="handleSubmit(onSubmit)"
      >
        <!-- SP Entity ID & ACS URL -->
        <b-row>
          <b-col md="6">
            <b-form-group
              label="Service Provider Entity ID"
            >
              <b-input-group>
                <b-form-input
                  v-model="localProgram.program_saml.sp_entity_id"
                  :disabled="true"
                />
                <b-input-group-append>
                  <b-button
                    type="button"
                    size="sm"
                    variant="outline"
                    title="Copy text."
                    @click="copyToClipBoard(localProgram.program_saml.sp_entity_id)"
                  >
                    <feather-icon
                      icon="CopyIcon"
                      size="20"
                    />  
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>

            <b-form-group
              label="Assertion Consumer Service (ACS) URL"
            >
              <b-input-group>
                <b-form-input
                  v-model="localProgram.program_saml.acs_url"
                  :disabled="true"
                />
                <b-input-group-append>
                  <b-button
                    type="button"
                    size="sm"
                    variant="outline"
                    title="Copy text."
                    @click="copyToClipBoard(localProgram.program_saml.acs_url)"
                  >
                    <feather-icon
                      icon="CopyIcon"
                      size="20"
                    />  
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row class="mt-2">
          <b-col md="6">
            <b-form-group
              label="Auto Create Participants"
              label-for="create-participant-checkbox"
            >
              <b-form-checkbox
                id="create-participant-checkbox"
                v-model="localProgram.program_saml.create_participant"
                name="create_participant"
                switch
                inline
                vid="create_participant"
              />
            </b-form-group>
          </b-col>

          <b-col md="12">
            <validation-provider
              v-slot="validationContext"
              :rules="{ required: true, url_with_protocol: true }"
              name="IdP SSO Service URL"
              vid="sso_url"
            >
              <b-form-group
                label-for="sso-url-input"
                label="IdP SSO Service URL"
              >
                <b-form-input
                  id="sso-url-input"
                  v-model="localProgram.program_saml.sso_url"
                  name="sso_url"
                  placeholder="https://example.com/saml"
                  :state="
                    getValidationState(validationContext)
                  "
                />
                <b-form-invalid-feedback
                  :state="
                    getValidationState(
                      validationContext
                    )
                  "
                >
                  {{
                    validationContext.errors[0]
                  }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>

        <b-row>
          <b-col md="12">
            <h5 class="mt-5">
              Issuers
              <b-button
                variant="light"
                class="float-right"
                @click="onAddIssuerClick"
              >
                <feather-icon icon="PlusIcon" /> Add Issuer
              </b-button>
            </h5>

            <app-collapse
              class="mt-3"
              accordion
              type="margin"
            >
              <app-collapse-item
                v-for="(item, index) in localProgram.program_saml.issuers"
                :key="index"
                title="issuers"
                :is-visible="(localProgram.program_saml.issuers.length - 1) === index && !item.id"
              >
                <template #header>
                  <span>{{
                    item.entity_id ||
                      "New Issuer " + (index + 1)
                  }}</span>
                  <b-button
                    :disabled="localProgram.program_saml.issuers < 2"
                    variant="Link"
                    size="sm"
                    class="text-decoration-none"
                    title="Delete"
                    @click="deleteIssuer(index)"
                  >
                    <feather-icon icon="TrashIcon" />
                  </b-button>
                </template>
                <validation-provider
                  v-slot="validationContext"
                  :rules="{ required: true, url_with_protocol: true }"
                  name="IdP Entity ID"
                  :vid="'issuers.' + index + '.entity_id'"
                >
                  <b-form-group
                    :label-for="'issuer-entity-id-' + index"
                    label="IdP Entity ID"
                  >
                    <b-form-input
                      :id="'issuer-entity-id-' + index"
                      v-model="item.entity_id"
                      :name="'issuers['+ index +'][entity_id]'"
                      placeholder="https://example.com"
                      :state="
                        getValidationState(validationContext)
                      "
                    />
                    <b-form-invalid-feedback
                      :state="
                        getValidationState(
                          validationContext
                        )
                      "
                    >
                      {{
                        validationContext.errors[0]
                      }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
                <validation-provider
                  v-slot="validationContext"
                  :rules="{ required: true }"
                  name="IdP Public Key (x509)"
                  :vid="'issuers.' + index + '.x509_cert'"
                >
                  <b-form-group
                    :label-for="'issuer-x509-cert-' + index"
                    label="IdP Public Key (x509)"
                  >
                    <b-form-textarea
                      :id="'issuer-x509-cert-' + index"
                      v-model="item.x509_cert"
                      :name="'issuers['+ index +'][x509_cert]'"
                      rows="10"
                      :placeholder="'-----BEGIN CERTIFICATE-----\n...\n-----END CERTIFICATE-----'"
                      :state="
                        getValidationState(validationContext)
                      "
                    />
                    <b-form-invalid-feedback
                      :state="
                        getValidationState(
                          validationContext
                        )
                      "
                    >
                      {{
                        validationContext.errors[0]
                      }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </app-collapse-item>
            </app-collapse>
          </b-col>
        </b-row>

        <b-row
          class="mt-2"
        >
          <b-col
            sm="6"
            class="text-left"
          >
            <b-button
              v-if="isAdminPortal"
              variant="outline-secondary"
              @click="clearForm()"
            >
              Cancel
            </b-button>
            <b-button
              v-else
              variant="light"
              @click="onBack"
            >
              <feather-icon
                icon="ArrowLeftIcon"
                class="mr-50"
              />
              Previous
            </b-button>
          </b-col>

          <b-col
            sm="6"
            class="text-right"
          >
            <b-button
              type="submit"
              variant="primary"
            >
              <b-spinner
                v-if="isUpdating"
                small
              />
              <span
                v-if="isUpdating"
              >Updating...</span>

              <template v-else>
                <span class="mr-50">Update</span>
                <feather-icon icon="SaveIcon" />
              </template>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BCardTitle,
  BCardSubTitle,
  BFormTextarea,
  BFormCheckbox,
  BSpinner,
  BFormInvalidFeedback,
  BInputGroup,
  BInputGroupAppend,
  VBTooltip
} from "bootstrap-vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import Ripple from "vue-ripple-directive";
import FeatherIcon from "@core/components/feather-icon/FeatherIcon.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import programsService from "@/services/programsService";
import { makeErrorToast, makeSuccessToast } from "@/libs/utils";
// eslint-disable-next-line
import { required, urlWithProtocol } from "@validations";
import { getValidationState } from "@/libs/utils";
import { SamlIssuerClass, ProgramClass } from "@/models/classes/programClass";
import { mapGetters } from 'vuex';

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BCardSubTitle,
    BFormTextarea,
    BFormCheckbox,
    BSpinner,
    BFormInvalidFeedback,
    AppCollapse,
    AppCollapseItem,
    FeatherIcon,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  props: {
    program: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      isUpdating: false,
      localProgram: new ProgramClass(this.program)
    };
  },
  computed: {
    ...mapGetters('app', ['isAdminPortal', 'isChampionPortal']),
  },
  methods: {
    onBack() {
      this.$emit('back', '#integrations');
    },
    copyToClipBoard(text) {
      navigator.clipboard.writeText(text)
        .then(() => {
          this.$toast(makeSuccessToast('Copied to clipboard.'));
        })
        .catch(error => {
          this.$toast(makeErrorToast('Cannot copy to clipboard.', error));
        });
    },
    onAddIssuerClick() {
      const newIssuer = new SamlIssuerClass();
      this.localProgram = {
        ...this.localProgram,
        program_saml: {
          ...(this.localProgram.program_saml || {}),
          issuers:[
            ...(this.localProgram.program_saml.issuers || []),
            newIssuer
          ]
        }
      };
    },
    deleteIssuer(index) {
      if(this.localProgram.program_saml.issuers.length === 1) {
        return;
      }
      const keepOptions = this.localProgram.program_saml.issuers.filter((s,i) => i !== index);
      this.localProgram = {
        ...this.localProgram,
        program_saml: {
          ...this.localProgram.program_saml,
          issuers: keepOptions
        }
      };
    },
    async onSubmit() {
      try {
        const response = await programsService.saveSamlSettings(
          this.localProgram.id,
          this.localProgram.program_saml
        );
        this.$toast(makeSuccessToast("Settings saved."));
        this.localProgram = {
          ...this.localProgram,
          program_saml: response.data
        };
        this.is_dirty = false;
        return true;
      } catch (e) {
        const { status, data } = e.response;
        if (status === 422 && data.errors) {
          this.$refs.observer.setErrors(data.errors);
        } else {
          this.$log.error(e);
          this.$toast(makeErrorToast());
        }
        return false;
      } finally {
        this.is_saving = false;
      }
    }
  },
  setup() {
    return {
      getValidationState
    };
  },
};
</script>

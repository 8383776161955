<template>
  <b-card class="program-update-tab-card">
    <!-- Table Top -->
    <b-row class="champions-list-title mb-1">
      <client-program :program="program" />
      <b-col
        v-if="!disabled"
        cols="6" 
        md="6"
      >
        <div class="d-flex justify-content-end add-champion-button">
          <b-button
            v-if="(isAdminPortal || !isProgramAdmin) && !championForClient"
            variant="primary"
            class="mb-1 mr-1"
            @click="addProgramAdmin"
          >
            <span class="text-nowrap">Add Program Admin</span>
          </b-button>
          <b-button
            v-if="championForClient || isProgramAdmin"
            variant="primary"
            class="mb-1"
            @click="createChampion"
          >
            <span class="text-nowrap">Create Champion</span>
          </b-button>
          <b-button
            v-if="!championForClient"
            variant="primary"
            class="mb-1 ml-1"
            @click="addChampion"
          >
            <span class="text-nowrap">Add Champion</span>
          </b-button>
        </div>
      </b-col>
    </b-row>
    <champions-list-for-client
      v-if="championForClient"
      ref="clientList"
    />
    <champions-list
      v-else
      ref="clientList"
    />
    <!-- Add champion to program -->
    <add-champion-to-program
      v-if="showAddModal && !championForClient"
      ref="addChampionToProgram"
      :show-modal="showAddModal"
      :program="program"
      @hiddenModal="hiddenAddModal"
      @championsAdded="onChampionsAdded"
    />
    <!-- Add champion by client / Edit champion -->
    <add-champion
      v-if="showCreateModal && (championForClient || isProgramAdmin)"
      ref="addChampion"
      :show-modal="showCreateModal"
      :client-id="clientId"
      @hiddenModal="hiddenCreateModal"
      @championAdded="onChampionCreated"
    />
    <add-program-admin-to-program
      v-if="!championForClient"
      ref="addProgramAdminToProgramModal"
      :program="program"
      @programAdminAdded="onChampionsAdded"
    />
  </b-card>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BButton,
} from "bootstrap-vue";

import ChampionsList from './ChampionsList.vue';
import AddChampionToProgram from './AddChampionToProgram';
import AddProgramAdminToProgram from './AddProgramAdminToProgram.vue';
import ChampionsListForClient from '@/views/apps/admin/champion/ChampionsListForClient';
import AddChampion from "@/views/apps/admin/champion/AddChampion";
import ClientProgram from '@/views/apps/admin/programs/general/ClientProgram.vue';
import championsService from "@/services/championsService";
import { makeErrorToast } from "@/libs/utils";
import { mapGetters } from "vuex";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    ChampionsList,
    AddChampionToProgram,
    ChampionsListForClient,
    AddChampion,
    ClientProgram,
    AddProgramAdminToProgram
  },
  props: {
    disabled: {
      type: Boolean
    },
    championForClient: {
      type: Boolean
    },
    title: {
      type: String,
      default: ''
    },
    program: {
      type: Object,
      default: () => {}
    },
    isProgramAdmin: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showAddModal: false,
      showCreateModal: false,
    };
  },
  computed: {
    ...mapGetters("app", ["isAdminPortal"]),

    clientId() {
      return this.program?.client_id || Number(this.$route.params.clientId);
    }
  },
  methods: {
    addChampion() {
      this.showAddModal = true;
    },
    createChampion() {
      this.showCreateModal = true;
    },
    addProgramAdmin() {
      this.$refs.addProgramAdminToProgramModal.show();
    },
    async onChampionCreated(obj) {
      if (!this.championForClient && this.program.id) {
        try {
          await championsService.addChampionsToProgram(
            this.program.id,
            {champions: [{id: obj.id}]}
          );
        } catch (e) {
          console.log(e);
          this.$toast(makeErrorToast('Failed to add champion to program.'));
        }
        
      }

      this.$refs.clientList.loadItems();
      this.$emit('championAdded');
    },
    onChampionsAdded() {
      this.$refs.clientList.loadItems();
      this.$emit('championAdded');
    },
    hiddenAddModal(isShow) {
      this.showAddModal = isShow;
    },
    hiddenCreateModal(isShow) {
      this.showCreateModal = isShow;
    }
  },
};
</script>

<style lang="scss">
.champions-list-title {
  margin: top -20px !important;
}
</style>

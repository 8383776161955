<template>
  <b-col sm="6">
    <h4 v-if="title">
      {{ title }}
    </h4>
    <fieldset
      v-if="!title && clientName || programName"
      id="client-program-wrapper"
    >
      <legend>
        {{ clientName }}
      </legend>
      <span><h4>{{ programName }}</h4></span>
    </fieldset>
  </b-col>
</template>
<script>
import {
  BCol,
} from "bootstrap-vue";
import { mapGetters } from 'vuex';
export default {
  name: 'ClientProgram',
  components: {
    BCol
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    program: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    ...mapGetters('programs',['defaultProgram', 'programById']),
    ...mapGetters('app',['isAdminPortal']),
    clientName() {
      return this.defaultProgram && !this.isAdminPortal ? this.defaultProgram?.client?.name : this.client?.name;
    },
    programName() {
      return this.defaultProgram && !this.isAdminPortal ? this.defaultProgram?.name : this.program?.name;
    },
    client() {
      const programClient = this.programById(this.program?.id).shift();
      return programClient?.client;
    }
  }
};
</script>
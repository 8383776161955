<template>
  <b-card :class="inProgramDetail ? 'program-update-tab-card' : ''">
    <!-- Table Top -->
    <b-row class="mb-1">
      <b-col
        v-if="isChampionPortal"
        md="6"
      >
        <h4>
          Applications
        </h4>
      </b-col>
      <client-program
        v-else
        :program="program"
      />

      <b-col
        cols="6"
        md="6"
      >
        <b-button
          v-if="!isChampionPortal && !hasApplication"
          variant="primary"
          class="float-right mb-2 d-flex align-items-center justify-content-end"
          :class="inProgramDetail ? 'outside-button' : ''"
          @click="inProgramDetail ? showModalAddApplication() : toggleSideBar(true, true)"
        >
          <feather-icon
            size="16"
            icon="PlusIcon"
            class="mr-50"
          />
          Add Application
        </b-button>
      </b-col>
    </b-row>
    <b-row
      v-if="!isChampionPortal && hasApplication && inProgramDetail && !isProgramTemplate"
      class="mb-1"
    >
      <b-col class="text-right">
        <b-button
          variant="outline-primary"
          class="btn-icon mr-1"
          @click="showMentorBulkImportModal"
        >
          <feather-icon
            icon="UploadIcon"
            size="16"
            class="mr-50"
          /> 
          Mentors: Import application answers
        </b-button>
        <b-button
          variant="outline-primary"
          class="btn-icon mr-1"
          @click="showMenteeBulkImportModal"
        >
          <feather-icon
            icon="UploadIcon"
            size="16"
            class="mr-50"
          /> 
          Mentees: Import application answers
        </b-button>
      </b-col>
    </b-row>
    <b-modal
      v-model="showImportModal"
      :title="isMentorImport? 'Mentors: Import application answers': 'Mentees: Import application answers'"
      centered
    >
      <p v-if="isMentorImport">
        Upload mentor application answers as CSV
      </p>
      <p v-else>
        Upload mentee application answers as CSV
      </p>
      <b-alert
        class="message-error mb-2 px-2 py-1"
        variant="danger"
        :show="!!importError"
      >
        <p
          class="font-weight-normal"
          v-html="importError"
        />
      </b-alert>
      <b-link
        class="text-primary"
        @click="downloadApplicationAnswers"
      >
        {{ isMentorImport? 'Download mentor sample template': 'Download mentee sample template' }}
      </b-link>

      <validation-observer
        ref="observer"
      >
        <validation-provider
          v-slot="validationContext"
          ref="file"
          :rules="{ required: true }"
          name="File"
        >
          <b-form-file
            ref="refInputEl"
            v-model="blogFile"
            :state="
              getValidationState(
                validationContext
              )
            "
            class="mt-3"
            placeholder="Add file"
            accept=".csv"
            drop-placeholder="Drop file here..."
            type="file"
            plain
          />
          <b-form-invalid-feedback
            :state="
              getValidationState(
                validationContext
              )
            "
          >
            {{
              validationContext.errors[0]
            }}
          </b-form-invalid-feedback>
        </validation-provider>
      </validation-observer>
      <!-- Modal Footer -->
      <template #modal-footer>
        <div>
          <b-button
            variant="primary"
            right
            @click="onImportApplicationAnswers"
          >
            <b-spinner
              v-if="isImporting"
              style="margin-right: 5px"
              small
            />
            <span v-if="isImporting">Uploading...</span>
            <span v-else>Upload</span>
          </b-button>
        </div>
      </template>
    </b-modal>
    


    <!-- Application in program detail -->
    <template v-if="inProgramDetail">
      <application-list-program
        ref="applicationsListProgram"
        @edit="editApplication"
      />
      <!-- Add application to program modal -->
      <add-application-to-program
        v-if="!isChampionPortal"
        ref="addApplicationToProgram"
        :show-modal="showModal"
        @hiddenModal="hiddenModal"
        @applicationSetsAdded="onApplicationSetsAdded"
      />
    </template>

    <template v-else>
      <application-list
        ref="applicationsList"
        @edit="editApplication"
      />

      <application-side-bar
        :title="sideBarTitle"
        :application-id="applicationId"
        :open="openAdd"
        @edit="editApplication"
        @toggleSideBar="toggleAddSideBar"
      />
    </template>

    <edit-application-side-bar
      :title="sideBarTitle"
      :application-id="applicationId"
      :open="openEdit"
      @toggleSideBar="toggleEditSideBar"
    />
  </b-card>
</template>
  
<script>
import { mapGetters } from "vuex";
import {
  BCard,
  BRow,
  BButton,
  BCol,
  BModal,
  BSpinner,
  BAlert,
  BLink,
  BFormFile,
  BFormInvalidFeedback
} from "bootstrap-vue";
import ApplicationList from "./ApplicationList.vue";
import ApplicationSideBar from './ApplicationSideBar.vue';
import EditApplicationSideBar from './EditApplicationSidebar.vue';
import AddApplicationToProgram from './AddApplicationToProgram.vue';
import ApplicationListProgram from "./ApplicationListProgram.vue";
import ClientProgram from '@/views/apps/admin/programs/general/ClientProgram.vue';
import { userRoles } from "@/models";
import { ValidationProvider, ValidationObserver } from "vee-validate";
//eslint-disable-next-line
import { required } from "@validations";
import { getValidationState } from "@/libs/utils";
import { BE_API_URL } from "@/constants/app";
import { applicationService } from "@/services";
import { makeSuccessToast, makeErrorToast, convertModelToFormData } from "@/libs/utils";

export default {
  name: 'Applications',
  components: {
    BCard,
    BRow,
    BButton,
    BCol,
    ApplicationList,
    ApplicationSideBar,
    EditApplicationSideBar,
    AddApplicationToProgram,
    ApplicationListProgram,
    ClientProgram,
    BModal,
    BSpinner,
    BAlert,
    ValidationProvider,
    ValidationObserver,
    BFormInvalidFeedback,
    BLink,
    BFormFile,
  },
  props: {
    title: {
      type: String,
      default: ""
    },
    inProgramDetail: {
      type: Boolean,
      default: false
    },
    program: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      openAdd: false,
      openEdit: false,
      sideBarTitle: '',
      applicationId: undefined,
      showModal: false,
      showImportModal: false,
      importError: null,
      role: null,
      isImporting: false,
      blogFile: null,
    };
  },
  computed: {
    ...mapGetters("app", ['isChampionPortal']),
    hasApplication () {
      return this.program?.application_set;
    },
    isMentorImport() {
      return this.role === userRoles.MENTOR;
    },
    isProgramTemplate() {
      return this.program?.is_template === true;
    }
  },
  methods: {
    toggleSideBar(value, isAdd = false) {
      if(isAdd) {
        this.sideBarTitle = 'Add Form';
      }
      this.openAdd = value;
    },
    editApplication(id) {
      this.openEdit = true;
      this.applicationId = id;
      this.sideBarTitle = 'Edit Application';
    },
    clear() {
      this.openEdit = false;
      this.openAdd = false;
      this.applicationId = undefined;
      this.sideBarTitle = '';
      this.$refs.applicationsList.loadItems();
    },
    toggleAddSideBar(value) {
      if(!value) {
        this.clear();
      }
    },
    toggleEditSideBar(value) {
      if(!value) {
        this.clear();
      }
    },
    showModalAddApplication() {
      this.showModal = true;
    },
    hiddenModal(isShow) {
      this.showModal = isShow;
    },
    onApplicationSetsAdded () {
      this.$emit('applicationAdded');
    },
    showMentorBulkImportModal() {
      this.showImportModal = true;
      this.importError = null;
      this.role = userRoles.MENTOR;
    },
    showMenteeBulkImportModal() {
      this.showImportModal = true;
      this.importError = null;
      this.role = userRoles.MENTEE;
    },
    hideBulkImportModal() {
      this.showImportModal = false;
    },
    downloadApplicationAnswers() {
      const dowloadLink = `${BE_API_URL}/programs/${this.program.id}/applications/role/${this.role}/results/download/csv`;
      window.document.location = dowloadLink;
    },
    async onImportApplicationAnswers() {
      this.$refs.observer.validate().then(async valid => {
        if(!valid) return;
        try {
          this.isImporting = true;
          const programId = this.program.id;
          await applicationService.importApplicationAnswers(
            programId, this.role,
            convertModelToFormData({file: this.blogFile})
          );
          this.$toast(makeSuccessToast("Application answers were imported successfully."));
          this.hideBulkImportModal();
        } catch(e) {
          const { status, data } = e.response;
          if (status === 422 && data.errors) {
            const formatMessage = mess => {
              if (/The \d+\./.test(mess)) {
                const words = mess.split(' ');
                words[0] = "Row";
                words[1] = words[1].replace(/\.|_/g, ' ');
                return words.join(" ");
              }
              return mess;
            };
            const messages = [];
            Object.keys(data.errors).map((key, index) => {
              if (data.errors[key] && index <= 5) {
                messages.push(formatMessage(data.errors[key][0]));
              }
            });
            this.importError = messages.join("<br/>");
            this.$refs.observer.setErrors(data.errors);
          } else {
            this.$toast(makeErrorToast(data.message));
          }
        } finally {
          this.isImporting = false;
        }
      });
    }
  },
  setup() {
    return {
      getValidationState
    };
  },
};
</script>
  
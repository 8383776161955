<template>
  <vue-good-table
    class="mt-3"
    mode="remote"
    :is-loading="isLoading"
    :columns="columns"
    :rows="rows"
    :rtl="directionIsRTL"
    :search-options="{
      enabled: true,
      externalQuery: searchTerm,
    }"
    :pagination-options="{
      enabled: true,
      perPage: perPage,
    }"
    style-class="vgt-table striped"
    @on-page-change="onPageChange"
    @on-sort-change="onSortChange"
    @on-column-filter="onColumnFilter"
    @on-per-page-change="onPerPageChange"
  >
    <template
      slot="table-row"
      slot-scope="props"
    >
      <div
        v-if="props.column.field === 'course_name'"
        cols="12"
        md="2"
        class="d-flex vgt-center-align"
      >
        <b-link
          @click="editTraining(props.row)"
        >
          {{ props.row.course_name }}
        </b-link>
      </div>
      <!-- Column: Actions -->
      <div
        v-else-if="props.column.field === 'actions'"
        cols="12"
        md="2"
        class="d-flex vgt-center-align"
      >
        <b-button
          variant="outline-none"
          @click="deleteTraining(props.row.id)"
        >
          <feather-icon
            icon="TrashIcon"
            size="20"
          />
        </b-button>
      </div>

      <!-- Column: Common -->
      <span
        v-else-if="props.column.field === 'role'"
        class="text-capitalize"
      >
        <span>{{ props.row.role }}</span>
      </span>
      <span v-else>
        {{ props.formattedRow[props.column.field] }}
      </span>
    </template>
    <!-- pagination -->
    <template
      slot="pagination-bottom"
      slot-scope="props"
    >
      <table-pagination
        :per-page="perPage"
        :total="total"
        @perPageChanged="
          (value) => props.perPageChanged({ currentPerPage: value })
        "
        @pageChanged="(value) => props.pageChanged({ currentPage: value })"
      />
    </template>
  </vue-good-table>
</template>

<script>
import { BButton, VBToggle, BLink } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { VueGoodTable } from "vue-good-table";
import store from "@/store/index";
import TablePagination from "@/views/components/pagination/TablePagination.vue";
import { makeSuccessToast, makeErrorToast } from "@/libs/utils";
import trainingsService from "@/services/trainingsService";
import { participantRolesDisplay } from "@/models";
import { useGetAvailability } from "@/views/apps/useGetAvailability";
import useAomTableConfig from "@aom-core/useAomTableConfig.js";

export default {
  name: 'TrainingListAdmin',
  components: {
    VueGoodTable,
    BButton,
    TablePagination,
    BLink
  },
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
  data() {
    return {
      isLoading: false,
      columns: [
        {
          label: "Training Name",
          field: "course_name",
          filterOptions: {
            enabled: true,
            placeholder: "Search Name",
          },
        },
        {
          label: "Role",
          field: "role",
          filterOptions: {
            enabled: true,
            placeholder: "All",
            filterDropdownItems: participantRolesDisplay.map(item => item.text),
          },
          width: "16em",
        },
        {
          label: "Availability",
          field: "availability",
          filterOptions: {
            enabled: false,
          },
          width: "16em",
          sortable: false
        },
        {
          field: "actions",
          sortable: false,
        },
      ],
      columnFilters: [],
      sort: [],
      rows: [],
      searchTerm: "",
    };
  },
  computed: {
    directionIsRTL() {
      return store.state.appConfig.isRTL;
    },
  },
  async created() {
    await this.loadItems();
    if (this.$route.query.id) {
      const item = this.rows.find(row => Number(row.id) === Number(this.$route.query.id));
      if (item) {
        this.editTraining(item);
      }
    }
  },
  methods: {
    onPageChange(params) {
      this.page = params.currentPage;
      this.loadItems();
    },

    onPerPageChange(params) {
      this.perPage = params.currentPerPage;
      this.loadItems();
    },

    onSortChange(params) {
      let field = params[0].field;
      let type = params[0].type;
      if (type !== 'none') {
        if (params[0].field === "role") {
          field = "roles.role_names";
        }
        this.sort = [{ field, type: type }];
      } else {
        this.sort = [];
      }
      this.loadItems();
    },

    onColumnFilter(params) {
      const columnFilters = [];
      for (let col of ["course_name", "role"]) {
        if (params.columnFilters[col]) {
          if (col === "role") {
            columnFilters.push({
              field: "roles.name",
              value: params.columnFilters[col],
            });
          } else {
            columnFilters.push({
              field: col,
              value: params.columnFilters[col],
            });
          }
        }
      }
      this.columnFilters = columnFilters;
      this.loadItems();
    },

    async loadItems() {
      try {
        this.isLoading = true;
        const response = await trainingsService.getLibraryTrainings({
          page: this.page,
          perPage: this.perPage,
          columnFilters: this.columnFilters,
          sort: this.sort,
        });
        this.total = response.data.total;
        this.rows = response.data.items.map(item => ({
          id: item.id,
          course_name: item.course_name,
          role: item.roles.map(r => r.name).join(", "),
          availability: useGetAvailability(item.scheduled_action),
          ...item
        }));
      } catch (e) {
        this.$log.error(e);
        this.$toast(makeErrorToast("Training list not loaded."));
      } finally {
        this.isLoading = false;
      }
    },

    async deleteTraining(id) {
      this.$bvModal
        .msgBoxConfirm(
          "Are you sure you want to delete this training? This action cannot be undone.",
          {
            title: "Delete Training",
            size: "sm",
            okVariant: "primary",
            okTitle: "Delete",
            cancelTitle: "Cancel",
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then(async value => {
          if (value) {
            try {
              const response = await trainingsService.deleteLibraryTraining(id);

              if (response) {
                this.$toast(makeSuccessToast("Training deleted successfully."));
                this.loadItems();
              }
            } catch (e) {
              const { data } = e.response;
              this.$log(e);
              this.$toast(makeErrorToast(data.message));
            }
          }
        });
    },

    editTraining(training) {
      this.$nextTick(() => {
        this.$emit("edit", training);
      });
    },
  },
  setup() {
    const { total, perPage, page } = useAomTableConfig();

    return {
      total, 
      perPage, 
      page
    };
  },
};
</script>

<style>
.search-date-selector {
  text-align: center !important;
}
</style>

<style lang="scss">
@import "/src/assets/scss/vue-good-table.scss";
.nav .dropdown-toggle:not(.active)::after {
  background-image: none !important;
}
</style>

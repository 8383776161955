<template>
  <validation-observer
    ref="observer"
    v-slot="{ pristine, invalid, handleSubmit }"
  >
    <b-modal
      id="modal-add-program-from-template"
      v-model="showModal"
      title="Add New Program"
      centered
      ok-title="Submit"
      size="lg"
      cancel-variant="outline-secondary"
      :ok-disabled="pristine || invalid"
      :no-close-on-backdrop="true"
      @hidden="onHidden"
      @ok.prevent="handleSubmit(onSubmit)"
    >
      <b-form @submit.prevent>
        <b-row>
          <b-col md="12">
            <validation-provider
              v-slot="validationContext"
              ref="Organisation"
              rules="required"
              name="Organisation"
            >
              <b-form-group 
                label="Organisation"
                label-for="Organisation"
              >
                <v-select
                  v-model="program.client_id"
                  :dir="dir"
                  placeholder="Please select a Organisation"
                  :options="clientsList"
                  :reduce="(option) => option.value"
                  label="text"
                >
                  <template v-slot:option="option">
                    {{ option.text }} - {{ option.domain }}
                  </template>
                </v-select>
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12">
            <validation-provider
              v-slot="validationContext"
              ref="name"
              :rules="{ required: true }"
              vid="name"
              name="Program Name"
            >
              <b-form-group
                label="Program Name"
                label-for="program-name"
              >
                <b-form-input
                  id="program-name-input"
                  v-model="program.name"
                  placeholder="Program Name"
                  maxlength="255"
                  :state="
                    getValidationState(
                      validationContext
                    )
                  "
                  @change="onNameChange"
                />
                <b-form-invalid-feedback>
                  {{
                    validationContext.errors[0]
                  }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12">
            <validation-provider
              v-slot="validationContext"
              ref="subdomain"
              :rules="{ required: true }"
              vid="path"
              name="Program Path"
            >
              <b-form-group
                label="Program Path"
                label-for="program-path-input"
              >
                <b-input-group
                  prepend="organisation-url/"
                  append="/"
                >
                  <b-form-input
                    id="program-path-input"
                    v-model="program.path"
                    placeholder="Path"
                    maxlength="63"
                    :state="
                      getValidationState(
                        validationContext
                      )
                    "
                  />
                </b-input-group>
                <b-form-invalid-feedback
                  :state="
                    getValidationState(
                      validationContext
                    )
                  "
                >
                  {{
                    validationContext.errors[0]
                  }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <validation-provider
              v-slot="validationContext"
              ref="programPlan"
              rules="required"
              name="Program Plan"
              vid="program_plan_id"
            >
              <b-form-group
                label="Program Plan"
                label-for="program-plan"
              >
                <v-select
                  id="program-plan"
                  v-model="program.program_plan_id"
                  label="text"
                  :reduce="programPlansDisplay => (programPlansDisplay.id)"
                  placeholder="Select the Program Plan"
                  :options="programPlansDisplay"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="6">
            <validation-provider
              v-slot="{ errors }"
              ref="start-date"
              :rules="{ required: true }"
              name="Start Date"
            >
              <b-form-group label="Licence Start Date">
                <flat-pickr
                  v-model="program.licence_start_date"
                  :state="errors.length > 0 ? false : null"
                  class="form-control"
                  placeholder="Select Date"
                  :config="datePickerConfig"
                  @on-change="onStartDateChange"
                />
              </b-form-group>
              <span class="text-danger">{{ errors[0] }}</span>
            </validation-provider>
          </b-col>
          <b-col sm="6">
            <validation-provider
              v-slot="{ errors }"
              ref="end-date"
              :rules="{ required: true }"
              name="End Date"
            >
              <b-form-group label="Licence End Date">
                <flat-pickr
                  v-model="program.licence_end_date"
                  :state="errors.length > 0 ? false : null"
                  class="form-control"
                  placeholder="Select Date"
                  :config="datePickerConfig"
                />
              </b-form-group>
              <span class="text-danger">{{ errors[0] }}</span>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col 
            md="6"
          >
            <div class="d-flex">
              <validation-provider
                v-slot="validationContext"
                ref="matchDuration"
                rules="required|min_value:1|max_value:365"
                name="Duration"
              >
                <b-form-group
                  label-for="match-duration"
                  label="Match Duration"
                >
                  <b-form-input
                    id="match-duration"
                    v-model="program.match_duration_value"
                    type="number"
                    :state="getValidationState(validationContext)"
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

              <validation-provider
                v-slot="validationContext"
                ref="duration-unit"
                rules="required"
                name="Period"
              >
                <b-form-group
                  label-for="duration-unit"
                  label="Type"
                  class="ml-1"
                >
                  <v-select
                    id="duration-unit"
                    v-model="program.match_duration_unit"
                    :options="durationUnitsDisplay"
                    :reduce="(option) => option.id"
                    label="name"
                    placeholder="Duration"
                    :state="getValidationState(validationContext)"
                    class="medium-select-list"
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </div>
          </b-col>
          <b-col
            v-if="!isProgramTypeMentoringOnDemand"
            md="6"
          >
            <validation-provider
              v-slot="{ errors }"
              ref="name"
              :rules="{ required: true, min_value: 1 }"
              name="Matches Allowed"
            >
              <b-form-group
                label-for="match_limit"
                label="Matches Allowed"
              >
                <b-form-input
                  id="match_limit"
                  v-model="program.match_limit"
                  type="number"
                  :state="errors.length > 0 ? false : null"
                  placeholder="10"
                />
              </b-form-group>
              <span class="text-danger">{{ errors[0] }}</span>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12">
            <validation-provider
              v-slot="validationContext"
              ref="Template"
              rules="required"
              name="Template"
            >
              <b-form-group 
                label="Select a Template" 
                label-for="Template"
              >
                <v-select
                  v-model="template_id"
                  :dir="dir"
                  placeholder="Please select a Template"
                  :options="templatesList"
                  :reduce="(option) => option.id"
                  label="text"
                >
                  <template v-slot:option="option">
                    {{ option.text }}
                  </template>
                </v-select>
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12">
            <validation-provider
              v-slot="validationContext"
              ref="program_admin_id"
              :rules="{ required: true }"
              vid="program_admin_id"
              name="Program Admin"
            >
              <b-form-group
                label="Select Program admin" 
              >
                <v-select
                  v-model="program_admin_id"
                  :dir="dir"
                  placeholder="Select Program admin"
                  :options="programAdmins"
                  :reduce="(option) => option.value"
                  label="text"
                />
                <b-form-invalid-feedback>
                  {{
                    validationContext.errors[0]
                  }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12">
            <validation-provider
              v-slot="validationContext"
              ref="timezone"
              rules="required"
              name="Timezone"
            >
              <b-form-group
                label="Timezone"
                label-for="timezone"
              >
                <v-select
                  id="timezone"
                  v-model="program.time_zone"
                  label="text"
                  placeholder="Select the Timezone"
                  :options="timezoneObjects"
                  :reduce="(option) => option.value"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12">
            <validation-provider
              v-slot="validationContext"
              ref="selectedLanguages"
              rules="required|program_languages"
              name="Default languages"
            >
              <b-form-group
                label="Language"
                label-for="default-language"
              >
                <v-select
                  id="selected-language"
                  v-model="program.languages"
                  item-text="name"
                  item-value="id"
                  label="name"
                  :options="languageList"
                  placeholder="Select the languages"
                  :state="getValidationState(validationContext)"
                  multiple
                />
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12">
            <validation-provider
              v-slot="{ errors }"
              ref="max_mentee_per_mentor"
              :rules="{ required: true }"
              vid="max_mentee_per_mentor"
              name="Maximum Mentees per Mentor"
            >
              <b-form-group
                label-for="max_mentee_per_mentor"
                label="Maximum Mentees per Mentor ?"
              >
                <v-select
                  id="max-mentee-per-mentor"
                  v-model="maxMenteePerMentor"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Maximum Mentees per Mentor ?"
                  :options="maxMenteePerMentorOptions"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </b-form-group>  
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="6">
            <b-form-group
              label="Enable participants to be both Mentor and Mentee"
              label-for="multiple_roles_enabled"
            >
              <b-form-checkbox
                id="allow-both-mentor-mentee"
                v-model="program.multiple_roles_enabled"
                checked="true"
                name="multiple_roles_enabled"
                switch
                inline
              />
              <feather-icon
                v-b-tooltip.hover.right="'If `On` then users are allowed to participate in the program as both mentor and mentee.'"
                size="20"
                class="align-top"
                icon="InfoIcon"
              />
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group
              label="Participant Visibility"
              label-for="participant_visibility"
            >
              <b-form-checkbox
                id="participant-visibility"
                v-model="participantVisibility"
                checked="true"
                switch
                inline
              />
              <feather-icon
                v-b-tooltip.hover.right="'If `On` then participants are able to view the profile of all participants in the program rather than their own published matches.'"
                size="20"
                class="align-top"
                icon="InfoIcon"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12">
            <b-form-group
              label="Index URL"
              label-for="indexUrl"
            >
              <b-form-checkbox
                id="indexedSwitch"
                v-model="program.indexed"
                checked="false"
                name="index-url"
                switch
                inline
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="outline-secondary"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          type="submit"
          variant="primary"
          @click="ok()"
        >
          <b-spinner
            v-if="isUpdating" 
            small
          />
          <span
            v-if="isUpdating"
          > Updating...</span>
          <span v-else>Submit</span>
        </b-button>
      </template>
    </b-modal>
  </validation-observer>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BForm,
  BModal,
  BFormCheckbox,
  BFormInvalidFeedback,
  VBModal,
  BButton,
  BSpinner,
  VBTooltip
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
//eslint-disable-next-line
import { required, max } from "@validations";
import {
  toFriendlyUrlPart,
  getValidationState,
  makeErrorToast,
  makeSuccessToast,
  PRIMARY_DATE_FORMAT
} from "@/libs/utils";
import clientsService from "@/services/clientsService";
import programsService from "@/services/programsService";
import localesService from "@/services/localesService";
import { ProgramClass } from '@models/classes/programClass';
import { AOM_MAIN_DOMAIN } from '@/constants/app';
import {
  programTypeDisplay,
  timezoneObjects,
  programPlansDisplay,
  matchDurationValuesDisplay,
  programTypes,
  durationUnitsDisplay
} from '@/models';
import { usersService } from "@/services";

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BForm,
    BModal,
    vSelect,
    BFormCheckbox,
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,
    BButton,
    BSpinner,
    flatPickr
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      name: null,
      dir: "ltr",
      clientsList: [],
      showModal: false,
      program: new ProgramClass(),
      isUpdating: false,
      programAdmins: [],
      maxMenteePerMentorOptions: Array.from({length: 10}, (_, i) => i + 1),
      maxMenteePerMentor: 0,
      participantVisibility: false,
      datePickerConfig: {
        dateFormat: 'Y-m-d',
        altInput: true,
        altFormat: PRIMARY_DATE_FORMAT,
        start: {
          maxDate: undefined,
        },
        end: {
          minDate: undefined,
        }
      },
      program_admin_id: null,
      template_id: null,
      languageList: [],
      templatesList: []
    };
  },
  computed: {
    isProgramTypeMentoringOnDemand() {
      if (this.selectedTemplate) {
        return this.selectedTemplate.type_id === programTypes.MENTORING_ON_DEMAND;
      }
      return false;
      
    },
    selectedTemplate () {
      return this.templatesList.find(template => template.id === this.template_id);
    }
  },  
  created() {
    this.loadClients();
    this.loadTemplates();
    this.loadProgramAdmins();
    this.getProgramLanguages();
  },
  methods: {
    onStartDateChange(selectedDates, dateStr) {
      this.datePickerConfig.end.minDate = dateStr;
    },
    onNameChange() {
      if (this.program?.name) {
        this.program.path = toFriendlyUrlPart(this.program.name);
      }
    },
    async loadClients() {
      try {
        const response = await clientsService.getList();
        this.clientsList = response.data.items.map(i => {
          return {value:i.id, text:i.name, domain: `${i.path}.${AOM_MAIN_DOMAIN}`};
        });
      } catch (e) {
        this.$toast(makeErrorToast("Organisations List not loaded."));
        this.$log.error(e);
      } finally {
        this.isLoading = false;
      }
    },

    async loadProgramAdmins() {
      try {
        const response = await usersService.listProgramAdmins();
        this.programAdmins = response.data.items.map(i => {
          return {value:i.id, text:i.full_name};
        });
      } catch (e) {
        this.$toast(makeErrorToast("Program Admin list not loaded."));
        this.$log.error(e);
      }
    },
    async loadTemplates() {
      try {
        const response = await programsService.getListTemplates({}, {
          has_application_set: true
        });
        this.templatesList = response.data.items.map(template => {
          return { id: template.id, text: template.name, type_id: template.type_id };
        });
      } catch (e) {
        this.$toast(makeErrorToast("Organisations List not loaded."));
        this.$log.error(e);
      } finally {
        this.isLoading = false;
      }
    },
    async getProgramLanguages() {
      try {
        const response = await localesService.getLocalesList();
        if (response && response.data) {
          this.languageList = response.data.map(lang => ({id: lang.id, name: lang.name}));
        }
      } catch (e) {
        const { status } = e.response;
        if (status === 404) {
          this.$toast(makeErrorToast('No Languages found.'));
        } else {
          this.$toast(makeErrorToast('Something went wrong! Default Languages list not loaded.'));
        }
      }
    },
    show() {
      this.showModal = true;
    },
    onHidden() {
      this.clearForm();
    },
    clearForm() {
      this.program = new ProgramClass();
      this.$nextTick(() => {
        this.$refs.observer.reset();
      });
    },
    async onSubmit() {
      try {
        this.isUpdating = true;
        const clientId = this.program.client_id;
        const data = {
          from_program_id: this.template_id,
          name: this.program.name,
          path: this.program.path,
          type_id: this.selectedTemplate?.type_id,
          languages: this.program.languages,
          program_plan_id: this.program.program_plan_id,
          indexed: this.program.indexed,
          licence_start_date: this.program.licence_start_date,
          licence_end_date: this.program.licence_end_date,
          match_duration_unit: this.program.match_duration_unit,
          match_duration_value: this.program.match_duration_value,
          match_limit: this.program.match_limit,
          program_admin_id: this.program_admin_id,
          time_zone: this.program.time_zone,
          max_mentee_per_mentor: this.maxMenteePerMentor,
          multiple_roles_enabled: this.program.multiple_roles_enabled,
          participant_visibility: this.participantVisibility,
          with_applications: true,
          with_surveys: true,
          with_resources: true,
          with_communications: true,
          with_trainings: true
        };
        await clientsService.duplicateClientProgram(clientId, data);
        this.showModal = false;
        this.$emit("programAdded");
        this.$toast(makeSuccessToast("Program saved."));
        await this.$store.dispatch('programs/GET_PROGRAMS');  
      } catch (e) {
        const { status, data } = e.response;
        //server may respond with vaidation errors
        if (status === 422 && data.errors) {
          this.$refs.observer.setErrors(data.errors);
        } else {
          this.$toast(makeErrorToast("Something went wrong. Program not saved."));
          this.$log.error(e);
        }
      } finally {
        this.isUpdating = false;
      }
    },
  },
  setup() {
    return {
      getValidationState,
      programTypeDisplay,
      programPlansDisplay,
      durationUnitsDisplay,
      matchDurationValuesDisplay,
      timezoneObjects
    };
  },
};
</script>
<template>
  <b-card class="program-update-tab-card">
    <b-card-title>
      <span v-b-tooltip.hover.top="'Connect to Teams or Skype chat'">
        Integrations
      </span>
    </b-card-title>
    <b-card-sub-title>
      Integrations and single sign on settings
    </b-card-sub-title>
    <b-form class="mt-2">
      <b-row>
        <b-col sm="2">
          <b-form-group
            label="MS Teams"
            label-for="msteam-integration"
          >
            <b-form-checkbox
              id="msteamIntegrationSwitch"
              v-model="integrationsProgram[integrations.TEAMS]"
              name="msteam-integration"
              switch
              inline
              :disabled="disabled"
              @change="onSubmit(integrations.TEAMS)"
            />
          </b-form-group>
        </b-col>

        <b-col sm="2">
          <b-form-group
            label="Skype"
            label-for="skype-integration"
          >
            <b-form-checkbox
              id="skypeIntegrationtSwitch"
              v-model="integrationsProgram[integrations.SKYPE]"
              name="skype-integration"
              switch
              inline
              :disabled="disabled"
              @change="onSubmit(integrations.SKYPE)"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row
        v-if="isChampionPortal"
        class="mt-2"
      >
        <b-col
          sm="12"
          class="text-left"
        >
          <b-button
            variant="light"
            @click="onBack"
          >
            <feather-icon
              icon="ArrowLeftIcon"
              class="mr-50"
            />
            Previous
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BRow,
  BCol,
  BCard,
  BCardTitle,
  BCardSubTitle,
  BFormCheckbox,
  VBTooltip
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { integrations, integrationsDisplay } from '@/models';
import programsService from "@/services/programsService";
import { makeSuccessToast, makeErrorToast } from "@/libs/utils";
import { mapGetters } from 'vuex';

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BCardSubTitle,
    BFormCheckbox
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  props: {
    disabled: {
      type: Boolean,
    },
    program: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      localProgram: this.program,
      integrationsProgram: {
        [integrations.TEAMS]: false,
        [integrations.SKYPE]: false,
        // [integrations.ZOOM]: false,
      },
      integrations,
      isUpdating: false
    };
  },
  computed: {
    ...mapGetters('app', ['isAdminPortal', 'isChampionPortal']),
  },

  created() {
    this.localProgram.integrations.forEach(i => {
      switch(i.id) {
      case integrations.TEAMS:
        this.integrationsProgram[integrations.TEAMS] = i.is_enabled;
        break;
      case integrations.SKYPE:
        this.integrationsProgram[integrations.SKYPE] = i.is_enabled;
        break;
      // case integrations.ZOOM:
      //   this.integrationsProgram[integrations.ZOOM] = i.is_enabled;
      //   break;
      }
    });
  },
  methods: {
    onBack() {
      this.$emit('back', '#theme');
    },
    clearForm() {
      this.localProgram = this.program;
    },
    onSubmit(integrationId) {
      const { id } = this.localProgram;
      let payload = this.integrationsProgram;
      payload = {integrations: Object.keys(payload).reduce((filtered, i) => {
        if (payload[i]) {
          let value = {id: Number(i)};
          filtered.push(value);
        }
        return filtered;
      }, [])};

      if (this.integrationsProgram[integrationId]) {
        this.actionUpdateIntegrations(id, payload);
      } else {
        this.$bvModal
          .msgBoxConfirm(`Are you sure you want to turn ${integrationsDisplay[integrationId]} integration off?`, {
            title: 'Save Changes',
            size: 'sm',
            okVariant: 'primary',
            okTitle: 'Yes',
            cancelTitle: 'Cancel',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          })
          .then(async value => {
            if(value) {
              this.actionUpdateIntegrations(id, payload);
            }
          });
      }
    },

    async actionUpdateIntegrations(id ,payload) {
      try {
        await programsService.updateProgramIntegrations(id, payload);
        this.$toast(makeSuccessToast('Program Details updated successfully.'));
        this.clearForm();
      } catch (e) {
        this.$toast(makeErrorToast('Something went wrong! Program Details not saved.'));
      } finally {
        this.isUpdating = false;
      }
    }
  },
};
</script>

/* eslint-disable camelcase */
export class ScheduledActionsClass {
    constructor ({
        id,
        action_id,
        actionable_event_id, 
        relative_value,
        relative_unit_id, 
        absolute_date, 
        created_at,
        updated_at
    } = {}) {
        this.id = id;
        this.action_id = action_id;
        this.actionable_event_id = actionable_event_id;
        this.relative_value = relative_value;
        this.relative_unit_id = relative_unit_id;
        this.absolute_date = absolute_date;
        this.created_at = created_at;
        this.updated_at = updated_at;
    }

}


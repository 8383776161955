<template>
  <section>
    <!-- Table Top -->
    <b-sidebar
      id="application-sidebar"
      v-model="openSideBar"
      :title="sideBarTitle"
      bg-variant="white"
      right
      shadow
      backdrop
      aria-controls="add-application-sidebar"
      :aria-expanded="openSideBar"
      width="40%"
    >
      <validation-observer
        ref="observer"
        v-slot="{ pristine, invalid, handleSubmit, errors }"
      >
        <b-form @submit.prevent="handleSubmit(onSubmit)">
          <aom-languages
            :dirty-tabs="isTabDataDirty(errors)"
          >        
            <template slot-scope="props">
              <application-title-desc
                :title="applicationTitle"
                :index="props.index"
                :locale-id="props.localeId"
                :description="applicationDescription"
                @updateTitle="updateTitle"
                @updateDescription="updateDescription"
              /> 
            </template>
          </aom-languages>
          <b-card>
            <b-row class="mt-5">
              <b-col
                sm="6"
                md="4"
                class="mb-1"
              >
                <b-button
                  block
                  type="reset"
                  variant="outline-dark"
                  @click="clearForm"
                >
                  Cancel
                </b-button>
              </b-col>
              <b-col
                sm="6"
                md="4"
                class="mb-1"
              >
                <b-button
                  block
                  type="submit"
                  variant="primary"
                >
                  <b-spinner
                    v-if="isUpdating" 
                    small
                  />
                  <span
                    v-if="isUpdating"
                  > Updating...</span>
                  <span v-else>Submit</span>
                </b-button>
              </b-col>
            </b-row>
          </b-card>
        </b-form>
      </validation-observer>
    </b-sidebar>
  </section>
</template>
  
<script>
import {
  BRow,
  BCol,
  BCard,
  BSidebar,
  BButton,
  BSpinner,
  BForm
} from "bootstrap-vue";
  
import AomLanguages from "@aom-core/AomLanguages";
import { ValidationObserver } from "vee-validate";
import { makeSuccessToast, makeErrorToast } from "@/libs/utils";
import ApplicationTitleDesc from './ApplicationTitleDesc.vue';
import { ApplicationSetClass, ApplicationSetTranslationClass } from '@models/applicationSetClass';
import { FormClass } from '@models/formsClass';
import { userRoles } from '@models/userRoles';
import formsService from '@/services/formsService';

export default {
  name: 'AddApplicationSideBar',
  components: {
    BCard,
    BRow,
    BCol,
    BSidebar,
    AomLanguages,
    BButton,
    BSpinner,
    BForm,
    ValidationObserver,
    ApplicationTitleDesc
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    open: {
      type: Boolean,
      default: false
    }
  },
  data(vm) {
    return {
      sideBarTitle: vm.title,
      isUpdating: false,
      openSideBar: vm.open,
      applicationTitle : '',
      applicationDescription: '',
      application: new ApplicationSetClass({translations: [], applications: [
        new FormClass({roles:[{id: userRoles.MENTOR}],translations: [], scheduled_actions:[]}), 
        new FormClass({roles: [{id: userRoles.MENTEE}],translations: [], scheduled_actions:[]})
      ]})
    };
  },
  watch: {
    open() {
      this.openSideBar = this.open;
    },
    title() {
      this.sideBarTitle = this.title;
    },
    openSideBar(n) {
      this.$emit('toggleSideBar', n);
    }
  },
  methods: {
    updateTitle (payload) {
      const {title, localeId} = payload;
      this.updateApplicationTranslation({title, locale_id: localeId});
    },
    updateDescription (payload) {
      const {description, localeId} = payload;
      this.updateApplicationTranslation({description, locale_id: localeId});
    },
    closeSideBar () {
      this.openSideBar = false;
      this.$emit('close');
    },
    async onSubmit () {
      try {
        this.isUpdating = true;
        await formsService.createApplicationSet(this.application);
        this.$toast(makeSuccessToast('Application created successfully.'));
        this.clearForm();
      } catch (e) {
        const { status, data } = e.response;
        //server may respond with vaidation errors
        if (status === 422 && data.errors) {
          this.$refs.observer.setErrors(data.errors);
        } 
        this.$log.error(e);
        this.$toast(makeErrorToast(data.message));
      } finally {
        this.isUpdating = false;
      }
    },
    isTabDataDirty (errors) {
      let tabErrorIndex = [];
      for (const [key, error] of Object.entries(errors)) {
        const regex = /\d+/g;
        if(error.length > 0) {
          const tabIndex = key.match(regex);
          if(Array.isArray(tabIndex)) {
            tabErrorIndex.push(tabIndex[0]);
          }
        }
      }
      return tabErrorIndex;
    },
    clearForm() {
      this.applicationTitle = '';
      this.applicationDescription =  '';
      this.application = new ApplicationSetClass({translations: [], applications: [
        new FormClass({roles:[{id: userRoles.MENTOR}]}), 
        new FormClass({roles: [{id: userRoles.MENTEE}]})
      ]});
      this.closeSideBar();
    },
    updateApplicationTranslation(trans) {
      const findIndex = this.application.translations.find(t => t.locale_id === trans.locale_id);
      if(findIndex) {
        const newArr = this.application.translations.map(obj => {
          if (obj.locale_id === trans.locale_id) {
            return {...obj, ...trans};
          }
          return obj;
        });
              
        this.application = {
          ...this.application,
          translations: newArr
        };
           
      } else {
        this.application = {
          ...this.application,
          translations: [
            ...this.application.translations,
            new ApplicationSetTranslationClass(trans)
          ]
        };
      }
    }
  }
};
</script>
  
  <style lang="scss" scoped>
    $sidebar-header-text-color: #808080;
    .b-sidebar > .b-sidebar-header{
      flex-direction: row-reverse;
      background: #f3f3f3;
      color: $sidebar-header-text-color;
  
      #application-sidebar___title__ {
        font-size: .8em;
        flex: 2;
      }
    }
    .card {
      box-shadow: none;
    }
  </style>
<template>
  <vue-good-table
    class="position-static"
    mode="remote"
    :is-loading="isLoading"
    :columns="columns"
    :rows="rows"
    :rtl="directionIsRTL"
    :search-options="{
      enabled: true,
      externalQuery: searchTerm,
    }"
    :pagination-options="{
      enabled: true,
      perPage: perPage,
    }"
    style-class="vgt-table striped"
    @on-page-change="onPageChange"
    @on-sort-change="onSortChange"
    @on-column-filter="onColumnFilter"
    @on-per-page-change="onPerPageChange"
  >
    <template
      slot="table-row"
      slot-scope="props"
    >
      <span v-if="props.column.field === 'title'">
        <span v-if="props.row.translations[0]">{{ props.row.translations[0].title }}</span> 
      </span>
      <span v-if="props.column.field === 'description'">
        <span v-if="props.row.translations[0]">{{ props.row.translations[0].description }}</span> 
      </span>
      <span v-else>
        {{ props.formattedRow[props.column.field] }}
      </span>
      <!-- Column: Help Link -->
      <!-- Column: Actions -->
      <div
        v-if="props.column.field === 'actions'"
        cols="12"
        md="2"
        class="vgt-center-align d-flex"
      >
        <b-button
          variant="outline-none"
          class="nav pr-0"
        >
          <b-nav-item-dropdown
            class="btn-icon"
          >
            <template 
              #button-content
            >
              <feather-icon
                icon="MoreVerticalIcon"
                size="20"
              />                
            </template>
            <b-dropdown-item
              link-class="d-flex align-items-center"
              @click="editApplication(props.row.id)"
            >
              <feather-icon
                size="16"
                icon="EditIcon"
                class="mr-50"
              />
              <span>Edit</span>
            </b-dropdown-item>
            <b-dropdown-item
              link-class="d-flex align-items-center"
              @click="viewApplication(props.row.id)"
            >
              <feather-icon
                size="16"
                icon="EyeIcon"
                class="mr-50"
              />
              <span>View</span>
            </b-dropdown-item>
          </b-nav-item-dropdown>
        </b-button>
        <b-button
          variant="outline-none"
          class="pr-0 pl-0"
          @click="deleteApplication(props.row.id)"
        >
          <feather-icon
            v-if="!props.row.hasBeenUsed"
            icon="TrashIcon"
            size="20"
          />
        </b-button>
      </div>
    </template>
  
    <!-- pagination -->
    <template
      slot="pagination-bottom"
      slot-scope="props"
    >
      <table-pagination
        :per-page="perPage"
        :total="total"
        @perPageChanged="
          (value) => props.perPageChanged({ currentPerPage: value })
        "
        @pageChanged="(value) => props.pageChanged({ currentPage: value })"
      />
    </template>
  </vue-good-table>
</template>
  
<script>
import {
  BButton,
  VBToggle,
  BNavItemDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import store from "@/store/index";
import {
  GOODTABLE_ISO_DATETIME_INPUT_FORMAT,
  GOODTABLE_LOCALISED_DATE_FORMAT,
  GOODTABLE_SEARCH_FIELD_DATE_PICKER_FORMAT,
  PRIMARY_DATE_FORMAT,
  localDateStringOrDateRangeStringToUtcRange
} from "@/libs/utils";
import TablePagination from "@/views/components/pagination/TablePagination.vue";
import flatPickr from "flatpickr";
import "flatpickr/dist/flatpickr.css";
import "flatpickr/dist/themes/material_blue.css";
import formsService from '@/services/formsService';
import { makeSuccessToast, makeErrorToast } from "@/libs/utils";
import router from '@/router';
import useAomTableConfig from "@aom-core/useAomTableConfig.js";

export default {
  name: 'ApplicationListAdmin',
  components: {
    VueGoodTable,
    BButton,
    TablePagination,
    BNavItemDropdown,
    BDropdownItem
  },
  directives: {
    "b-toggle": VBToggle,
  },
  data() {
    return {
      isLoading: false,
      element: {},
      dir: false,
      columns: [
        {
          label: "Title",
          field: "title",
          filterOptions: {
            enabled: true,
            placeholder: "Search Title",
          },
          width: "10em",
        },
        {
          label: "Description",
          field: "description",
          filterOptions: {
            enabled: true,
            placeholder: "Search Decsription",
          },
          width: "15em",
        },
        {
          label: "No. Questions",
          field: "all_questions_count",
          filterOptions: {
            enabled: true,
            placeholder: "Search Number of Questions",
          },
          width: "10em",
        },
        {
          label: "Usage",
          field: "copies_count",
          filterOptions: {
            enabled: true,
            placeholder: "Search Usage of Questions",
          },
          width: "7em",
        },
        {
          label: "Last Modified",
          field: "updated_at",
          filterOptions: { 
            enabled: true,
            placeholder: "Filter Date",
            filterFn: this.dateFilter,
          },
          width: "9em",
          type: "date",
          dateInputFormat: GOODTABLE_ISO_DATETIME_INPUT_FORMAT,
          dateOutputFormat: GOODTABLE_LOCALISED_DATE_FORMAT,
        },
        {
          field: "actions",
          width: "5em",
          sortable: false
        },
      ],
      columnFilters: [],
      sort: [],
      rows: [],
      searchTerm: ""
    };
  },
  computed: {
    directionIsRTL() {
      return store.state.appConfig.isRTL;
    },
  },
  mounted() {
    const elements = document.getElementsByName("vgt-updated_at");
    elements.forEach(function (input) {
      flatPickr(input, {
        enableTime: false,
        dateFormat: GOODTABLE_SEARCH_FIELD_DATE_PICKER_FORMAT, 
        allowInput: true,
        altInput: true,
        altFormat: PRIMARY_DATE_FORMAT,
      });
    });
  },
  created() {
    this.loadItems();
  },
  methods: {
    dateFilter(data, filterString) {
      return (data = Date.parse(data) >= Date.parse(filterString));
    },
  
    onPageChange(params) {
      this.page = params.currentPage;
      this.loadItems();
    },
  
    onPerPageChange(params) {
      this.perPage = params.currentPerPage;
      this.loadItems();
    },
  
    onSortChange(params) {
      let field = params[0].field;
      let type = params[0].type;
      if (type !== 'none') {
        this.sort = [{ field: field, type: type }];
      } else {
        this.sort = [];
      }
      this.loadItems();
    },
  
    onColumnFilter(params) {
      const columnFilters = [];
      for (let col of ["title", "description", "updated_at", "copies_count", "all_questions_count"]) {
        if (params.columnFilters[col]) {
          if (col === "updated_at") {
            const dateRange = localDateStringOrDateRangeStringToUtcRange(params.columnFilters[col]);
            if (dateRange) {
              columnFilters.push({
                field: col,
                value: dateRange,
              });
            }
          } else {
            columnFilters.push({
              field: col,
              value: params.columnFilters[col],
            });
          }
        }
      }
      this.columnFilters = columnFilters;
      this.loadItems();
    },
    async loadItems() {
      try {
        this.isLoading = true;
        const response = await formsService.getApplicationList({
          page: this.page,
          perPage: this.perPage,
          columnFilters: this.columnFilters,
          sort: this.sort,
        });
        this.total = response.data.total;
        this.rows = response.data.items;
      } catch (e) {
        const { status, data } = e.response;
        //server may respond with vaidation errors
        if (status === 422 && data.errors) {
          this.$refs.observer.setErrors(data.errors);
        } else {
          this.$log.error(e);
        }
        return;
      } finally {
        this.isLoading = false;
      }
    },
    editApplication(id) {
      this.$nextTick(()=> {
        this.$emit('edit', id);
      });
    },
    viewApplication(id) {
      router.push({ name: 'admin-application', params: { application: id } });
    },
    async deleteApplication(id) {
      // @TODO delete from Form as well
      this.$bvModal
        .msgBoxConfirm('Are you sure you want to delete this Application ?', {
          title: 'Delete Application',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Delete',
          cancelTitle: 'Cancel',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(async value => {
          if(value) {
            try {
              this.isLoading = true;
              await formsService.deleteApplicationSet(id);
              this.loadItems();
              this.$toast(makeSuccessToast('Application deleted successfully.'));
            } catch (e) {
              this.$toast(makeErrorToast('Something went wrong. Try again'));
              this.$log.error(e);
            } finally {
              this.isLoading = false;
            }
          }
        });
    }
  },
  setup() {
    const { total, perPage, page } = useAomTableConfig();

    return {
      total, 
      perPage, 
      page
    };
  },
};
</script>
  
  <style lang="scss" scoped>
  .float-left{
    float: left;
  }
  
  .badge {
    font-weight: 400 !important;
  }
  table.vgt-table td {
    vertical-align: middle;
  }
  </style>
  
  <style lang="scss">
  @import "/src/assets/scss/vue-good-table.scss";
  .nav .dropdown-toggle:not(.active)::after {
  background-image: none !important;
}
  </style>
  
<template>
  <validation-observer
    ref="observer"
    v-slot="{ handleSubmit }"
  >
    <b-modal
      id="modal-add-champion-to-program"
      v-model="showModal"
      title="Add Champion"
      centered
      ok-title="Submit"
      cancel-variant="outline-secondary"
      :ok-disabled="isLoading"
      @hidden="onHidden"
      @ok.prevent="handleSubmit(onSubmit)"
    >
      <b-form>
        <b-row>
          <b-col md="6">
            <validation-provider
              v-slot="validationContext"
              ref="first-name"
              :rules="{ required: true }"
              vid="first-name"
              name="First name"
            >
              <b-form-group
                label="First name"
                label-for="first-name"
              >
                <b-form-input
                  v-model="championData.first_name"
                  placeholder="First name"
                  name="first-name"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="6">
            <validation-provider
              v-slot="validationContext"
              ref="last-name"
              :rules="{ required: true }"
              vid="last-name"
              name="Last name"
            >
              <b-form-group
                label="Last name"
                label-for="last-name"
              >
                <b-form-input
                  v-model="championData.last_name"
                  placeholder="Last name"
                  name="last-name"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>

        <b-row>
          <b-col md="12">
            <validation-provider
              v-slot="validationContext"
              ref="email"
              :rules="{ required: true, email: true }"
              vid="email"
              name="Email"
            >
              <b-form-group
                label="Email"
                label-for="email"
              >
                <b-form-input
                  v-model="championData.email"
                  placeholder="Email"
                  name="email"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>

        <b-row>
          <b-col md="12">
            <validation-provider
              v-slot="validationContext"
              ref="phone"
              vid="phone"
              rules="required|aom_phone"
              name="Phone"
            >
              <b-form-group
                label="Phone"
                label-for="phone"
              >
                <aom-tel-input
                  id="phone"
                  v-model="championData.phone"
                />
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
  </validation-observer>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BModal,
  BFormInvalidFeedback,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
//eslint-disable-next-line
import { required, email } from "@validations";
import championsService from "@/services/championsService";
import { getValidationState } from "@/libs/utils";
import { makeErrorToast } from "@/libs/utils";
import { userCreationErrorCodes } from '@models';

export default {
  name: 'AddChampion',
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BForm,
    BModal,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    clientId: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      championData: {
        first_name: null,
        last_name: null,
        email: null,
        phone: null,
        force: false
      },
      isLoading: false,
    };
  },
  methods: {
    async onSubmit(force = false) {
      try {
        this.isLoading = true;
        const payload = {
          first_name: this.championData.first_name,
          last_name: this.championData.last_name,
          email: this.championData.email,
          phone: this.championData.phone,
        };

        if (force) {
          payload.force = true;
        }

        const response = await championsService.createChampionForClient(
          this.clientId || this.$route.params.clientId,
          payload
        );
        this.$emit("championAdded", response.data);

        this.showModal = false;
        this.$emit("hiddenModal", false);
      } catch (e) {
        const { status, data } = e.response;
        if (status === 409 && data.code === userCreationErrorCodes.EXISTING_USER_ALLOWED) {
          this.$bvModal
            .msgBoxConfirm('There is already an account using this email. If any fields belonging to that account are different, they will be overwritten to match this account. Would you like to continue?', {
              title: 'Multiple Role Alert',
              size: 'sm',
              okVariant: 'primary',
              okTitle: 'Continue',
              cancelTitle: 'Cancel',
              cancelVariant: 'outline-secondary',
              hideHeaderClose: false,
              centered: true,
            })
            .then(async value => {
              if (value) {
                return this.onSubmit(true);
              }
            });
        } else if (status === 409 && data.code === userCreationErrorCodes.EXISTING_USER_NOT_ALLOWED) {
          this.$toast(makeErrorToast(data.message));
          this.showModal = false;
          this.$emit("hiddenModal", false);
        } else {
          console.log(e);
          this.$toast(makeErrorToast(e));
        }
      } finally {
        this.isLoading = false;
      }
    },
    show() {
      this.showModal = true;
    },
    onHidden() {
      this.$emit("hiddenModal", false);
      this.clearForm();
    },
    clearForm() {
      this.championData = {
        first_name: null,
        last_name: null,
        email: null,
        phone: null,
        force: false
      };
      this.$nextTick(() => {
        this.$refs.observer.reset();
      });
    },
  },
  setup() {
    return {
      getValidationState,
    };
  },
};
</script>

<template>
  <!-- table -->
  <vue-good-table
    class="mt-3"
    mode="remote"
    :is-loading="isLoading"
    :columns="columns"
    :rows="rows"
    :rtl="directionIsRTL"
    :search-options="{
      enabled: true,
      externalQuery: searchTerm,
    }"
    :pagination-options="{
      enabled: true,
      perPage: perPage,
    }"
    style-class="vgt-table striped"
    @on-page-change="onPageChange"
    @on-sort-change="onSortChange"
    @on-column-filter="onColumnFilter"
    @on-per-page-change="onPerPageChange"
  >
    <template
      slot="table-row"
      slot-scope="props"
    >
      <!-- Column: Actions -->
      <div
        v-if="props.column.label === 'Actions'"
        cols="12"
        md="2"
        class="d-flex vgt-center-align"
      >
        <b-button
          variant="outline-none"
          @click="editResource(props.row.id)"
        >
          <feather-icon
            icon="EditIcon"
            size="20"
          />
        </b-button>

        <b-button
          variant="outline-none"
          class="nav"
          style=""
        >
          <b-nav-item-dropdown class="btn-icon">
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="20"
              />
            </template>
            <b-dropdown-item
              v-if="props.row.type.id === resourceTypes.WEB_PAGE"
              link-class="d-flex align-items-center"
              @click="getToResourcePreview(props.row.id)"
            >
              <feather-icon
                size="16"
                icon="ClipboardIcon"
                class="mr-50"
              />
              <span>Preview</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="!isProgramTemplate"
              link-class="d-flex align-items-center"
              @click.prevent="copyResourceLink(props.row.id)"
            >
              <feather-icon
                size="16"
                icon="ClipboardIcon"
                class="mr-50"
              />
              <span>Copy Link</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="(props.row.type.id === resourceTypes.FILE || props.row.type.id === resourceTypes.WEB_PAGE) && props.row.is_public && !isProgramTemplate"
              link-class="d-flex align-items-center"
              @click.prevent="copyResourcePublicLink(props.row.id)"
            >
              <feather-icon
                size="16"
                icon="ClipboardIcon"
                class="mr-50"
              />
              <span>Copy Public Link</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="props.row.type.id === resourceTypes.FILE"
              link-class="d-flex align-items-center"
              @click.prevent="copyResourceLinkForBulkEmail(props.row.id)"
            >
              <feather-icon
                size="16"
                icon="ClipboardIcon"
                class="mr-50"
              />
              <span>Copy Link For Bulk Email</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="props.row.type.id === resourceTypes.URL && !isProgramTemplate"
              link-class="d-flex align-items-center"
              @click.prevent="openResourceLink(props.row.id)"
            >
              <feather-icon
                size="16"
                icon="SendIcon"
                class="mr-50"
              />
              <span>Go To Link</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="props.row.type.id === resourceTypes.FILE && !isProgramTemplate"
              link-class="d-flex align-items-center"
              @click="downloadResource(props.row.id)"
            >
              <feather-icon
                size="16"
                icon="DownloadIcon"
                class="mr-50"
              />
              <span>Download</span>
            </b-dropdown-item>

            <b-dropdown-item
              link-class="d-flex align-items-center"
              @click.prevent="deleteProgramResource(props.row.id)"
            >
              <feather-icon
                size="16"
                icon="TrashIcon"
                class="mr-50"
              />
              <span>Delete</span>
            </b-dropdown-item>
          </b-nav-item-dropdown>
        </b-button>
      </div>

      <!-- Column: Common -->
      <span
        v-else-if="props.column.field === 'role'"
        class="text-capitalize"
      >
        {{ props.formattedRow[props.column.field] }}
      </span>
      <span v-else>
        {{ props.formattedRow[props.column.field] }}
      </span>
    </template>
    <!-- pagination -->
    <template
      slot="pagination-bottom"
      slot-scope="props"
    >
      <table-pagination
        :per-page="perPage"
        :total="total"
        @perPageChanged="
          (value) => props.perPageChanged({ currentPerPage: value })
        "
        @pageChanged="(value) => props.pageChanged({ currentPage: value })"
      />
    </template>
  </vue-good-table>
</template>

<script>
import {
  BButton,
  BNavItemDropdown,
  BDropdownItem,
  VBToggle
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { VueGoodTable } from "vue-good-table";
import store from "@/store/index";
import TablePagination from "@/views/components/pagination/TablePagination.vue";
import resourcesService from "@/services/resourcesService";
import { makeSuccessToast, makeErrorToast } from "@/libs/utils";
import {
  resourceTypes,
  resourceTypesSelect,
  userRolesDisplayFilter,
} from "@/models";
import { useGetAvailability } from "@/views/apps/useGetAvailability";
import { AOM_MAIN_DOMAIN, AOM_PORTAL_SUB_DOMAIN } from '@/constants/app';
import { mapGetters } from 'vuex';
import { isPublicOptions } from "@/models/eKeyDates";

export default {
  components: {
    VueGoodTable,
    BButton,
    BNavItemDropdown,
    BDropdownItem,
    TablePagination,
  },
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
  props: {
    program: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isLoading: false,
      perPage: 10,
      page: 1,
      element: {},
      dir: false,

      columns: [
        {
          label: "Title",
          field: "title",
          filterOptions: {
            enabled: true,
            placeholder: "Search Title",
          },
        },
        {
          label: "Type",
          field: "typeName",
          filterable: true,
          filterOptions: {
            enabled: true,
            placeholder: "All",
            filterDropdownItems: resourceTypesSelect,
          },
          width: "8em",
        },
        {
          label: "Role",
          field: "role",
          sortable: false,
          filterOptions: {
            enabled: true,
            placeholder: "All",
            filterDropdownItems: userRolesDisplayFilter,
          },
          width: "8em",
        },
        {
          label: "Category",
          field: "categoryName",
          filterOptions: {
            enabled: true,
            placeholder: "Search Category",
          },
          width: "12em",
        },
        {
          label: "Availability",
          field: "availability",
          sortable: true,
          filterOptions: {
            enabled: false,
            filterValue: ''
          },
          width: "12em",
        },
        {
          label: "Visible to public",
          field: "public",
          sortable: true,
          filterOptions: {
            enabled: true,
            placeholder: "All",
            filterDropdownItems: isPublicOptions,
          },
          width: "12em",
        },
        {
          label: "Actions",
          field: "id",
          sortable: false,
          tdClass: "text-center",
          width: "26m",
        },
      ],
      columnFilters: [],
      sort: [],
      total: 0,
      rows: [],
      searchTerm: "",
      resourceTypes,
      client: undefined,
    };
  },
  computed: {
    ...mapGetters('programs',['programById']),
    directionIsRTL() {
      return store.state.appConfig.isRTL;
    },
    isProgramTemplate() {
      return this.program?.is_template === true;
    }
  },
  created() {
    this.client = this.program?.client;
    this.loadItems();
  },
  methods: {
    copyResourcePublicLink(id) {
      const resource = this.rows.find(item => item.id === id);
      if (resource && window.navigator) {
        const protocol = window.location.protocol;
        const link = `${protocol}//${AOM_PORTAL_SUB_DOMAIN}.${AOM_MAIN_DOMAIN}/${this.client.path}/${this.program.path}/resources/${resource.id}/public`;
        window.navigator.clipboard.writeText(link);
        this.$toast(makeSuccessToast("Copied to clipboard."));
      }
    },
    getToResourcePreview(id) {
      this.$router.push({
        name: 'champion-program-resource-view',
        params: { resource: id }
      });
    },
    dateFilter(date, filterString) {
      return (date = Date.parse(date) >= Date.parse(filterString));
    },

    onPageChange(params) {
      this.page = params.currentPage;
      this.loadItems();
    },

    onPerPageChange(params) {
      this.perPage = params.currentPerPage;
      this.loadItems();
    },

    onSortChange(params) {
      let field = params[0].field;
      let type = params[0].type;
      if (type === 'none') {
        this.sort = [];
      } else {
        if (params[0].field === "typeName") {
          field = "type_id";
        } else if (params[0].field === "categoryName") {
          field = "category.name";
        } else if (params[0].field === "title") {
          field = "translations.title";
        } else if (params[0].field === "availability") {
          field = "scheduled_action.calculated_date";
        } else if (params[0].field === "public") {
          field = "is_public";
        }
        this.sort = [{ field, type: type }];
      }
      
      this.loadItems();
    },

    onColumnFilter(params) {
      const columnFilters = [];
      for (let col of [
        "title",
        "typeName",
        "role",
        "categoryName",
        "availability",
        "public"
      ]) {
        if (params.columnFilters[col]) {
          if (params.columnFilters[col]) {
            if (col === "typeName") {
              columnFilters.push({
                field: "type_id",
                value: params.columnFilters[col],
              });
            } else if (col === "categoryName") {
              columnFilters.push({
                field: "category.name",
                value: params.columnFilters[col],
              });
            } else if (col === "role") {
              columnFilters.push({
                field: "roles.id",
                value: params.columnFilters[col],
              });
            } else if (col === "title") {
              columnFilters.push({
                field: "translations.title",
                value: params.columnFilters[col],
              });
            } else if (col === "public") {
              columnFilters.push({
                field: "is_public",
                value: params.columnFilters[col] === 'true' ? 1: 0,
              });
            } else {
              columnFilters.push({
                field: col,
                value: params.columnFilters[col],
              });
            }
          }
        }
      }
      this.columnFilters = columnFilters;
      this.loadItems();
    },

    async loadItems() {
      try {
        this.isLoading = true;
        const response = await resourcesService.getProgramResources(
          this.$route.params.id,
          {
            page: this.page,
            perPage: this.perPage,
            columnFilters: this.columnFilters,
            sort: this.sort,
          }
        );
        this.total = response.data.total;
        this.rows = response.data.items.map(item => {
          item.roles.map(role => role.id);
          let role = item.roles.map(role => role.name).join(", ");
          return {
            title: item.translations[0].title,
            typeName: item.type.name,
            role,
            categoryName: item.category.name,
            availability: useGetAvailability(item.scheduled_action),
            public: item.is_public? 'Yes': "No",
            ...item
          };
        });
      } catch (e) {
        this.$log.error(e);
        this.$toast(makeErrorToast("Resources list not loaded."));
      } finally {
        this.isLoading = false;
      }
    },

    editResource(id) {
      this.$nextTick(()=> {
        this.$emit('edit', id);
      });
      this.$root.$emit("bv::toggle::collapse", "edit-resource-right");
    },

    copyResourceLink(id) {
      const resource = this.rows.find(item => item.id === id);
      const protocol = window.location.protocol;
      if (resource && window.navigator) {
        let link = null;
        if (resource.type_id === resourceTypes.URL) {
          link = resource.translations[0].link;
        } else {
          link = `${protocol}//${AOM_PORTAL_SUB_DOMAIN}.${AOM_MAIN_DOMAIN}/${this.client.path}/${this.program.path}/resources/${resource.id}`;
        }
        window.navigator.clipboard.writeText(link);
        this.$toast(makeSuccessToast("Copied to clipboard."));
      }
    },
    copyResourceLinkForBulkEmail(id) {
      const resource = this.rows.find(item => item.id === id);
      if (resource && window.navigator) {
        const protocol = window.location.protocol;
        const link = `${protocol}//${AOM_PORTAL_SUB_DOMAIN}.{{BASE_SITE_DOMAIN}}/${this.client.path}/${this.program.path}/resources/${resource.id}/{{PARTICIPANT_TOKEN}}`;
        window.navigator.clipboard.writeText(link);
        this.$toast(makeSuccessToast("Copied to clipboard."));
      }
    },

    openResourceLink(id) {
      const resource = this.rows.find(item => item.id === id);
      if (resource) {
        const link = resource.translations[0].link;
        window.open(link);
      }
    },

    downloadResource(id) {
      const resource = this.rows.find(item => item.id === id);
      if (resource) {
        const dowloadLink = `${BE_API_URL}/library/resources/${resource.id}/translations/${resource.translations[0].id}/download`;
        window.document.location = dowloadLink;
      }
    },

    async deleteProgramResource(id) {
      this.$bvModal
        .msgBoxConfirm(
          "Are you sure you want to delete this resource? This action cannot be undone",
          {
            title: "Delete Resource",
            size: "sm",
            okVariant: "primary",
            okTitle: "Delete",
            cancelTitle: "Cancel",
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then(async value => {
          if (value) {
            try {
              const response = await resourcesService.deleteProgramResource(
                this.$route.params.id,
                id
              );

              if (response) {
                this.$toast(makeSuccessToast("Resource deleted successfully."));
                this.loadItems();
              }
            } catch (e) {
              const { data } = e.response;
              this.$log.error(e);
              this.$toast(makeErrorToast(data.message));
            }
          }
        });
    },
  },
};
</script>

<style lang="scss">
@import "/src/assets/scss/vue-good-table.scss";
</style>

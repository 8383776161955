<template>
  <b-card class="program-update-tab-card">
    <b-card-title>
      <span
        v-b-tooltip.hover.top="
          'Update theme on your application and landing page'
        "
      >
        Theme
      </span>
    </b-card-title>
    <b-card-sub-title>
      Update branding
    </b-card-sub-title>

    <validation-observer
      ref="observer"
      v-slot="{ handleSubmit }"
    >
      <b-form
        class="mt-2"
        @submit.prevent="handleSubmit(onSubmit)"
      >
        <!-- WCAG Compliance -->
        <b-row>
          <b-col md="6">
            <validation-provider
              v-slot="validationContext"
              ref="compliance"
              rules="required"
              name="Compliance"
            >
              <b-form-group
                label="WCAG Compliance"
                label-for="default-compliance"
              >
                <v-select
                  id="compliances"
                  v-model="selectedCompliance"
                  label="text"
                  placeholder="Select the Compliance"
                  :options="wcagComplianceDisplay"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>

        <!-- Logo -->
        <b-row class="mt-2 mb-2">
          <b-col md="12">
            <span>
              Logo (dimensions: 88px wide, 50px high)
            </span>
            <b-media
              no-body
              vertical-align="center"
              class="flex-column flex-md-row"
            >
              <b-media-aside>
                <b-img
                  ref="refPreviewImg"
                  :src="mainImagePreview"
                  width="115"
                  class="rounded mr-5 mb-1 mb-md-0"
                />
              </b-media-aside>
              <b-media-body>
                <div class="d-inline-block">
                  <action-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="primary"
                    size="sm"
                    @click="openMainImageUpload"
                  >
                    <!-- :disabled="hasMainLogo" -->
                    <b-spinner
                      v-if="isUpdatingMainImage"
                      small
                    />
                    <span
                      v-if="!isUpdatingMainImage"
                      class="text-nowrap"
                    > Upload</span>
                  </action-button>
                  <action-button
                    id="btn-reset-main-logo"
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    class="ml-1"
                    variant="outline-secondary"
                    size="sm"
                    :disabled="!hasMainLogo"
                    @click="deleteMainImage"
                  >
                    <b-spinner
                      v-if="isDeletingMainImage" 
                      small
                    />
                    <span
                      v-if="!isDeletingMainImage"
                      class="text-nowrap"
                    >Reset</span>
                  </action-button>
                  <validation-provider
                    v-slot="validationContext"
                    ref="main-logo"
                    :rules="{ size: MAX_LOGO_SIZE_KB }"
                    vid="main-logo"
                    name="Main Logo"
                  >
                    <b-form-file
                      ref="refInputImg"
                      v-model="urlMainLogo"
                      accept=".jpg, .png, .gif"
                      placeholder="Choose a file"
                      class="mt-2 imageReader hidden"
                      drop-placeholder="Drop file here..."
                      :state="
                        getValidationState(
                          validationContext
                        )
                      "
                    />
                    <b-form-invalid-feedback
                      :state="
                        getValidationState(
                          validationContext
                        )
                      "
                    >
                      {{
                        validationContext.errors[0]
                      }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </div>
              </b-media-body>
            </b-media>
          </b-col>
        </b-row>

        <!-- Sponsor Logos -->
        <b-row class="mt-2 mb-1">
          <b-col md="12">
            <span>
              Sponsor {{ sponsorLogos.length > 0 ? 'Logos' : 'Logo' }} (dimensions: 200px wide, 100px high)
            </span>
          </b-col>
        </b-row>
        <b-row class="mb-1">
          <b-col md="3">
            <action-button
              id="btn-add-sponsor-logo"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="primary"
              @click="addSponsorLogo"
            >
              <feather-icon
                icon="PlusIcon"
                size="16"
              />
              <span class="text-nowrap">Add Sponsor Logo</span>
            </action-button>
          </b-col>
        </b-row>
        <draggable
          v-model="sponsorLogos"
          @change="updateSortOrder"
        >
          <b-row
            v-for="(sponsor, index) in sponsorLogos"
            :key="sponsor.id || sponsor.tempId"
            class="mt-1"
            :title="index"
          >
            <b-col sm="12">
              <LogoComponent
                :index="index"
                :sponsor-logo="sponsor"
                :is-uploading="sponsor.isUpdating"
                :is-deleting="sponsor.isDeleting"
                @upload="createSponsorLogo"
                @delete="deleteSponsorLogo"
                @updateLink="updateLink"
                @updateLogo="updateSponsorLogo"
              />
            </b-col>
          </b-row>
        </draggable>

        <!-- Primary color -->
        <b-row class="theme-list-title mt-2">
          <b-col
            cols=""
            md="6"
          >
            <span>Primary color</span>
            <div class="d-flex align-items-left">
              <div class="control">
                <InputColorPicker
                  v-model="localProgram.theme_primary_color"
                  class="inputColorPicker"
                />
              </div>
              <span class="ml-1 mt-1">#HEX</span>

              <validation-provider
                v-slot="{ errors }"
                ref="primary-color"
                :rules="{ required: true }"
                name="Primary color"
              >
                <b-form-group
                  label-for="primary-color"
                  class="control ml-1"
                >
                  <input
                    v-model="localProgram.theme_primary_color"
                    class="form-control"
                    type="text"
                    placeholder="color"
                    name="primary-color"
                  >
                  <span class="text-danger">{{ errors[0] }}</span>
                </b-form-group>
              </validation-provider>
            </div>
          </b-col>

          <b-col
            cols=""
            md="6"
          >
            <span>Secondary color</span>
            <div class="d-flex align-items-left">
              <div class="control">
                <InputColorPicker
                  v-model="localProgram.theme_secondary_color"
                  class="inputColorPicker"
                />
              </div>
              <span class="ml-1 mt-1">#HEX</span>

              <validation-provider
                v-slot="{ errors }"
                :rules="{ required: true }"
                name="Secondary Color"
              >
                <b-form-group
                  label-for="secondary-color"
                  class="control ml-1"
                >
                  <input
                    v-model="localProgram.theme_secondary_color"
                    class="form-control"
                    type="text"
                    placeholder="color"
                    name="secondary-color"
                  >
                  <span class="text-danger">{{ errors[0] }}</span>
                </b-form-group>
              </validation-provider>
            </div>
          </b-col>
        </b-row>
        <!-- Secondary color -->
        <b-row class="theme-list-title">
          <b-col
            md="10"
          >
            <span>Email Signature</span>
            <aom-text-editor
              v-model="localProgram.email_signature"
              :height="300"
              :tool-bar="toolBar"
            />
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col
            sm="6"
            class="text-left"
          >
            <b-button
              v-if="isAdminPortal"
              variant="outline-secondary"
              @click="clearForm()"
            >
              Cancel
            </b-button>
            <b-button
              v-else
              variant="light"
              @click="onBack"
            >
              <feather-icon
                icon="ArrowLeftIcon"
                class="mr-50"
              />
              Previous
            </b-button>
          </b-col>
          <b-col
            sm="6"
            class="text-right"
          >
            <action-button
              type="submit"
              variant="primary"
            >
              <b-spinner
                v-if="isUpdating"
                small
              />
              <span
                v-if="isUpdating"
              >Updating...</span>

              <template v-else>
                <span class="mr-50">Update</span>
                <feather-icon icon="SaveIcon" />
              </template>
            </action-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>
<script>
import {
  BCard,
  BCardTitle,
  BCardSubTitle,
  BRow,
  BCol,
  BForm,
  BButton,
  BFormGroup,
  BFormInvalidFeedback,
  BFormFile,
  BSpinner,
  BMedia,
  BMediaAside,
  BMediaBody,
  BImg,
  VBTooltip
} from "bootstrap-vue";
import ActionButton from "@/views/components/common/ActionButton.vue";
import AomTextEditor from "@/@aom-core/AoMTextEditor.vue";
import InputColorPicker from "./InputColorPicker";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
//eslint-disable-next-line
import { required, size } from "@validations";
import { wcagComplianceDisplay} from "@/models/enums/wCagCompliance"; 
import { programLogoService, programsService } from "@/services";
import {
  getValidationState,
  makeSuccessToast,
  makeErrorToast,
  PRIMARY_COLOR,
  SECONDARY_COLOR,
  setRootElementCssVars,
  convertModelToFormData
} from "@/libs/utils";
import { mapGetters } from 'vuex';
import { AOM_TEXT_EDITOR_TOOLBAR_WITHOUT_MERGE_TAGS } from '@/constants/aom-text-editor';
import { logoType, ProgramLogoClass } from '@models';
import draggable from 'vuedraggable';
import Ripple from "vue-ripple-directive";
import LogoComponent from "@/views/apps/admin/programs/general/LogoComponent.vue";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import { ref } from "@vue/composition-api";

const MAX_LOGO_SIZE_KB = '5000';

export default {
  components: {
    ActionButton,
    AomTextEditor,
    BCard,
    BCardTitle,
    BCardSubTitle,
    BRow,
    BCol,
    BForm,
    BButton,
    BFormGroup,
    BFormFile,
    BSpinner,
    BMedia,
    BMediaAside,
    BMediaBody,
    BImg,
    vSelect,
    InputColorPicker,
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,
    draggable,
    LogoComponent
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  props: {
    program: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      required,
      snowOption: {
        theme: "snow",
      },
      localProgram: this.program,
      isUpdating: false,
      toolBar: AOM_TEXT_EDITOR_TOOLBAR_WITHOUT_MERGE_TAGS,

      // Data for logos
      urlMainLogo: undefined,
      programLogo: new ProgramLogoClass({program_id: this.program.id, client_id: this.program.client_id}),
      isUpdatingMainImage: false,
      isDeletingMainImage: false,
      sponsorLogos: []
    };
  },
  computed: {
    ...mapGetters('app', ['isAdminPortal', 'isChampionPortal']),
    ...mapGetters('programs',['programById', 'defaultProgramId']),

    selectedCompliance: {
      get() {
        return wcagComplianceDisplay.filter(w => w.value === this.localProgram.wcag_compliance_id);
      },
      set(newValue) {
        this.localProgram.wcag_compliance_id = newValue.value;
      }
    },

    hasMainLogo() {
      return this.programMainLogo.length > 0;
    },
    programMainLogo() {
      return this.localProgram?.program_logos?.filter(l => Number(l.type_id) === Number(logoType.PRIMARY));
    },
    mainImagePreview() {
      return this.hasMainLogo ? this.programMainLogo[0].url : require('@/assets/images/logo/logo.png');
    },
    programMainLogoFileName() {
      return this.programMainLogo[0]?.filepath ? `Current file...${this.programMainLogo[0]?.filepath}`: '';
    }
  },
  watch: {
    'localProgram.theme_primary_color': {
      handler(n) {
        if(n) {
          this.changeProgramColors(n, this.localProgram.theme_secondary_color);
        }
      }, 
      immediate: true
    },
    'localProgram.theme_secondary_color': {
      handler(n) {
        if(n) {
          this.changeProgramColors(this.localProgram.theme_primary_color, n);
        }
      }, 
      immediate: true
    },
    urlMainLogo(n) {
      this.$refs['main-logo'].validate().then(success => {
        if(!success.valid) {
          return;
        }
        this.setPrimaryLogo(n);
      });
    },
  },
  mounted() {
    this.programSponsorLogos();
  },
  methods: {
    onBack() {
      this.$emit('back', '#general');
    },

    clearForm() {
      this.$nextTick(() => {
        this.$refs.observer.reset();
      });
    },

    async onSubmit() {
      try {
        const { id } = this.localProgram;
        this.isUpdating = true;
        await programsService.updateProgram(id, this.localProgram);
        this.$toast(makeSuccessToast('Program Details updated successfully.'));
        await this.$store.dispatch('programs/GET_PROGRAMS');  
        this.clearForm();
      } catch (e) {
        console.log(e);
        this.$toast(makeErrorToast('Something went wrong! Program Details not saved.'));
      } finally {
        this.isUpdating = false;
      }
    },

    changeProgramColors(primary = PRIMARY_COLOR, secondary = SECONDARY_COLOR) {
      if(this.$route.meta.resource === 'ChampionRoutes') {
        setRootElementCssVars(primary, secondary);
      } 
    },

    openMainImageUpload() {
      this.$refs.refInputImg.$refs.input.click();
    },
    async deleteMainImage () {
      if(!this.programMainLogo[0].id) {
        return;
      }
      try {
        const { id } = this.localProgram;
        const logoId = this.programMainLogo[0].id;
        this.isDeletingMainImage = true;
        await programLogoService.delete(id, this.programMainLogo[0].id);
        this.$toast(makeSuccessToast('Program Logo deleted successfully.'));
        this.$refs.refPreviewImg.src = require('@/assets/images/logo/logo.png');
        const removeMainImage = this.localProgram.program_logos.filter(l => l.id !== logoId);
        this.localProgram = {
          ...this.localProgram,
          program_logos: removeMainImage
        };
        this.$store.commit('programs/SET_PROGRAM_LOGO', { program_id: id, url: undefined });
      } catch (e) {
        if(e.response?.data?.message) {
          this.$refs.refPreviewImg.src = this.programMainLogo[0].url;
          return this.$toast(makeErrorToast(e.response.data.message));
        }
        return this.$toast(makeErrorToast('Something went wrong! Program Logo not deleted.'));
      } finally {
        this.isDeletingMainImage = false;
      }
    },
    async create() {
      try {
        const { id } = this.localProgram;
        this.isUpdatingMainImage = true;
        const formData = new FormData();
        Object.keys(this.programLogo).forEach(key => formData.append(key, this.programLogo[key]));
        const response = await programLogoService.create(id, formData);
        this.programLogo.id = response?.data?.id;
        const newLogo = [...this.localProgram.program_logos, response.data];
        this.localProgram = {
          ...this.localProgram,
          program_logos: newLogo,
        };
        this.$toast(makeSuccessToast('Program Logo uploaded successfully.'));
        this.$store.commit('programs/SET_PROGRAM_LOGO', response.data);
        this.$forceUpdate();
      } catch (e) {
        console.log(e);

        if(e.response?.data?.message) {
          this.$refs.refPreviewImg.src = this.programMainLogo[0].url;
          return this.$toast(makeErrorToast(e.response.data.message));
        } 
        return this.$toast(makeErrorToast('Something went wrong! Program Logo not saved.'));
      } finally {
        this.isUpdatingMainImage = false;
      }
    },
    async update() {
      try {
        this.$bvModal
          .msgBoxConfirm('Are you sure you want to update the logo ?', {
            title: 'Update Logo',
            size: 'sm',
            okVariant: 'primary',
            okTitle: 'Update',
            cancelTitle: 'Cancel',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          })
          .then(async value => {
            if(value) {
              const { id } = this.localProgram;
              this.isUpdatingMainImage = true;
              const response = await programLogoService.update(
                id,
                this.programMainLogo[0].id,
                convertModelToFormData({...this.programLogo, _method: 'PATCH'})
              );
              const {data} = response;
              this.$refs.refPreviewImg.src = data.url;
              this.$store.commit('programs/SET_PROGRAM_LOGO', data);
              this.$toast(makeSuccessToast('Program Logo updated successfully.'));
              this.isUpdatingMainImage = false;
            }
          });
      } catch (e) {
        console.log(e);

        if(e.response?.data?.message) {
          this.$refs.refPreviewImg.src = this.programMainLogo[0].url;
          return this.$toast(makeErrorToast(e.response.data.message));
        } 
        return this.$toast(makeErrorToast('Something went wrong! Program Logo not saved.'));
      } finally {
        this.isUpdatingMainImage = false;
      }
    },
    setPrimaryLogo(file) {
      this.programLogo = {
        ...this.programLogo,
        file: file,
        type_id: logoType.PRIMARY
      };
      if (this.programMainLogo.length > 0) {
        this.update();
      } else {
        this.create();
      }
    },
    addSponsorLogo() {
      this.sponsorLogos = [
        {
          ...new ProgramLogoClass({
            program_id: this.program.id,
            client_id: this.program.client_id,
            type_id: logoType.GENERAL,
            display_order: 0,
            url: require('@/assets/images/logo/logo.png')
          }),
          tempId: (new Date()).getTime(),
          isUpdating: false,
          isDeleting: false
        },
        ...this.sponsorLogos
      ];
    },
    async createSponsorLogo(logo) {
      try {
        const { id } = this.localProgram;
        this.setSponsorLogoUpdating(true, logo);
        const formData = new FormData();
        Object.keys(logo).forEach(key => formData.append(key, logo[key]));
        const response = await programLogoService.create(id, formData);
        this.localProgram = {
          ...this.localProgram,
          program_logos: [
            response.data,
            ...this.localProgram.program_logos,
          ]
        };
        this.sponsorLogos = this.localProgram.program_logos.filter(l => Number(l.type_id) === Number(logoType.GENERAL));
        // Update sort order base on index
        this.updateSortOrder();

        this.$toast(makeSuccessToast('Program Sponsor Logo uploaded successfully.'));
      } catch (e) {
        if(e.response?.data?.message) {
          return this.$toast(makeErrorToast(e.response.data.message));
        } 
        return this.$toast(makeErrorToast('Something went wrong! Program Sponsor Logo not saved.'));
      } finally {
        this.setSponsorLogoUpdating(false, logo);
      }
    },
    async deleteSponsorLogo(logoId, logoIndex) {
      if(!logoId) {
        this.setSponsorLogoDeleting(false, 0, logoIndex);
        return;
      }
      try {
        const { id } = this.localProgram;
        this.setSponsorLogoDeleting(true, logoId);
        await programLogoService.delete(id, logoId);
        this.$toast(makeSuccessToast('Program Sponsor Logo deleted successfully.'));
        const removeImage = this.localProgram.program_logos.filter(l => l.id !== logoId);
        this.localProgram = {
          ...this.localProgram,
          program_logos: removeImage
        };
        this.sponsorLogos = this.sponsorLogos.filter(l => l.id !== logoId);
      } catch (e) {
        if(e.response?.data?.message) {
          this.$refs.refPreviewImg.src = this.programMainLogo[0].url;
          return this.$toast(makeErrorToast(e.response.data.message));
        } 
        return this.$toast(makeErrorToast('Something went wrong! Program Sponsor Logo not deleted.'));
      } finally {
        this.setSponsorLogoDeleting(false, logoId);
      }
    },
    programSponsorLogos() {
      if (this.localProgram) {
        const sp = this.localProgram.program_logos.filter(l => l.type_id === logoType.GENERAL);
        this.sponsorLogos = sp.map(s=> ({...s, isUpdating: false, isDeleting: false}));
      }
    },
    setSponsorLogoUpdating(state, logo) {
      this.sponsorLogos = this.sponsorLogos.map(l => l.id === logo.id ? {...l, isUpdating: state} : l);
    },
    setNewSponsorImage(newLogo, logo) {
      this.sponsorLogos = this.sponsorLogos.map(l => l.id === logo.id ? {...l, ...newLogo} : l);
    },
    setSponsorLogoDeleting(state, id, logoIndex = 0) {
      if (!id) {
        this.sponsorLogos = this.sponsorLogos.filter((_logo, index) => index !== logoIndex);
      } else {
        this.sponsorLogos = this.sponsorLogos.map(l => l.id === id ? {...l, isDeleting: state} : l);
      }
    },
    async updateSortOrder() {
      try {
        const { id } = this.localProgram;
        const orderedList = this.sponsorLogos.map((item, index) => (
          {
            ...item, 
            display_order: index
          }
        ));
        this.sponsorLogos = orderedList;
        await programLogoService.order(id, { logos: orderedList });
        this.$toast(makeSuccessToast('Sponsor Logo order updated successfully.'));
      } catch(e) {
        return this.$toast(makeErrorToast('Something went wrong! Sponsor Logo order not updated.'));
      } 
    },
    async updateLink(data) {
      const {logoId, link } = data;
      const valid = await this.$refs['observer'].validate();

      if (!valid) {
        return;
      }
      if (!logoId) {
        return;
      }

      const { id } = this.localProgram;

      const response = await programLogoService.update(
        id,
        logoId,
        convertModelToFormData({link: link, _method: 'PATCH'})
      );
      this.setNewSponsorImage(response, {id: logoId});

      this.$toast(makeSuccessToast('Sponsor Link updated successfully.'));
    },
    async updateSponsorLogo(logo) {
      try {
        this.$bvModal
          .msgBoxConfirm('Are you sure you want to update the logo ?', {
            title: 'Update Logo',
            size: 'sm',
            okVariant: 'primary',
            okTitle: 'Update',
            cancelTitle: 'Cancel',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          })
          .then(async value => {
            if(value) {
              const { id } = this.localProgram;
              this.setSponsorLogoUpdating(true, logo);
              const response = await programLogoService.update(
                id,
                logo.id,
                convertModelToFormData({...logo, _method: 'PATCH'})
              );
              const {data} = response;
              this.$toast(makeSuccessToast('Sponsor logo updated successfully.'));
              this.setSponsorLogoUpdating(false, logo);
              this.setNewSponsorImage(data, logo);
            }
          });
      } catch (e) {
        if(e.response?.data?.message) {
          this.$refs.refPreviewImg.src = this.programMainLogo[0].url;
          return this.$toast(makeErrorToast(e.response.data.message));
        }
        return this.$toast(makeErrorToast('Something went wrong! Program Logo not saved.'));
      } finally {
        this.setSponsorLogoUpdating(false, logo);
      }
    },
  },
  setup() {
    const refInputImg = ref(null);

    const refPreviewImg = ref(null);

    const { inputImageRenderer } = useInputImageRenderer(
      refInputImg,
      base64 => {
        refPreviewImg.value.src = base64;
      }
    );

    return {
      getValidationState,
      wcagComplianceDisplay,
      MAX_LOGO_SIZE_KB,
      refInputImg,
      refPreviewImg,
      inputImageRenderer,
    };
  },
};
</script>

<style lang="scss" scoped>
.program-update-tab-card {
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
  padding: 5px;
}
.control input {
  max-width: 100px;
  border-radius: 0.3rem;
}
.inputColorPicker{
  background-color: transparent;
  border-radius: 0px;
  width: 50px;
  border: none;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  padding: 0;
}
</style>

<template>
  <!-- form -->
  <validation-observer
    ref="observer"
    v-slot="{ handleSubmit }"
  >
    <b-card class="program-update-tab-card">
      <b-row>
        <b-col sm="6">
          <b-card-title>
            <span v-b-tooltip.hover.top="'Update program settings'">
              General
            </span>
          </b-card-title>
          <b-card-sub-title>
            Update settings
          </b-card-sub-title>
        </b-col>
        <b-col
          sm="6"
          class="d-flex justify-content-end align-items-start flex-column flex-sm-row mt-2 mt-sm-0"
        >
          <a
            v-b-tooltip.hover.righttop="'Please use an incognito browser to login as a participant'"
            target="_blank"
            class="btn btn-light mr-sm-2 mb-1 mb-sm-0"
            @click="copyToClipBoard"
          >
            <feather-icon icon="CopyIcon" />
            Copy Link
          </a>
          <a
            v-b-tooltip.hover.righttop="'Please use an incognito browser to login as a participant'"
            target="_blank"
            class="btn btn-primary"
            :href="programParticipantPortal"
          >
            <feather-icon icon="ExternalLinkIcon" />
            Launch Portal
          </a>
        </b-col>
      </b-row>

      <!--/ media -->
      <b-form
        class="mt-3"
        @submit.prevent="handleSubmit(onSubmit)"
      >
        <!-- Status -->
        <b-row>
          <b-col
            v-if="isAdminPortal || hasProgramAdminRole"
            sm="3"
          >
            <b-form-group
              label="Set Status Active"
              label-for="active"
            >
              <b-form-checkbox
                id="status"
                v-model="programStatusActive"
                checked="true"
                name="active-status"
                switch
                inline
                :disabled="isProgramDisabled"
              />
            </b-form-group>
          </b-col>
          <b-col sm="3">
            <b-form-group
              label="Current Status"
              label-for="current-status"
            >
              <b-badge :variant="STATUS_COLOR[localProgram.status.name]">
                {{ localProgram.status.name }}
              </b-badge>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-if="programHasApplication">
          <b-col sm="6">
            <b-row>
              <b-col sm="6">
                <b-form-group
                  label="Mentor Applications Open"
                  label-for="mentor-applications-open"
                >
                  <router-link
                    :to="programApplication"
                    class="bag-button btn-common secondary-bg-color"
                  >
                    <b-badge :variant="STATUS_COLOR['Active']">
                      {{ mentorApplicationsOpenDate }}
                    </b-badge>
                  </router-link>
                </b-form-group>
              </b-col>
              <b-col sm="6">
                <b-form-group
                  label="Mentee Applications Open"
                  label-for="mentee-applications-open"
                >
                  <router-link
                    :to="programApplication"
                    class="bag-button btn-common secondary-bg-color"
                  >
                    <b-badge :variant="STATUS_COLOR['Active']">
                      {{ menteeApplicationsOpenDate }}
                    </b-badge>
                  </router-link>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
          <b-col sm="6">
            <b-row>
              <b-col sm="6">
                <b-form-group
                  label="Mentor Applications Close"
                  label-for="mentor-applications-close"
                >
                  <router-link
                    :to="programApplication"
                    class="bag-button btn-common secondary-bg-color"
                  >
                    <b-badge :variant="STATUS_COLOR['Suspended']">
                      {{ mentorApplicationsCloseDate }}
                    </b-badge>
                  </router-link>
                </b-form-group>
              </b-col>
              <b-col sm="6">
                <b-form-group
                  label="Mentee Applications Close"
                  label-for="mentee-applications-close"
                >
                  <router-link
                    :to="programApplication"
                    class="bag-button btn-common secondary-bg-color"
                  >
                    <b-badge :variant="STATUS_COLOR['Suspended']">
                      {{ menteeApplicationsCloseDate }}
                    </b-badge>
                  </router-link>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <b-row>
          <b-col sm="3">
            <b-form-group
              label="Licence Start Date"
              label-for="licence-start-date"
            >
              <div
                class="bag-button btn-common secondary-bg-color"
              >
                <b-badge :variant="STATUS_COLOR['Active']">
                  {{ programLicenceStartDate }}
                </b-badge>
              </div>
            </b-form-group>
          </b-col>
          <b-col sm="3">
            <b-form-group
              label="Licence End Date"
              label-for="licence-end-date"
            >
              <div
                class="bag-button btn-common secondary-bg-color"
              >
                <b-badge :variant="STATUS_COLOR['Suspended']">
                  {{ programLicenceEndDate }}
                </b-badge>
              </div>
            </b-form-group>
          </b-col>
        </b-row>

        <!-- Name and URL -->
        <b-row>
          <b-col sm="6">
            <validation-provider
              v-slot="{ errors }"
              ref="name"
              :rules="{ required: true }"
              name="Name"
            >
              <b-form-group
                label="Program Name"
                label-for="program-name"
              >
                <b-input-group>
                  <b-form-input
                    id="program-name"
                    v-model="localProgram.name"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Name"
                    name="program-name"
                    :disabled="programNameDisabled"
                    @change="onProgramNameChange"
                  />
                  <template
                    v-if="isChampionPortal"
                    #append
                  >
                    <b-input-group-text
                      class="cursor-pointer"
                      :class="programNameChanged ? 'text-success' : ''"
                      @click="onSubmit()"
                    >
                      <feather-icon
                        icon="CheckIcon"
                        size="20"
                      />
                    </b-input-group-text>
                  </template>
                </b-input-group>
                <span class="text-danger">{{ errors[0] }}</span>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            v-if="isAdminPortal"
            sm="6"
          >
            <b-form-group
              label="Program URL"
              label-for="url"
            >
              <b-form-input
                id="program-url"
                v-model="localProgram.path"
                maxlength="255"
                name="url"
                placeholder="https://domain.com"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <!-- Conduct URL -->
        <b-row>
          <b-col sm="6">
            <validation-provider
              v-slot="{ errors }"
              ref="code_of_conduct_url"
              :rules="{ url_with_protocol: true }"
              name="Code of Conduct URL"
              vid="code_of_conduct_url"
            >
              <b-form-group
                label="Code of Conduct URL"
                label-for="code-of-conduct-url"
              >
                <b-form-input
                  id="code-of-conduct-url"
                  v-model="localProgram.code_of_conduct_url"
                  :state="errors.length > 0 ? false : null"
                  placeholder="https://example.com/code-of-conduct"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>

        <!-- Type and Plan -->
        <b-row v-if="isAdminPortal || hasProgramAdminRole">
          <b-col sm="6">
            <validation-provider
              v-slot="validationContext"
              ref="programType"
              rules="required"
              name="Program Type"
            >
              <b-form-group
                label="Program Type"
                label-for="program-type"
              >
                <v-select
                  id="program-type"
                  v-model="localProgram.type_id"
                  label="text"
                  :reduce="programTypeDisplay => (programTypeDisplay.id)"
                  placeholder="Select the Program Type"
                  :options="programTypeDisplay"
                  :state="getValidationState(validationContext)"
                  :disabled="true"
                />
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col sm="6">
            <validation-provider
              v-slot="validationContext"
              ref="programPlan"
              rules="required"
              name="Program Plan"
              vid="program_plan_id"
            >
              <b-form-group
                label="Program Plan"
                label-for="program-plan"
              >
                <v-select
                  id="program-plan"
                  v-model="localProgram.program_plan_id"
                  label="text"
                  :reduce="programPlansDisplay => (programPlansDisplay.id)"
                  placeholder="Select the Program Plan"
                  :options="programPlansDisplay"
                  :state="getValidationState(validationContext)"
                  :disabled="disabled && !hasProgramAdminRole"
                />
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>

        <!-- License start date & end date -->
        <b-row v-if="isAdminPortal">
          <b-col sm="6">
            <validation-provider
              v-slot="{ errors }"
              ref="start-date"
              :rules="{ required: true }"
              name="Start Date"
            >
              <b-form-group label="Licence Start Date">
                <flat-pickr
                  v-model="localProgram.licence_start_date"
                  :state="errors.length > 0 ? false : null"
                  class="form-control"
                  placeholder="Select Date"
                  :config="datePickerConfig"
                  :disabled="disabled"
                  @on-change="onStartDateChange"
                />
              </b-form-group>
              <span class="text-danger">{{ errors[0] }}</span>
            </validation-provider>
          </b-col>
          <b-col sm="6">
            <validation-provider
              v-slot="{ errors }"
              ref="end-date"
              :rules="{ required: true }"
              name="End Date"
            >
              <b-form-group label="Licence End Date">
                <flat-pickr
                  v-model="localProgram.licence_end_date"
                  :state="errors.length > 0 ? false : null"
                  class="form-control"
                  placeholder="Select Date"
                  :config="datePickerConfig"
                  :disabled="disabled"
                />
              </b-form-group>
              <span class="text-danger">{{ errors[0] }}</span>
            </validation-provider>
          </b-col>
        </b-row>

        <!-- Timezone -->
        <b-row>
          <b-col sm="6">
            <validation-provider
              v-slot="validationContext"
              ref="timezone"
              rules="required"
              name="Timezone"
            >
              <b-form-group
                label="Timezone"
                label-for="timezone"
              >
                <v-select
                  id="timezone"
                  v-model="localProgram.time_zone"
                  label="text"
                  placeholder="Select the Timezone"
                  :options="timezoneObjects"
                  :reduce="(option) => option.value"
                  :state="getValidationState(validationContext)"
                  :disabled="disabled && !hasProgramAdminRole"
                />
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          
        </b-row>

        <!-- minimum & maximun mentees per mentor-->
        <b-row>
          <b-col sm="6">
            <validation-provider
              v-slot="{ errors }"
              ref="min_mentee_per_mentor"
              :rules="{ required: true }"
              vid="min_mentee_per_mentor"
              name="Minimum Mentees per Mentor"
            >
              <b-form-group
                :label="minMenteePerMentorLabel"
                label-for="min_mentee_per_mentor"
              >
                <v-select
                  id="min-mentee-per-mentor"
                  v-model="minMenteePerMentor"
                  :state="errors.length > 0 ? false : null"
                  :placeholder="minMenteePerMentorLabel"
                  :options="minMenteePerMentorOptions"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </b-form-group>  
            </validation-provider>
          </b-col>
          <b-col sm="6">
            <validation-provider
              v-slot="{ errors }"
              ref="max_mentee_per_mentor"
              :rules="{ required: true }"
              vid="max_mentee_per_mentor"
              name="Maximum Mentees per Mentor"
            >
              <b-form-group
                :label="maxMenteePerMentorLabel"
                label-for="max_mentee_per_mentor"
              >
                <v-select
                  id="max-mentee-per-mentor"
                  v-model="maxMenteePerMentor"
                  :state="errors.length > 0 ? false : null"
                  :placeholder="maxMenteePerMentorLabel"
                  :options="maxMenteePerMentorOptions"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </b-form-group>  
            </validation-provider>
          </b-col>
        </b-row>

        <!-- Aliases -->
        <b-row>
          <b-col sm="6">
            <b-form-group
              v-if="programRoleMentorIndex > -1"
              label="Mentor alias"
              label-for="mentorSynonym"
            >
              <b-form-input
                id="mentor-alias"
                v-model="programMentorSynonym"
                maxlength="255"
                name="url"
                placeholder="Not Set"
                :disabled="disabled && !hasProgramAdminRole"
              />
            </b-form-group>
          </b-col>

          <b-col sm="6">
            <b-form-group
              v-if="programRoleMenteeIndex > -1"
              label="Mentee alias"
              label-for="menteeSynonym"
            >
              <b-form-input
                id="mentee-alias"
                v-model="programMenteeSynonym"
                maxlength="255"
                name="url"
                placeholder="Not Set"
                :disabled="disabled && !hasProgramAdminRole"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <!-- Default languages & default champion -->
        <b-row v-if="isAdminPortal || hasProgramAdminRole">
          <b-col 
            v-if="isAdminPortal"
            sm="6"
          >
            <validation-provider
              v-slot="validationContext"
              ref="selectedLanguages"
              rules="required|program_languages"
              name="Default languages"
            >
              <b-form-group
                label="Language"
                label-for="default-language"
              >
                <v-select
                  v-if="currentPortal === userRoleAdmin"
                  id="selected-language"
                  v-model="localProgram.languages"
                  item-text="name"
                  item-value="id"
                  label="name"
                  :options="languageList"
                  placeholder="Select the languages"
                  :state="getValidationState(validationContext)"
                  multiple
                  :disabled="isChampionPortal"
                />

                <!-- Display languages for Champion Portal -->
                <b-form-input
                  v-else
                  :value="localProgram.languages.map(lang => lang.name).join(', ')"
                  placeholder="Not Set"
                  :disabled="disabled"
                />

                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col sm="6">
            <validation-provider
              v-slot="validationContext"
              ref="selectedChampion"
              :rules="{ required: isRequiredDefaultChamp }"
              name="Default Champion"
            >
              <b-form-group
                label="Default Champion"
                label-for="default-champion"
              >
                <v-select
                  id="selected-champion"
                  v-model="defaultChampion"
                  label="text"
                  :reduce="championsList => ({id:championsList.id})"
                  :options="championsList"
                  placeholder="Select the Default Champion"
                  :state="getValidationState(validationContext)"
                  :disabled="disabled && !hasProgramAdminRole"
                />
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>

        <!-- Allow Champion Matching -->
        <b-row>
          <b-col
            v-if="isProgramTypeMentoringOnDemand"
            sm="4"
          >
            <b-form-group
              label="Allow Champion Matching"
              label-for="champion_matching_enabled"
            >
              <b-form-checkbox
                v-model="localProgram.champion_matching_enabled"
                checked="true"
                name="champion_matching_enabled"
                switch
                inline
              />
              <feather-icon
                v-b-tooltip.hover.right="'If Yes champions can manually match participants'"
                size="20"
                class="align-middle"
                icon="InfoIcon"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <!-- Match rules -->
        <b-row v-if="isAdminPortal || hasProgramAdminRole">
          <b-col 
            md="6"
          >
            <div class="d-flex">
              <validation-provider
                v-slot="validationContext"
                ref="matchDuration"
                rules="required|min_value:1|max_value:365"
                name="Duration"
              >
                <b-form-group
                  label-for="match-duration"
                  label="Match Duration"
                >
                  <b-form-input
                    id="match-duration"
                    v-model="localProgram.match_duration_value"
                    type="number"
                    :state="getValidationState(validationContext)"
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

              <validation-provider
                v-slot="validationContext"
                ref="duration-unit"
                rules="required"
                name="Period"
              >
                <b-form-group
                  label-for="duration-unit"
                  label="Type"
                  class="ml-1"
                >
                  <v-select
                    id="duration-unit"
                    v-model="durationUnit"
                    :options="durationUnitsDisplay"
                    label="name"
                    placeholder="Duration"
                    :state="getValidationState(validationContext)"
                    class="medium-select-list"
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </div>
          </b-col>
          <b-col
            v-if="!isProgramTypeMentoringOnDemand"
            md="3"
          >
            <validation-provider
              v-slot="{ errors }"
              ref="name"
              :rules="{ required: true, min_value: 1 }"
              name="Matches Allowed"
            >
              <b-form-group
                label-for="match_limit"
                label="Matches Allowed"
              >
                <b-form-input
                  id="match_limit"
                  v-model="localProgram.match_limit"
                  type="number"
                  :state="errors.length > 0 ? false : null"
                  placeholder="10"
                  :disabled="isChampionPortal"
                />
              </b-form-group>
              <span class="text-danger">{{ errors[0] }}</span>
            </validation-provider>
          </b-col>
        </b-row>

        <!-- Enable participants to be both Mentor and Mentee & Participant Visibility -->
        <b-row>
          <b-col sm="6">
            <b-form-group
              :label="'Enable participants to be both ' + roleAlias( userRoles.MENTOR, program) + ' and ' + roleAlias( userRoles.MENTEE, program)"
              label-for="multiple_roles_enabled"
            >
              <b-form-checkbox
                id="allow-both-mentor-mentee"
                v-model="localProgram.multiple_roles_enabled"
                checked="true"
                name="multiple_roles_enabled"
                switch
                inline
              />
              <feather-icon
                v-b-tooltip.hover.right="'If `On` then users are allowed to participate in the program as both mentor and mentee.'"
                size="20"
                class="align-top"
                icon="InfoIcon"
              />
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group
              label="Participant Visibility"
              label-for="participant_visibility"
            >
              <b-form-checkbox
                id="participant-visibility"
                v-model="localProgram.participant_visibility"
                checked="true"
                switch
                inline
              />
              <feather-icon
                v-b-tooltip.hover.right="'If `On` then participants are able to view the profile of all participants in the program rather than their own published matches.'"
                size="20"
                class="align-top"
                icon="InfoIcon"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <!-- Display suggested partner identity prior to matching & Disable training -->
        <b-row>
          <b-col
            v-if="isProgramTypePartnerPreferencing || isProgramTypeMentoringOnDemand"
            sm="6"
          >
            <b-form-group
              label="Display suggested partner identity prior to matching"
              label-for="participant_visibility_suggest"
            >
              <b-form-checkbox
                id="participant_visibility_suggest"
                v-model="partnerIdentity"
                checked="true"
                name="participant_visibility_suggest"
                switch
                inline
              />
            </b-form-group>
          </b-col>
          <b-col
            v-if="isAdminPortal || hasProgramAdminRole"
            sm="6"
          >
            <b-form-group
              label="Disable training"
              label-for="disable_training"
            >
              <b-form-checkbox
                id="disable-training"
                v-model="localProgram.training_disabled"
                checked="true"
                name="disable_training"
                switch
                inline
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            v-if="isAdminPortal || hasProgramAdminRole"
            sm="6"
          >
            <b-form-group
              label="Make participant phone number compulsory at registration"
              label-for="compulsory_phone_number"
            >
              <b-form-checkbox
                id="participant_compulsory_phone_number"
                v-model="localProgram.compulsory_phone_number"
                checked="true"
                name="compulsory_phone_number"
                switch
                inline
              />
            </b-form-group>
          </b-col>
          <b-col
            v-if="isAdminPortal"
            sm="6"
          >
            <b-form-group
              label="Enable SSO for program"
              label-for="enable_sso_for_program"
            >
              <b-form-checkbox
                id="enable_sso_for_program"
                v-model="localProgram.program_saml.is_enabled"
                checked="false"
                name="enable_sso_for_program"
                switch
                inline
              />
            </b-form-group>
          </b-col>
        </b-row>

        <!-- Active participant cap for program -->
        <b-row>
          <b-col
            v-if="isProgramTypeMentoringOnDemand"
            md="6"
          >
            <validation-provider
              v-slot="{ errors }"
              ref="active_participant_limit"
              :rules="{ required: true }"
              vid="active_participant_limit"
              name="Active participant cap for program"
            >
              <b-form-group
                label-for="participant-cap"
                label="Active participant cap for program"
              >
                <b-form-input
                  v-model="localProgram.active_participant_limit"
                  type="number"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Active participant cap for program"
                  :disabled="isChampionPortal"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>

        <!-- Maximum Mentors per Mentee? -->
        <b-row>
          <b-col
            v-if="isProgramTypeMentoringOnDemand"
            sm="6"
          >
            <validation-provider
              v-slot="{ errors }"
              ref="max_mentor_per_mentee"
              :rules="{ required: true, min_value:1 }"
              vid="max_mentor_per_mentee"
              :name="maxMentorPerMenteeLabel"
            >
              <b-form-group
                label-for="participant-cap"
                :label="maxMentorPerMenteeLabel"
              >
                <b-row>
                  <b-col sm="10">
                    <b-form-input
                      v-model="maxMentorPerMentee"
                      type="number"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="maxMentorPerMenteeLabel"
                    />
                  </b-col>
                  <b-col sm="2">
                    <feather-icon
                      v-b-tooltip.hover.right="'When they apply ' + roleAlias( userRoles.MENTEE, program, true) + ' will be asked how many ' + roleAlias( userRoles.MENTOR, program) + ' they are willing to be matched with in this program. '"
                      size="20"
                      class="align-middle"
                      icon="InfoIcon"
                    />
                  </b-col>
                  <span class="text-danger">{{ errors[0] }}</span>
                </b-row>
              </b-form-group>  
            </validation-provider>
          </b-col>
        </b-row>

        <!-- Number of mentors to suggest to each mentee -->
        <b-row>
          <b-col sm="6">
            <validation-provider
              v-slot="{ errors }"
              ref="suggestion_limit"
              :rules="suggestionLimitValidationRule"
              :name="numberOfMentorsToSuggestEachMenteeLabel"
              vid="suggestion_limit"
            >
              <b-form-group
                v-if="isProgramTypePartnerPreferencing || isProgramTypeMentoringOnDemand "
                label-for="suggestion_limit"
                :label="numberOfMentorsToSuggestEachMenteeLabel"
              >
                <v-select
                  id="suggestion_limit"
                  v-model="mentorSuggestionLimit"
                  :options="maxMentorSuggestion"
                  :state="errors.length || !mentorSuggestionLimit > 0 ? false : null"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>

        <!-- Minimum number of mentors a mentee must select -->
        <b-row>
          <b-col sm="6">
            <b-form-group
              v-if="isProgramTypePartnerPreferencing"
              label-for="suggestion_limit"
              :label="&quot;Minimum number of &quot; + roleAlias( userRoles.MENTOR, program, true) + &quot; a &quot; + roleAlias( userRoles.MENTEE, program) + &quot; must select&quot;"
            >
              <v-select
                id="suggestion_limit"
                v-model="miniumMentorsPerMentee"
                label="text"
                :options="maxMentorSuggestion"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <!-- Number of pending requests allowed per Mentor -->
        <b-row>
          <b-col
            v-if="isProgramTypeMentoringOnDemand"
            sm="6"
          >
            <validation-provider
              v-slot="{ errors }"
              ref="match_request_limit_mentor"
              :rules="{ required: true }"
              vid="match_request_limit_mentor"
              :name="matchRequestLimitMentorLabel"
            >
              <b-form-group
                label-for="match_request_limit_mentor"
                :label="matchRequestLimitMentorLabel"
              >
                <b-form-input
                  id="match_request_limit_mentor"
                  v-model="matchRequestLimitMentor"
                  type="number"
                  :state="errors.length > 0 ? false : null"
                  :placeholder="matchRequestLimitMentorLabel"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            v-if="isProgramTypeMentoringOnDemand"
            sm="6"
          >
            <validation-provider
              v-slot="{ errors }"
              ref="match_request_limit_mentee"
              :rules="{ required: true }"
              vid="match_request_limit_mentee"
              :name="matchRequestLimitMenteeLabel"
            >
              <b-form-group
                label-for="match_request_limit_mentee"
                :label="matchRequestLimitMenteeLabel"
              >
                <b-form-input
                  id="match_request_limit_mentee"
                  v-model="matchRequestLimitMentee"
                  type="number"
                  :state="errors.length > 0 ? false : null"
                  :placeholder="matchRequestLimitMenteeLabel"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            v-if="isProgramTypeMentoringOnDemand"
            sm="4"
          >
            <validation-provider
              v-slot="{ errors }"
              ref="match_request_duration_value"
              :rules="{ required: true }"
              vid="match_request_duration_value"
              name="Request expiry Duration"
            >
              <b-form-group
                label-for="match_request_duration_value"
                label="Request Expiry Duration"
              >
                <v-select
                  id="match_request_duration_value"
                  v-model="localProgram.match_request_duration_value"
                  label="text"
                  :options="matchRequestDurationValue"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            v-if="isProgramTypeMentoringOnDemand"
            sm="2"
          >
            <validation-provider
              v-slot="{ errors }"
              ref="match_request_duration_unit"
              :rules="{ required: true }"
              vid="match_request_duration_unit"
              name="Type"
            >
              <b-form-group
                label-for="match_request_duration_unit"
                label="Type"
              >
                <v-select
                  id="match_request_duration_unit"
                  v-model="localProgram.match_request_duration_unit"
                  label="name"
                  :options="durationUnitsDisplay"
                  :reduce="option => option.id"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="6">
            <b-form-group
              v-if="isProgramTypePartnerPreferencing"
              label-for="preferencing_weight"
              label="Partner Preferencing Weighting"
            >
              <v-select
                id="preferencing_weight"
                v-model="localProgram.partner_pref_weight"
                label="text"
                :options="preferencingWeightingOptions"
                :reduce="option => option.value"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="6">
            <b-form-group
              v-if="isProgramTypePartnerPreferencing"
              label-for="compat_threshold"
              label="Hide preferences under this compatibility threshold"
            >
              <v-select
                id="compat_threshold"
                v-model="localProgram.compatibility_threshold"
                label="text"
                :options="compatThresholdOptions"
                :reduce="option => option.value"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="6">
            <b-form-group
              v-if="isProgramTypePartnerPreferencing"
              label="Preferencing"
              label-for="preferencing"
            >
              <v-select
                id="preferencing"
                v-model="localProgram.partner_pref_active"
                label="text"
                :options="preferencingOptions"
                :reduce="option => option.value"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="6">
            <b-form-group
              v-if="isProgramTypePartnerPreferencing"
              label="Hide ordering for preferences"
              label-for="hide_ordering_of_preferences"
            >
              <v-select
                id="hide_ordering_of_preferences"
                v-model="localProgram.hide_preference_order"
                label="text"
                :options="hideOrdering"
                :reduce="option => option.value"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col
            sm="6"
            class="text-left"
          >
            <b-button
              v-if="isAdminPortal"
              variant="outline-secondary"
              @click="clearForm"
            >
              Cancel
            </b-button>
          </b-col>
          <b-col
            sm="6"
            class="text-right"
          >
            <action-button
              type="submit"
              variant="primary"
            >
              <b-spinner
                v-if="isUpdating"
                small
              />
              <span
                v-if="isUpdating"
              >Updating...</span>

              <template v-else>
                <span class="mr-50">Update</span>
                <feather-icon icon="SaveIcon" />
              </template>
            </action-button>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
  </validation-observer>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BSpinner,
  BCard,
  BCardTitle,
  BCardSubTitle,
  BFormCheckbox,
  BFormInvalidFeedback,
  BBadge,
  VBTooltip,
  BInputGroup,
  BInputGroupText
} from "bootstrap-vue";
import ActionButton from "@/views/components/common/ActionButton.vue";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import {
  PRIMARY_DATE_FORMAT,
  STATUS_COLOR
} from "@/libs/utils";
import { ValidationProvider, ValidationObserver } from "vee-validate";
//eslint-disable-next-line
import { required, max_value, min_value } from "@validations";
import {
  getValidationState,
} from "@/libs/utils";
import programsService from "@/services/programsService";
import usersService from "@/services/usersService";
import {
  makeSuccessToast,
  makeErrorToast,
  localeDateStringFromIsoDateTimeWithoutTimezone,
  localeDateStringFromIsoDateTime,
} from "@/libs/utils";
import _cloneDeep from "lodash/cloneDeep";
import { mapGetters } from 'vuex';
import {
  userRoles,
  programTypeDisplay,
  timezoneObjects,
  programStatus,
  programStatusDisplay,
  programPlansDisplay,
  matchDurationValuesDisplay,
  programTypes,
  durationUnitsDisplay
} from '@/models';
import localesService from "@/services/localesService";
import { AOM_MAIN_DOMAIN } from '@/constants/app';
import { roleAlias } from "@/@aom-core/utils/utils";

export default {
  name: 'ProgramDetails',
  components: {
    BButton,
    BForm,
    BSpinner,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BCardSubTitle,
    BFormCheckbox,
    BBadge,
    vSelect,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    BFormInvalidFeedback,
    vSelect,
    BInputGroup,
    BInputGroupText,
    ActionButton
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  props: {
    program: {
      type: Object,
      default: () => {},
    },
    disabled: {
      type: Boolean
    }
  },
  data(vm) {
    return {
      required,
      championsList: [],
      languageList: [],
      localProgram: _cloneDeep(this.program),
      isUpdating: false,
      client: undefined,
      datePickerConfig: {
        dateFormat: 'Y-m-d',
        altInput: true,
        altFormat: PRIMARY_DATE_FORMAT,
        start: {
          maxDate: undefined,
        },
        end: {
          minDate: undefined,
        }
      },
      programRoleMentorIndex: -1,
      programRoleMenteeIndex: -1,
      programMentorSynonym: null,
      programMenteeSynonym: null,
      currentPortal: null,
      userRoleAdmin: userRoles.ADMIN,
      defaultChampion: null,
      programStatusActive: vm.program.status_id === programStatus.ACTIVE,
      programNameChanged: false,
      trainingAvailableStage: [{ value: 'post', text: 'Post'},{ value: 'pre', text: 'Pre'}],
      maxMenteePerMentor: this.program.program_roles.filter(r => r.role_id === userRoles.MENTOR).shift()?.match_limit,
      minMenteePerMentor: this.program.program_roles.filter(program_role => program_role.role_id === userRoles.MENTOR).shift()?.min_match_limit,
      maxMentorPerMentee: this.program.program_roles.filter(r => r.role_id === userRoles.MENTEE).shift()?.match_limit,
      mentorSuggestionLimit: this.program.program_roles.filter(r => r.role_id === userRoles.MENTEE).shift()?.suggestion_limit,
      matchRequestDurationValue: Array.from({ length: 30}, (_, i) => i + 1),
      matchRequestLimitMentee: this.program.program_roles.filter(r => r.role_id === userRoles.MENTEE).shift()?.match_request_limit,
      matchRequestLimitMentor: this.program.program_roles.filter(r => r.role_id === userRoles.MENTOR).shift()?.match_request_limit,
      miniumMentorsPerMentee: this.program.program_roles.filter(r => r.role_id === userRoles.MENTEE).shift()?.selection_limit,
      maxMenteePerMentorOptions: Array.from({length: 10}, (_, i) => i + 1),
      minMenteePerMentorOptions: Array.from({length: 10}, (_, i) => i + 1),
      durationUnit: durationUnitsDisplay.filter(d => d.id === this.program.match_duration_unit),
      preferencingWeighting: this.program?.partner_pref_weight, 
      preferencingWeightingOptions: [
        {value: 0, text: 'None (0%)'},
        {value: 15, text: 'Very Low (15%)'},
        {value: 30, text: 'Low (30%)'},
        {value: 50, text: 'Medium (50%)'},
        {value: 70, text: 'High (70%)'},
        {value: 85, text: 'Very High (85%)'},
        {value: 100, text: 'Highest (100%)'}
      ],
      compatThresholdOptions: Array.from({ length: 21 }, (_, index) => ({value: index * 5, text: `${index * 5} %`})),
      partnerIdentity: this.program.program_roles.filter(r => r.visibility === true).length > 0,
      preferencingOptions: [{value: true, text: 'Active'},{value: false, text: 'InActive'}],
      hideOrdering: [{value: true, text: 'Yes'},{value: false, text: 'No'}],
      participantVisibility: this.program.program_roles.filter(r => Boolean(r.visibility) === Boolean(true)).length > 0
    };
  },
  computed: {
    ...mapGetters('programs',['programById']),
    ...mapGetters('app', ['isAdminPortal', 'isChampionPortal']),
    ...mapGetters("profile", ["profile", 'isAdmin', 'isProgramAdmin']),
    maxMenteePerMentorLabel (){
      return "Maximum " + roleAlias( userRoles.MENTEE, this.program, true) + " per " + roleAlias( userRoles.MENTOR, this.program) + "?";
    },
    minMenteePerMentorLabel (){
      return "Minimum " + roleAlias( userRoles.MENTEE, this.program, true) + " per " + roleAlias( userRoles.MENTOR, this.program) + "?";
    },
    maxMentorPerMenteeLabel (){
      return "Maximum " + roleAlias( userRoles.MENTOR, this.program, true) + " per " + roleAlias( userRoles.MENTEE, this.program) + "?";
    },
    matchRequestLimitMentorLabel (){
      return "Number of pending requests allowed per " + roleAlias( userRoles.MENTOR, this.program);
    },
    matchRequestLimitMenteeLabel (){
      return "Number of pending requests allowed per " + roleAlias( userRoles.MENTEE, this.program);
    },
    numberOfMentorsToSuggestEachMenteeLabel() {
      return `Number of ${roleAlias(
        userRoles.MENTOR,
        this.program,
        true
      )} to suggest to each ${roleAlias(userRoles.MENTEE, this.program)}`;
    },
    suggestionLimitValidationRule() {
      return this.isProgramTypeMentoringOnDemand ? { required: true, min_value: 1 } : '';
    },
    hasProgramAdminRole() {
      return !this.isAdmin && this.isProgramAdmin(this.program?.id);
    },
    originalModel() {
      return Object.assign({},  _cloneDeep(this.program));
    },
    programParticipantPortal () {
      if(!this.client) return;
      return `${window.location.protocol}//${this.client.path}.${AOM_MAIN_DOMAIN}/${this.program.path}`;
    },
    isRequiredDefaultChamp() {
      return this.championsList.length > 0;
    },
    isProgramDisabled () {
      if (this.disabled && !this.hasProgramAdminRole) { return true; }

      return this.localProgram.champions_count === 0 ||
          !this.localProgram.default_champion ||
          !this.localProgram.application_set ||
          this.localProgram.status_id === programStatus.INCOMPLETE || 
          this.localProgram.status_id === programStatus.EXPIRED;
    },
    mentorApplications () {
      return this.localProgram.application_set.applications.filter(app => app.roles.find(r => r.id === userRoles.MENTOR));
    },
    menteeApplications () {
      return this.localProgram.application_set.applications.filter(app => app.roles.find(r => r.id === userRoles.MENTEE));
    },
    mentorApplicationsOpenDate() {
      return localeDateStringFromIsoDateTimeWithoutTimezone(this.mentorApplications[0].scheduled_actions[0].absolute_date);
    },
    mentorApplicationsCloseDate() {
      return localeDateStringFromIsoDateTimeWithoutTimezone(this.mentorApplications[0].scheduled_actions[1].absolute_date);
    },
    menteeApplicationsOpenDate() {
      return localeDateStringFromIsoDateTimeWithoutTimezone(this.menteeApplications[0].scheduled_actions[0].absolute_date);
    },
    menteeApplicationsCloseDate() {
      return localeDateStringFromIsoDateTimeWithoutTimezone(this.menteeApplications[0].scheduled_actions[1].absolute_date);
    },
    programApplication () {
      if(this.isAdminPortal) {
        return { name: 'admin-program-application', params: {application: this.localProgram?.application_set?.id, id: this.$route.params.id} };
      }
      return { name: 'champion-program-application', params: {application: this.localProgram.application_set.id, id: this.$route.params.id} };
    },
    maxMentorSuggestion () {
      const maxSuggestion = this.isProgramTypeMentoringOnDemand ? this.localProgram.active_participant_limit : 20;
      return Array.from({length: maxSuggestion}, (_, i) => i + 1);
    },
    programHasApplication () {
      return Boolean(this.localProgram?.application_set);
    },
    programNameDisabled() {
      if (this.isChampionPortal) {
        return false;
      }
      return this.disabled;
    },

    isProgramTypeStandard() {
      return this.localProgram.type_id === programTypes.STANDARD;
    },
    isProgramTypePartnerPreferencing() {
      return this.localProgram.type_id === programTypes.PARTNER_PREFERENCING;
    },
    isProgramTypeMentoringOnDemand() {
      return this.localProgram.type_id === programTypes.MENTORING_ON_DEMAND;
    },
    mentorRoleInProgram() {
      return this.program.program_roles.filter(r => r.role_id === userRoles.MENTOR).shift();
    },
    programLicenceStartDate() {
      return localeDateStringFromIsoDateTime(this.program?.licence_start_date);
    },
    programLicenceEndDate() {
      return localeDateStringFromIsoDateTime(this.program?.licence_end_date);
    },
  },
  watch: {
    programStatusActive(n) {
      let status;
      if(n) {
        status = programStatus.ACTIVE;
      } else {
        status = programStatus.INACTIVE;
      }
      this.localProgram.status_id = status;
    },
    maxMenteePerMentor(n) {
      if(n < this.minMenteePerMentor) {
        this.minMenteePerMentor = n;

      }
      this.updateMaxMenteePerMentor(n);
    },
    minMenteePerMentor(n) {
      if(n > this.maxMenteePerMentor) {
        this.maxMenteePerMentor = n;
    
      }
      this.updateMinMenteePerMentor(n);
    },
    durationUnit(n) {
      this.updateMatchDuration(n);
    },
    partnerIdentity(n) {
      const v = this.localProgram.program_roles.map(r => ({...r, visibility: n}));
      this.localProgram.program_roles = v;
    },
    maxMentorPerMentee(n) {
      if(n) { 
        this.updateMaxMentorPerMentee(n);
      }
    },
    mentorSuggestionLimit(n) {
      if(n) { 
        this.updateMentorSuggestionLimit(n);
      }
    },
    matchRequestLimitMentee(n) {
      if(n) { 
        this.updateMatchRequestLimitMentee(n);
      }
    },
    matchRequestLimitMentor(n) {
      if(n) {
        this.updateMatchRequestLimitMentor(n);
      }
    },
    miniumMentorsPerMentee(n) {
      if(n) {
        this.updateMiniumMentorsPerMentee(n);
      }
    },
    participantVisibility(n) {
      const v = this.localProgram.program_roles.map(r => ({...r, visibility: n}));
      this.localProgram.program_roles = v;
    }
  },
  async created() {
    this.getProgramChampions();
    const program = this.programById(this.program.id);
    // this.program.status_id = program;
    this.client = program?.[0].client;

    if (this.$route.meta.resource === 'ChampionRoutes') {
      this.currentPortal = userRoles.CHAMPION;
    } else if (this.$route.meta.resource === 'AdminRoutes') {
      this.currentPortal = userRoles.ADMIN;
      this.getProgramLanguages();
    }

    // Init value for synonyms
    if (this.program || !this.program.program_roles) {
      this.programRoleMentorIndex = this.program.program_roles.findIndex(programRole => programRole.role_id === userRoles.MENTOR);
      this.programRoleMenteeIndex = this.program.program_roles.findIndex(programRole => programRole.role_id === userRoles.MENTEE);

      if (this.programRoleMentorIndex > -1) {
        this.programMentorSynonym = this.program.program_roles[this.programRoleMentorIndex].alternative_role_name;
      }
      if (this.programRoleMenteeIndex > -1) {
        this.programMenteeSynonym = this.program.program_roles[this.programRoleMenteeIndex].alternative_role_name;
      }
    }

    // Init value for default champion
    if (this.program.default_champion) {
      this.defaultChampion = {id: this.program.default_champion.id, text: this.program.default_champion.full_name };
    }
  },
  methods: {
    onProgramNameChange () {
      this.programNameChanged = this.originalModel.name !== this.localProgram.name;
    },
    onStartDateChange(selectedDates, dateStr) {
      this.datePickerConfig.end.minDate = dateStr;
    },
    onEndDateChange(selectedDates, dateStr) {
      this.datePickerConfig.start.maxDate = dateStr;
    },
    clearForm() {
      this.localProgram = _cloneDeep(this.originalModel);
      this.maxMenteePerMentor = this.program.program_roles.filter(r => r.role_id === userRoles.MENTOR).shift()?.match_limit || 1,
      this.maxMentorPerMentee = 1,
      this.mentorSuggestionLimit = 1;
      this.matchRequestLimitMentee = 1;
      this.matchRequestLimitMentor = 1;
      this.miniumMentorsPerMentee =  3;
      this.$nextTick(() => {
        this.$refs.observer.reset();
      });
    },
    async onSubmit() {
      try {
        const { id } = this.localProgram;

        // Add synonym to localProgram
        if (this.currentPortal === userRoles.ADMIN || this.hasProgramAdminRole) {
          if (this.programRoleMentorIndex >= -1) {
            this.localProgram.program_roles[this.programRoleMentorIndex].alternative_role_name = this.programMentorSynonym;
          }
          if (this.programRoleMenteeIndex >= -1) {
            this.localProgram.program_roles[this.programRoleMenteeIndex].alternative_role_name = this.programMenteeSynonym;
          }
        }

        // Add default champion to localProgram
        this.localProgram.default_champion = this.defaultChampion;

        this.isUpdating = true;
        const response = await programsService.updateProgram(id, this.localProgram);
        const { status_id } = response.data;
        this.setProgramStatusOnUpdate(status_id);
        this.$toast(makeSuccessToast('Program Details updated successfully.'));
      } catch (e) {
        console.log(e);
        this.$toast(makeErrorToast('Something went wrong! Program Details not saved.'));

        let message= e.response.data.message;
        if (e.response.data.message.includes("suggestion_limit")) {
          message = 'The number of mentors suggested to each mentee cannot be less than the minimum number of mentors a mentee must select.';
        }
        this.$toast(makeErrorToast(message));
        this.$log.error(e);
      } finally {
        this.isUpdating = false;
      }
    },
    async getProgramChampions() {
      try {
        const { id } = this.localProgram;
        const response = await usersService.getChampionsByProgram(id);
        const {data} = response;
        this.championsList = data.items.map(c => ({id: c.id, text: c.full_name, }));
      } catch (e) {
        console.log(e);
        const { status } = e.response;
        if (status === 404) {
          this.$toast(makeErrorToast('No champions for this program found.'));
        } else {
          this.$toast(makeErrorToast('Something went wrong! Default champions list not loaded.'));
        }
      }
    },

    async getProgramLanguages() {
      try {
        const response = await localesService.getLocalesList();
        if (response && response.data) {
          this.languageList = response.data.map(lang => ({id: lang.id, name: lang.name}));
        }
      } catch (e) {
        const { status } = e.response;
        if (status === 404) {
          this.$toast(makeErrorToast('No Languages found.'));
        } else {
          this.$toast(makeErrorToast('Something went wrong! Default Languages list not loaded.'));
        }
      }
    },
    setProgramStatusOnUpdate(statusId) {
      if(statusId) {
        this.localProgram.status = {
          ...this.localProgram.status,
          id: statusId,
          name: programStatusDisplay[statusId]
        };
        this.localProgram.status_id = statusId;
      }
    },
    copyToClipBoard() {
      navigator.clipboard.writeText(this.programParticipantPortal)
        .then(() => {
          this.$toast(makeSuccessToast('Link copied.'));
        })
        .catch(error => {
          this.$toast(makeErrorToast('Error cannot copy text.', error));
        });
    },
    updateMaxMenteePerMentor(n) {
      const mentorRole = this.localProgram.program_roles.findIndex(r => r.role_id === userRoles.MENTOR);      
      if(mentorRole !== -1) {
        this.localProgram.program_roles[mentorRole].match_limit = n; 
      }
    },
    updateMinMenteePerMentor(n) {
      const mentorRole = this.localProgram.program_roles.findIndex(program_role => program_role.role_id === userRoles.MENTOR);      
      if(mentorRole !== -1) {
        this.localProgram.program_roles[mentorRole].min_match_limit = n; 
      }
    },  
    updateMatchDuration (v) {
      const { id } = v;
      this.localProgram.match_duration_unit = id;
    },
    updateMaxMentorPerMentee(n) {
      const menteeRole = this.localProgram.program_roles.findIndex(r => r.role_id === userRoles.MENTEE);      
      if(menteeRole !== -1) {
        this.localProgram.program_roles[menteeRole].match_limit = n; 
      }
    },
    updateMentorSuggestionLimit(n) {
      const menteeRole = this.localProgram.program_roles.findIndex(r => r.role_id === userRoles.MENTEE);      
      if(menteeRole !== -1) {
        this.localProgram.program_roles[menteeRole].suggestion_limit = n; 
      }
    },
    updateMatchRequestLimitMentee(n) {
      const menteeRole = this.localProgram.program_roles.findIndex(r => r.role_id === userRoles.MENTEE);      
      if(menteeRole !== -1) {
        this.localProgram.program_roles[menteeRole].match_request_limit = n; 
      }
    },
    updateMatchRequestLimitMentor(n) {
      const mentorRole = this.localProgram.program_roles.findIndex(r => r.role_id === userRoles.MENTOR);      
      if(mentorRole !== -1) {
        this.localProgram.program_roles[mentorRole].match_request_limit = n; 
      }
    },
    updateMiniumMentorsPerMentee(n) {
      const menteeRole = this.localProgram.program_roles.findIndex(r => r.role_id === userRoles.MENTEE);      
      if(menteeRole !== -1) {
        this.localProgram.program_roles[menteeRole].selection_limit = n; 
      }
    }
  },
  setup() {
    return {
      getValidationState,
      programTypeDisplay,
      timezoneObjects,
      STATUS_COLOR,
      localeDateStringFromIsoDateTimeWithoutTimezone,
      programPlansDisplay,
      durationUnitsDisplay,
      matchDurationValuesDisplay,
      roleAlias,
      userRoles
    };
  },
};
</script>
<style>
.program-update-tab-card {
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
  padding: 5px;
}
</style>
<style lang="scss">
#client-program-wrapper {
  border: 1px solid #eee;
  border-radius: 5px;
  padding: 5px 10px;
  legend {
    font-size: 1em;
    width: auto;
  }
}
</style>

<template>
  <b-card :class="inProgramDetail ? 'program-update-tab-card' : ''">
    <!-- Table Top -->
    <b-row class="mb-1">
      <b-col
        v-if="isChampionPortal"
        sm="6"
      >
        <h4>
          Surveys
        </h4>
      </b-col>
      <client-program
        v-else
        :program="program"
      />

      <b-col sm="6">
        <action-button
          v-if="isChampionPortal"
          variant="primary"
          class="float-right mb-2"
          @click="openAdd = true"
        >
          <feather-icon
            icon="PlusIcon"
            size="16"
          />
          <span class="text-nowrap ml-1">Create Survey</span>
        </action-button>
        <action-button
          v-if="isAdminPortal || hasProgramAdminRole"
          variant="primary"
          class="float-right mb-2 mr-1"
          @click="
            inProgramDetail ? showModalAddSurvey() : (openAdd = true)
          "
        >
          {{ inProgramDetail ? 'Add Survey' : 'Create Survey' }}
        </action-button>
      </b-col>
    </b-row>

    <!-- Survey in program detail -->
    <template v-if="inProgramDetail">
      <surveys-list-program
        ref="surveysListProgram"
        :program="program"
        @edit="editSurvey"
      />

      <!-- Add survey to program -->
      <add-survey-to-program
        v-if="isAdminPortal || hasProgramAdminRole"
        :show-modal="showModal"
        @hiddenModal="hiddenModal"
        @confirmAddSurvey="onConfirmAddComms"
      />

      <confirm-add-survey-to-program
        :show-modal="showModalConfirm"
        :surveys="surveys"
        :is-program-template="isProgramTemplate"
        @hiddenModal="hiddenModalConfirm"
        @surveysAdded="onRefreshItems"
      />
    </template>

    <template v-else>
      <surveys-list
        ref="surveysList"
        @edit="editSurvey"
      />
    </template>

    <add-surveys-side-bar
      :in-program-detail="inProgramDetail"
      :application-id="surveyId"
      :open="openAdd"
      @edit="editSurvey"
      @close="clear"
      @toggleSideBar="toggleAddSideBar"
      @surveysAdded="onRefreshItems"
    />

    <edit-surveys-side-bar
      :in-program-detail="inProgramDetail"
      :survey-id="surveyId"
      :open="openEdit"
      :is-program-template="isProgramTemplate"
      @close="clear"
      @toggleSideBar="toggleEditSideBar"
      @surveyUpdated="onRefreshItems"
    />
  </b-card>
</template>

<script>
import ActionButton from "@/views/components/common/ActionButton.vue";

import { mapGetters } from "vuex";
import { BCard, BRow, BCol, BButton } from "bootstrap-vue";
import SurveysList from "./SurveysList.vue";
import AddSurveysSideBar from "./AddSurveysSideBar.vue";
import EditSurveysSideBar from "./EditSurveysSideBar.vue";
import SurveysListProgram from "./SurveysListProgram.vue";
import AddSurveyToProgram from "./AddSurveyToProgram.vue";
import ConfirmAddSurveyToProgram from "./ConfirmAddSurveyToProgram.vue";
import { userRoles } from '@models/userRoles';
import ClientProgram from '@/views/apps/admin/programs/general/ClientProgram.vue';

export default {
  name: "Surveys",
  components: {
    ActionButton,
    BCard,
    BRow,
    BCol,
    BButton,
    SurveysList,
    AddSurveysSideBar,
    EditSurveysSideBar,
    SurveysListProgram,
    AddSurveyToProgram,
    ConfirmAddSurveyToProgram,
    ClientProgram
  },
  props: {
    inProgramDetail: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: ''
    },
    program: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      openAdd: false,
      openEdit: false,
      surveyId: undefined,
      showModal: false,
      showModalConfirm: false,
      surveys: [],
      userRoles
    };
  },
  computed: {
    ...mapGetters("app", ["isChampionPortal", "isAdminPortal"]),
    ...mapGetters("profile", ['isProgramAdmin']),
    hasProgramAdminRole() {
      return this.isProgramAdmin(Number(this.$route.params.id));
    },
    user() {
      return this.$store.getters['profile/profile'];
    },
    isProgramTemplate() {
      return this.program?.is_template === true;
    }
  },
  methods: {
    editSurvey(id) {
      this.openEdit = true;
      this.surveyId = id;
    },
    clear() {
      this.openEdit = false;
      this.openAdd = false;
      this.surveyId = undefined;
      this.onRefreshItems();
    },
    toggleAddSideBar(value) {
      if (!value) {
        this.clear();
      }
    },
    toggleEditSideBar(value) {
      if (!value) {
        this.clear();
      }
    },
    showModalAddSurvey() {
      this.showModal = true;
    },
    hiddenModal(isShow) {
      this.showModal = isShow;
    },
    async onConfirmAddComms(surveys) {
      this.showModalConfirm = true;
      this.surveys = surveys;
    },
    hiddenModalConfirm(isShow) {
      this.showModalConfirm = isShow;
    },
    onRefreshItems() {
      if (this.inProgramDetail) {
        this.$refs.surveysListProgram.loadItems();
      } else {
        this.$refs.surveysList.loadItems();
      }
    },
  },
};
</script>

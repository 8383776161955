<template>
  <b-modal
    id="modal-confirm-add-survey-to-program"
    v-model="showModal"
    :title="`Add ${surveys.length} survey(s)?`"
    centered
    ok-title="Submit"
    cancel-variant="outline-secondary"
    :ok-disabled="isSubmitting"
    size="lg"
    @hidden="onHidden"
    @ok.prevent="onSubmit"
  >
    <b-card>
      <p class="my-1">
        Are you sure you want to add these surveys to this program? Please note, the default rules will apply
      </p>
      <b-table
        v-if="items && items.length > 0"
        :items="items"
        :fields="fields"
      >
        <template #cell(include_default_recipient)="props">
          <v-select
            v-model="props.item.include_default_recipient"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="name"
            class="flex-fill flex-1 email-to-selector"
            :close-on-select="true"
            :options="emailToGroupOptions"
            input-id="email-to-group"
            :loading="isLoadingGroups"
            append-to-body
            :calculate-position="withPopper"
            placeholder="Select a Group"
          >
            <template #option="{ name, type }">
              <b-row>
                <b-col sm="12">
                  <span class="mr-1"> {{ name }}</span>
                  <b-badge
                    variant="secondary"
                  >
                    {{ type }}
                  </b-badge>
                </b-col>
              </b-row>
            </template>
  
            <template #selected-option="{ avatar, name }">
              <b-avatar
                size="sm"
                class="border border-white"
                :src="avatar"
              />
              <span class="ml-50"> {{ name }}</span>
            </template>
          </v-select>
        </template>
      </b-table>
    </b-card>
  </b-modal>
</template>

<script>
import { BCard, BModal, BTable, BRow, BCol, BBadge, BAvatar } from "bootstrap-vue";
import { groupsService, programsService } from "@/services";
import vSelect from 'vue-select';
import { makeErrorToast, makeSuccessToast } from "@/libs/utils";
import { createPopper } from '@popperjs/core';

export default {
  components: {
    BCard,
    BModal,
    BTable,
    vSelect,
    BRow,
    BCol,
    BBadge,
    BAvatar
  },
  props: {
    showModal: {
      type: Boolean
    },
    isProgramTemplate: {
      type: Boolean,
      required: false,
      default: false
    },
    surveys: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  data() {
    return {
      items: [],
      fields: [
        {
          key: 'name',
          label: 'Survey Name',
        },
        {
          key: 'include_default_recipient',
          label: 'Include Default Recipient',
        },
      ],
      emailToGroupOptions: [],
      isLoadingGroups: false,
      group: [],
      isSubmitting: false
    };
  },
  watch: {
    surveys: {
      handler (n) {
        if(n) {
          this.items = this.surveys.map(item => ({
            ...item,
            include_default_recipient: null
          }));
        }
      },
      deep: true,
      immediate: true
    }
  },
  created() {
    this.getGroups();
  },
  methods: {
    onHidden() {
      this.$emit("hiddenModal", false);
      this.clearForm();
    },
    async resolveService() {
      const programId = this.$route.params.id;
      if (this.isProgramTemplate) {
        return groupsService.getListTemplates(programId);
      }
      return groupsService.getList(programId);
    },
    async getGroups() {
      try {
        this.isLoadingGroups = true;
        const response = await this.resolveService();
        const {items} = response.data;
        this.emailToGroupOptions = items.filter(item => !item.is_champion).map(i => ({id: i.id, name: i.name, type: i.type.name}));
      } catch (e) {
        this.$toast(makeErrorToast("Groups list not loaded."));
        console.log(e);
      } finally {
        this.isLoadingGroups = false;
      }
    },
    withPopper(dropdownList, component) {
      dropdownList.style.width = '100%';
      dropdownList.style.maxWidth = "550px";
      dropdownList.style.maxHeight = "300px";
      dropdownList.style.zIndex = "1050";
      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: 'bottom',
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, -1],
            },
          },
          {
            name: 'toggleClass',
            enabled: true,
            phase: 'write',
            fn({ state }) {
              component.$el.classList.toggle(
                'drop-up',
                state.placement === 'top'
              );
            },
          },
        ],
      });
      return () => popper.destroy();
    },
    async onSubmit() {
      this.isSubmitting = true;
      try {
        const forms = this.items.map(item => ({
          id: item.id,
          group_id: item.include_default_recipient.id,
          is_enabled: false
        }));
        await programsService.addSurveyToProgram(
          this.$route.params.id,
          { forms }
        );
        this.$emit("surveysAdded");
        this.$emit("hiddenModal", false);
        this.clearForm();
        this.$toast(makeSuccessToast('Survey was successfully added'));
      } catch (e) {
        this.$log.error(e);
        this.$toast(makeErrorToast("Add survey to program failed."));
      } finally {
        this.isSubmitting = false;
      }
    },
    clearForm() {}
  }
};
</script>


<template>
  <div>
    <b-row class="mt-2">
      <b-col md="12">
        <validation-provider
          v-slot="validationContext"
          :ref="`title-${index}`"
          :vid="`title-${index}`"
          :rules="{ required: isRequired }"
          name="Title"
        >
          <b-form-group 
            label="Title"
            label-for="title"
          >
            <b-form-input
              v-model="selectedTitle"
              type="text"
              placeholder="Title"
              :state="
                getValidationState(
                  validationContext
                )
              "
            />
            <b-form-invalid-feedback
              :state="
                getValidationState(
                  validationContext
                )
              "
            >
              {{
                validationContext.errors[0]
              }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>
    </b-row>
  
    <b-row class="mt-1">
      <b-col md="12">
        <validation-provider
          v-slot="validationContext"
          :ref="`description-${index}`"
          :vid="`description-${index}`"
          :rules="{ required: isRequired, min: 10 }"
          name="Description"
        >
          <b-form-group 
            label="Description"
            label-for="description"
          >
            <b-form-textarea
              v-model="selectedDescription"
              placeholder="Description"
              maxlength="255"
              rows="3"
              :state="
                getValidationState(
                  validationContext
                )
              "
            />
            <b-form-invalid-feedback
              :state="
                getValidationState(
                  validationContext
                )
              "
            >
              {{
                validationContext.errors[0]
              }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>
    </b-row>
  </div>
</template>
  
  
<script>
import {
  BRow, 
  BCol, 
  BFormGroup, 
  BFormInput,
  BFormTextarea,
  BFormInvalidFeedback
} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import { ValidationProvider } from "vee-validate";
import { getValidationState } from "@/libs/utils";
  
export default {
  name:'ApplicationTitleDesc',
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea, 
    ValidationProvider,
    BFormInvalidFeedback
  },
  directives: {
    Ripple,
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    index: {
      type: Number,
      default: 0
    },
    localeId: {
      type: Number,
      default: 0
    }
  },
  inject: ['currentTab'],
  data() {
    return {
      selectedTitle: this.title,
      selectedDescription: this.description
    };
  },
  computed: {
    isRequired() {
      let isTabDataDirty = false;
      for (const [, text] of Object.entries(this.$refs)) {
        if(text.value) {
          isTabDataDirty = true;
        }
      }
      return this.index === this.currentTab.value || isTabDataDirty;
    }
  },
  watch:{
    title(n) {
      this.selectedTitle = n;
    },
    description(n){
      this.selectedDescription = n;
    },
    selectedTitle(){
      this.$emit('updateTitle', {title: this.selectedTitle, localeId: this.localeId});
    },
    selectedDescription(){
      this.$emit('updateDescription', {description: this.selectedDescription, localeId: this.localeId});
    }
  },
  setup() {
    return {
      getValidationState,
        
    };
  }
};
</script> 
  
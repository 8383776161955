<template>
  <b-modal
    id="modal-add-champion"
    v-model="showModal"
    title="Add Champion"
    centered
    ok-title="Submit"
    cancel-variant="outline-secondary"
    :ok-disabled="isSubmitting"
    @hidden="onHidden"
    @ok.prevent="onSubmit"
  >
    <b-form>
      <!-- Add champion -->
      <v-select
        v-model="selected"
        multiple
        class="mt-1"
        :dir="dir"
        placeholder="Select Champion"
        label="name"
        :options="options"
        :loading="isLoading"
        transition="fade"
        @search="debounceInput"
      />
    </b-form>
    <template #modal-footer="{ ok, cancel}">
      <b-button
        size="md"
        variant="outline-secondary"
        @click="cancel()"
      >
        Cancel
      </b-button>
      <b-button
        size="md"
        variant="primary"
        :disabled="selected.length === 0"
        @click="ok(onSubmit)"
      >
        <b-spinner
          v-if="isSubmitting" 
          small
        />
        <span
          v-if="isSubmitting"
        > Updating...</span>
        <span v-else>Add Champion</span>
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  BForm,
  BModal,
  BSpinner,
  BButton
} from "bootstrap-vue";
import championsService from "@/services/championsService";
import { makeErrorToast, makeSuccessToast } from "@/libs/utils";
import _debounce from 'lodash/debounce';
import vSelect from "vue-select";

export default {
  name: 'AddChampionToProgram',
  components: {
    BForm,
    BModal,
    BSpinner,
    vSelect, 
    BButton
  },
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    champion: {
      type: Object,
      default: null,
    },
    program: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      keyword: null,
      selected: [],
      options: [],
      dir: null,
      isLoading: false,
      isSubmitting: false,
    };
  },
  computed: {
    ...mapGetters('champions', ['currentProgram']),
  },
  mounted() {
    this.loadItems();
  },
  methods: {
    async onSubmit() {
      this.isSubmitting = true;
      try {
        const champions = this.selected.map(select => ({id: select.id}));
        await championsService.addChampionsToProgram(
          this.$route.params.id,
          {champions}
        );
        this.$emit("championsAdded");
        this.$emit("hiddenModal", false);
        this.clearForm();
        this.$toast(makeSuccessToast('Champion was successfully added'));
      } catch (e) {
        this.$toast(makeErrorToast(e));
        this.$log.error(e);
      } finally {
        this.isSubmitting = false;
      }
    },
    onHidden() {
      this.$emit("hiddenModal", false);
      this.clearForm();
    },
    clearForm() {
      this.selected = [];
    },
    debounceInput: _debounce(function (value) {
      this.keyword = value;
      this.loadItems();
    }, 500),
    async loadItems() {
      try {
        // Fetch program champions
        this.isLoading = true;
        const programId = this.program.id;
        const programChampionsResponse = await championsService.getChampionsByProgram(
          programId,
          {
            page: 1,
            perPage: 100,
          }
        );
        const programChampions = programChampionsResponse.data.items.map(item => item.id);
        // Fetch client champions
        const clientId = this.currentProgram?.client_id || this.program?.client_id;
        const response = await championsService.getChampionsByClient(
          clientId,
          {
            columnFilters: this.keyword ? [
              {
                field: "full_name",
                value: this.keyword,
              }
            ] : []
          }
        );
        this.options = response.data.items
          .map(champion => ({id: champion.id, name: champion.full_name}))
          .filter(champion => !programChampions.includes(champion.id));
      } catch (e) {
        this.$toast(makeErrorToast("Champions list not loaded."));
        console.log(e);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
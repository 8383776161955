<template>
  <!-- SPONSOR LOGO -->
  <section>
    <div class="pt-1">
      <b-media
        id="media2"
        no-body
        vertical-align="center"
        class="flex-column flex-md-row"
      >
        <b-media-aside>
          <feather-icon
            class="d-flex align-items-center move-icon mt-3"
            icon="MoveIcon"
            size="20"
          />
          <b-img
            :src="sponsorLogo.url"
            width="115"
            class="rounded mr-2 mb-1 mb-md-0 ml-1"
          />
        </b-media-aside>
        <b-media-body class="mb-2">
          <div class="mb-2">
            <action-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="primary"
              size="sm"
              @click="openImageUpload(index)"
            >
              <b-spinner
                v-if="sponsorLogo.isUpdating" 
                small
              />
              <span
                v-if="!sponsorLogo.isUpdating"
                class="text-nowrap"
              > Upload</span>
            </action-button>
            <action-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              class="ml-1"
              variant="outline-secondary"
              size="sm"
              @click="deleteImage(sponsorLogo.id)"
            >
              <b-spinner
                v-if="sponsorLogo.isDeleting" 
                small
              />
              <span
                v-if="!sponsorLogo.isDeleting"
                class="text-nowrap"
              >Delete</span>
            </action-button>
          </div>
          <validation-provider
            v-slot="validationContext"
            :ref="`sponsor-file-${index}`"
            :rules="{ size: MAX_LOGO_SIZE_KB }"
            :vid="`sponsor-file-${index}`"
            name="Sponsor File"
          >
            <b-form-file
              ref="refInputImg"
              v-model="uploadLogo"
              class="hidden"
              accept=".jpg, .png, .gif"
              placeholder="Choose a file"
              :state="
                getValidationState(
                  validationContext
                )
              "
              @input="inputImageRenderer"
            />
            <b-form-invalid-feedback
              :state="
                getValidationState(
                  validationContext
                )
              "
            >
              {{
                validationContext.errors[0]
              }}
            </b-form-invalid-feedback>
          </validation-provider>
          <validation-provider
            v-slot="validationContext"
            :ref="`sponsor-link-${index}`"
            :rules="{ url_with_protocol: true }"
            :vid="`sponsor-link-${index}`"
            name="Sponsor link"
          >
            <b-form-group
              label="Link"
              label-for="sponsor-link"
            >
              <b-form-input
                id="sponsor-link"
                v-model="link"
                maxlength="255"
                name="sponsor-link"
                placeholder="Add URL here..."
                :state="
                  getValidationState(
                    validationContext
                  )
                "
              />
              <b-form-invalid-feedback
                :state="
                  getValidationState(
                    validationContext
                  )
                "
              >
                {{
                  validationContext.errors[0]
                }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-media-body>
      </b-media>
    </div>
  </section>
</template>

<script>
import {
  BFormFile,
  BButton,
  BFormGroup,
  BFormInput,
  BMedia,
  BMediaAside,
  BMediaBody,
  BImg,
  BSpinner,
  BFormInvalidFeedback
} from "bootstrap-vue";
import ActionButton from "@/views/components/common/ActionButton.vue";
import { ValidationProvider } from "vee-validate";
//eslint-disable-next-line
import { required, size, urlWithProtocol } from "@validations";
import Ripple from "vue-ripple-directive";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import { ref } from "@vue/composition-api";
import { getValidationState } from "@/libs/utils";

const MAX_LOGO_SIZE_KB = '5000';

export default {
  components: {
    ActionButton,
    BButton,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BMedia,
    BMediaAside,
    BMediaBody,
    ValidationProvider,
    BSpinner,
    BFormInvalidFeedback
  },
  directives: {
    Ripple,
  },
  props: {
    sponsorLogo: {
      type: Object,
      default: () => {}
    },
    index: {
      type: Number,
      required: true,
      default: 0
    }
  },
  data() {
    return {
      required,
      size,
      uploadLogo: undefined,
      link: this.sponsorLogo.link,
      MAX_LOGO_SIZE_KB
    };
  },
  watch: {
    'sponsorLogo.link': function(n) {
      if (n && this.sponsorLogo?.link) {
        this.link = this.sponsorLogo.link;
      }
    },
    uploadLogo(n) {
      if (n) {
        this.$refs[`sponsor-file-${this.index}`].validate().then(success => {
          if(!success.valid) {
            return;
          }
          const logo = {
            ...this.sponsorLogo,
            file: n
          };

          if (logo.id) {
            this.$emit('updateLogo', logo);
          } else {
            this.$emit('upload', logo);
          }
        });
      }
    },
    link (n) {
      if(n) {
        this.$refs[`sponsor-link-${this.index}`].validate().then(success => {
          if(!success) {
            return;
          }
          this.$emit('updateLink', {link: n, logoId: this.sponsorLogo.id, index: this.index});
        });
      }
    },
  },
  methods: {
    openImageUpload() {
      const refName = `refInputImg`;
      this.$refs[refName].$refs.input.click();
    },
    deleteImage(id) {
      this.$emit('delete', id, this.index);
    }
  },
  setup() {
    const refInputImg = ref(null);

    const refPreviewImg = ref(null);

    const { inputImageRenderer } = useInputImageRenderer(
      refInputImg,
      base64 => {
        if (refPreviewImg.value) {
          refPreviewImg.value.src = base64;
        }
      }
    );
    return {
      refInputImg,
      refPreviewImg,
      inputImageRenderer,
      getValidationState
    };
  },
};
</script>
<style>
.program-update-tab-card {
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
  padding: 5px;
}
.move-icon {
  cursor: pointer;
}
</style>

<template>
  <b-modal
    id="modal-add-application"
    v-model="showModal"
    title="Add Survey"
    centered
    ok-title="Submit"
    cancel-variant="outline-secondary"
    :ok-disabled="isSubmitting"
    @hidden="onHidden"
    @ok.prevent="onSubmit"
  >
    <b-form>
      <!-- Add Servey -->
      <v-select
        v-model="selected"
        append-to-body
        :calculate-position="withPopper"
        multiple
        class="mt-1"
        :dir="dir"
        placeholder="Select survey"
        label="name"
        :options="options"
        :loading="isLoading"
        transition="fade"
        @search="debounceInput"
      />
    </b-form>
  </b-modal>
</template>

<script>
import {
  BForm,
  BModal
} from "bootstrap-vue";
import formsService from "@/services/formsService";
import _debounce from 'lodash/debounce';
import { makeErrorToast } from "@/libs/utils";
import vSelect from "vue-select";
import { createPopper } from '@popperjs/core';

export default {
  components: {
    BForm,
    BModal,
    vSelect
  },
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    champion: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      keyword: null,
      selected: [],
      options: [],
      dir: null,
      isLoading: false,
      isSubmitting: false
    };
  },
  created() {
    this.loadItems();
  },
  methods: {
    onSubmit() {
      this.$emit("confirmAddSurvey", [...this.selected]);
      this.$emit("hiddenModal", false);
      this.clearForm();
    },
    onHidden() {
      this.$emit("hiddenModal", false);
      this.clearForm();
    },
    clearForm() {
      this.selected = [];
    },
    debounceInput: _debounce(function (value) {
      this.keyword = value;
      this.loadItems();
    }, 500),
    async loadItems() {
      try {
        this.isLoading = true;
        const response = await formsService.getSurveysList({
          page: 1,
          perPage: 100,
          columnFilters: this.keyword
            ? [
              {
                field: "title",
                value: this.keyword,
              },
            ]
            : [],
        });
        this.options = response.data.items.map(application => ({
          id: application.id,
          name: application.translations[0]?.title,
        }));
      } catch (e) {
        this.$log.error(e);
        this.$toast(makeErrorToast("Surveys list not loaded."));
      } finally {
        this.isLoading = false;
      }
    },
    withPopper(dropdownList, component) {
      dropdownList.style.width = '100%';
      dropdownList.style.maxWidth = "550px";
      dropdownList.style.maxHeight = "300px";
      dropdownList.style.zIndex = "1050";
      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: 'bottom',
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, -1],
            },
          },
          {
            name: 'toggleClass',
            enabled: true,
            phase: 'write',
            fn({ state }) {
              component.$el.classList.toggle(
                'drop-up',
                state.placement === 'top'
              );
            },
          },
        ],
      });
      return () => popper.destroy();
    },
  },
};
</script>

<template>
  <div
    :class="['aom-status', `aom-status-${variant}`]"
  />
</template>

<script>
export default {
  props: {
    variant: {
      type: String,
      default: 'primary'
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/base/bootstrap-extended/include'; // Bootstrap includes
@import '~@/assets/scss/base/components/include'; // Components includes

// Color palettes
@import '~@/assets/scss/base/core/colors/palette-variables.scss';

/* Generate:
*  Apply background color to dot
*/
@each $color_name, $color in $colors {
  @each $color_type, $color_value in $color {
    @if $color_type== 'base' {
      .aom-status-#{$color_name} {
        background-color: $color_value;

        &:before {
          background-color: rgba($color_value, 0.12);
        }
      }
    }
  }
}

.aom-status {
  position: relative;
  width: 16px;
  height: 16px;
  border-radius: 50%;

  &:before {
    content: '';
    z-index: 1;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    position: absolute;
    top: -4px;
    left: -4px;
    bottom: 0;
    right: 0;
  }
}
</style>
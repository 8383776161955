<template>
  <b-modal
    id="modal-add-program-admin-to-program"
    v-model="showModal"
    title="Add Program Admin"
    centered
    ok-title="Submit"
    cancel-variant="outline-secondary"
    :ok-disabled="isLoading || !selectedUser"
    @hidden="onHidden"
    @ok.prevent="onSubmit"
  >
    <b-form>
      <v-select
        v-model="selectedUser"
        class="mt-1"
        placeholder="Select Program Admin"
        label="name"
        :options="options"
        :loading="isLoading"
        transition="fade"
      />
    </b-form>
  </b-modal>
</template>

<script>
import {
  BForm,
  BModal,
} from "bootstrap-vue";
import { makeErrorToast } from "@/libs/utils";
import { userRoles } from '@models';
import { usersService, championsService } from '@/services';
import vSelect from "vue-select";

export default {
  name: 'AddProgramAdminToProgram',
  components: {
    BForm,
    BModal,
    vSelect,
  },
  props: {
    program: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      selectedUser: null,
      isLoading: false,
      showModal: false,
      options: [],
    };
  },
  methods: {
    async onSubmit() {
      try {
        this.isLoading = true;
        const payload = {
          user_id: this.selectedUser.id
        };

        await championsService.addProgramAdminToProgram(
          this.program.id,
          payload
        );
        this.$emit("programAdminAdded");
        this.showModal = false;
      } catch (e) {
        const { status, data } = e.response || {};
        if (status === 409) {
          this.$toast(makeErrorToast(data.message));
          this.showModal = false;
          this.$emit("hiddenModal", false);
        } else {
          console.log(e);
          this.$toast(makeErrorToast());
        }
      } finally {
        this.isLoading = false;
      }
    },
    show() {
      this.showModal = true;
      this.loadList();
    },
    onHidden() {
      this.clearForm();
    },
    clearForm() {
      this.selectedUser = null;
      this.options = [];
    },
    async loadList(){
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      try {
        const programId = this.program.id;
        const programChampionsResponse = await championsService.getChampionsByProgram(programId);
        const existingProgramAdmins = programChampionsResponse.data.items
          .filter(u => !!u.user_roles.find(ur => ur.role_id === userRoles.PROGRAM_ADMIN))
          .map(u => u.id);
        const programAdminsResponse = await usersService.listProgramAdmins();
        this.options = programAdminsResponse.data.items
          .map(u => ({id: u.id, name: u.full_name}))
          .filter(u => !existingProgramAdmins.includes(u.id));
      } catch (e) {
        console.log(e);
        this.$toast(makeErrorToast('Error loading Program Administrator list.'));
      } finally {
        this.isLoading = false;
      }
    }
  },
};
</script>
